import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store';
import { DataSenderObject, getAllDataSenders } from '../../../store/Communications/actions';
import { selectAllDataSenders } from '../../../store/Communications/selectors';
import TablePagination from '../../Pagination/TablePagination';
import './DataSenderModal.scss';

const DataSenderModal = ({ onClose, onSelect }: any) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const dataSenders = useSelector((state) => selectAllDataSenders(state)) as DataSenderObject | null

  useEffect(() => {
    dispatch(getAllDataSenders(currentPage));
  }, [currentPage]);

  const onSenderSelect = (dataSender: any, e: any) => {
    e.stopPropagation();
    onSelect(dataSender);
  }

  return (
    <div className="templateModal">
      <div className="templateContentContainer">
        <div className="templateRow">
          <h2>Data Senders</h2>
          <button className="close" onClick={onClose} />
        </div>
        <h4>Data Senders</h4>
        <div className="availableDataSenders">
          <table>
            <thead>
              <tr className="dataSenderHeaderRow">
                <td width="20%"><p>Title</p></td>
                <td width="20%"><p>ID</p></td>
                <td width="20%"><p>Actions</p></td>
              </tr>
            </thead>
            <tbody>
              {dataSenders && dataSenders.results && dataSenders.results.map((dataSender, index) => (
                <tr key={`data-sender-${index}`} className="dataSenderRow">
                  <td><p className="mb-0">{dataSender.name || "N/A"}</p></td>
                  <td><p className="mb-0">{dataSender.id}</p></td>
                  <td><button className="selectButton" onClick={(e) => onSenderSelect(dataSender, e)}>Select</button></td>
                </tr>
              ))}
            </tbody>
          </table>
          {dataSenders && (<TablePagination count={dataSenders.count} setCurrentPage={setCurrentPage} currentPage={currentPage} />)}
        </div>
      </div>
    </div>
  )
}

export default DataSenderModal;
