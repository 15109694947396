import React, { useEffect, useState, useRef, useMemo } from "react"
import { useSelector } from "react-redux"
import Select from "react-select"
import MultiSelect from "react-multi-select-component"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Toggle from "../../Toggle/Toggle"
import ReactTags from "react-tag-autocomplete"
import "./CommunicationModule.scss"
import viewToPlainText from "@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext"
import TemplateModal from "../TemplateModal/TemplateModal"
import {
  getAllAdditionalContacts,
  selectAgent,
  selectAllContacts,
  selectCustomer,
  selectDealId,
  selectLoanOfficer,
  selectSuccessfullySentMessage,
  selectUnsuccessfulMessageSend,
} from "../../../store/Communications/selectors"
import Contacts, { LoanOfficer } from "../../../Interfaces/contacts"
import {
  getSelectedTemplate,
  selectOpenTemplateListModal,
} from "../../../store/Templates/selectors"
import { State, useAppDispatch } from "../../../store"
import {
  toggleTemplateListModal,
  resetTemplate,
} from "../../../store/Templates/reducer"
import { renderDjangoTemplate } from "../../../utils/templateHelpers"
import {
  DataSender,
  EmailCommunicationObject,
  getAgentFromId,
  getContactsFromId,
  getCustomerFromId,
  sendCommunicationEmailToContacts,
  sendCommunicationSMSToContacts,
  SMSCommunicationObject,
} from "../../../store/Communications/actions"
import {
  resetAll,
  resetStateFlags,
} from "../../../store/Communications/reducer"
import Agent from "../../../Interfaces/agent"
import Customer from "../../../Interfaces/customer"
import {
  CustomerDeals,
  getCustomerDealsFromURL,
  getDealFromId,
  getDealsFromCustomerId,
} from "../../../store/Deal/actions"
import { selectCustomerDeals, selectDeal } from "../../../store/Deal/selector"
import { Deal } from "../../../Interfaces/deal"
import { getUser } from "../../../store/User/actions"
import { selectUser } from "../../../store/User/selectors"
import User from "../../../Interfaces/user"
import {
  closeCreatePlaybookModal,
  closePlaybookModal,
  openPlaybookModal,
  resetAllPlaybookStates,
} from "../../../store/Playbooks/reducer"
import {
  selectEditingPlaybook,
  selectIsCreatePlaybookModalOpen,
  selectIsPlaybookModalOpen,
  selectSelectedPlaybook,
} from "../../../store/Playbooks/selectors"
import PlaybooksModal from "../PlaybooksModal/PlaybooksModal"
import CreatePlaybookModal from "../CreatePlaybookModal/CreatePlaybookModal"
import { Playbook } from "../../../store/Playbooks/actions"
import PlaybookCommunication from "../PlaybookCommunication/PlaybookCommunication"
import { checkContactsForSMSOptOut, waitForElm } from "../../../utils/helpers"
import { openUpdateModal } from "../../../store/UpdateConnection/reducer"
import { selectOpenUpdateModal } from "../../../store/UpdateConnection/selectors"
import UpdateForm from "../UpdateForm/UpdateForm"
import {
  selectOpenAddContactsModal,
  selectOpenCreateDealModal,
} from "../../../store/AddContacts/selectors"
import AddNewContactModal from "../AddNewContactModal/AddNewContactModal"
import {
  openAddContactsModal,
  openCreateDealModal,
} from "../../../store/AddContacts/reducer"
import CreateDealModal from "../CreateDealModal/CreateDealModal"
import DataSenderModal from "../DataSenderModal/DataSenderModal"
import DataSenderFormModal from "../DataSenderFormModal/DataSenderFormModal"
import { resetDataStates } from "../../../store/DataSender/reducer"
import { useAuthenticationStatus } from "../../hooks"
import ContactDetails from "../ContactDetails"

const Unlocked = () => (
  <svg
    width="123"
    height="117"
    viewBox="0 0 123 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M74.2899 79.9099L74.3606 44.7449H37.6256H0.890564L0.44751 79.9099C0.51821 100.238 17.0165 116.736 37.3685 116.736C57.7205 116.736 74.2895 100.261 74.2895 79.9099H74.2899Z"
      fill="#EE7878"
    />
    <path
      d="M37.3599 61.8699C33.3471 61.8699 30.1123 65.1047 30.1123 69.1175C30.1123 72.0418 31.818 74.577 34.3083 75.7294V93.8164H41.2063V75.2844C43.2595 74.0005 44.6394 71.7176 44.6394 69.1176C44.6394 65.1048 41.3728 61.87 37.36 61.87L37.3599 61.8699Z"
      fill="#BB1D1D"
    />
    <path
      d="M59.2998 42.1299C59.2998 42.1299 57.7585 0.000976562 91.1538 0.000976562C124.549 0.000976562 122.494 42.1299 122.494 42.1299H113.76C113.76 42.1299 113.503 10.276 91.1537 9.50595C68.8047 8.73529 70.0887 42.1299 70.0887 42.1299H59.2997H59.2998Z"
      fill="#EE7878"
    />
  </svg>
)

const Locked = () => (
  <svg
    width="74"
    height="117"
    viewBox="0 0 74 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.80983 42.3399C4.80983 42.3399 3.26854 0.210938 36.6638 0.210938C70.0591 0.210938 68.0038 42.3399 68.0038 42.3399H59.2697C59.2697 42.3399 59.0129 10.4859 36.6637 9.71594C14.3147 8.94528 15.5987 42.3399 15.5987 42.3399H4.80974H4.80983Z"
      fill="#8FFF67"
    />
    <path
      d="M73.8399 80.1099L73.9106 44.9448H37.1756H0.440613L-0.00244141 80.1099C0.0682586 100.438 16.5665 116.936 36.9185 116.936C57.2705 116.936 73.8395 100.461 73.8395 80.1099H73.8399Z"
      fill="#8FFF67"
    />
    <path
      d="M36.91 62.0698C32.8972 62.0698 29.6624 65.3046 29.6624 69.3174C29.6624 72.2417 31.3681 74.7769 33.8584 75.9293V94.0164H40.7564V75.4843C42.8096 74.2004 44.1895 71.9176 44.1895 69.3176C44.1895 65.3048 40.9229 62.0699 36.9101 62.0699L36.91 62.0698Z"
      fill="#35CC00"
    />
  </svg>
)

const CommunicationModule = () => {
  // Redux
  const dispatch = useAppDispatch()
  const [isAuthenticated, authLoading] = useAuthenticationStatus()
  const deal = useSelector(state => selectDeal(state)) as Deal
  const self = useSelector((state: State) => state.users.self)
  const selectedTemplate = useSelector(state => getSelectedTemplate(state))
  const contacts = useSelector(state => selectAllContacts(state)) as Contacts
  const agent = useSelector(state => selectAgent(state)) as Agent
  const customer = useSelector(state => selectCustomer(state)) as Customer
  const loanOfficer = useSelector(state =>
    selectLoanOfficer(state)
  ) as LoanOfficer
  const dealId = useSelector(state => selectDealId(state))
  const user = useSelector(state => selectUser(state)) as User
  const customerDeals = useSelector(state =>
    selectCustomerDeals(state)
  ) as CustomerDeals
  const openTemplateModal = useSelector(state =>
    selectOpenTemplateListModal(state)
  )
  const successfullySentMessage = useSelector(state =>
    selectSuccessfullySentMessage(state)
  )
  const unsuccessfulMessageSend = useSelector(state =>
    selectUnsuccessfulMessageSend(state)
  )
  // update connection
  const isUpdateConnectionModalOpen = useSelector(state =>
    selectOpenUpdateModal(state)
  )
  // add contacts
  const createDealModalOpen = useSelector(state =>
    selectOpenCreateDealModal(state)
  )
  const addContactsModalOpen = useSelector(state =>
    selectOpenAddContactsModal(state)
  )
  const additionalContacts = useSelector(state =>
    getAllAdditionalContacts(state)
  )
  // playbooks
  const playbookModalOpen = useSelector(state =>
    selectIsPlaybookModalOpen(state)
  )
  const createPlaybookModalOpen = useSelector(state =>
    selectIsCreatePlaybookModalOpen(state)
  )
  const selectedPlaybook = useSelector(state =>
    selectSelectedPlaybook(state)
  ) as Playbook | null
  const editingPlaybook = useSelector(state =>
    selectEditingPlaybook(state)
  ) as Playbook
  // Search Fields
  const [dealSearch, setDealSearch] = useState("")
  const [customerSearch, setCustomerSearch] = useState("")
  const [agentSearch, setAgentSearch] = useState("")
  const [ccs, setCCS] = useState([])
  const [bccs, setBCCS] = useState([])
  // Toggles
  const [isEmail, setIsEmail] = useState(true)
  const [displaySuccessBanner, setDisplaySuccessBanner] = useState(false)
  const [showSearchFields, setShowSearchFields] = useState(false)
  // Editable fields
  const [rawText, setRawText] = useState("")
  const [parsedTemplate, setParsedTemplate] = useState("")
  const [content, setContent] = useState("")
  const [loadedConfig, setLoadedConfig] = useState(false)
  const [cleverUserIdentity, setCleverUserIdentity] = useState("")
  // Data Sender
  const [selectedDataSender, setSelectedDataSender] = useState<any | null>(null)
  const [displaySelectDataSender, setDisplaySelectDataSender] = useState(false)
  const [displayDataSenderModal, setDisplayDataSenderModal] = useState(false)

  // Contact Options
  const [contactOptions, setContactOptions] = useState([])
  const [agentOptions, setAgentOptions] = useState([])
  const [subject, setSubject] = useState("")
  const [selectedContacts, setSelectedContacts] = useState([])
  const [viewContactDetails, setViewContactDetails] = useState(false)

  const [roleDropdowns, setRoleDropdowns] = useState([]) as any[]
  const [selectedOptionForRole, setSelectedOptionForRole] = useState({}) as any
  const [lockRoles, setLockRoles] = useState(false)
  const [noTemplate, setNoTemplate] = useState(false)
  const [invalidContact, setInvalidContact] = useState(false)

  // SMS Features
  const [useMessagingService, setUseMessagingService] = useState(false)
  ////
  // SSR issue
  ////
  const [loadedEditor, setLoadedEditor] = useState(false)
  const editorRef = useRef() as any

  const { ClassicEditor, CKEditor } = editorRef.current || {}

  const displayTemplateOptions =
    (selectedTemplate && selectedOptionForRole) || noTemplate

  useEffect(() => {
    if (dealId) {
      dispatch(getDealFromId(dealId))
    }
  }, [dealId])

  useEffect(() => {
    if (deal) {
      setCleverUserIdentity(
        deal.account_executive
          ? `${deal.account_executive} from Clever`
          : "Clever Home Concierge"
      )
    }
  }, [deal])

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = {
        CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
        ClassicEditor: require("@cleverrealestate/ckeditor5-build-classic"),
      }
      setLoadedEditor(true)
    }
  }, [])

  useEffect(() => {
    if (authLoading) {
      return
    }
    if (isAuthenticated && self) {
      dispatch(getUser())
    }
  }, [authLoading, isAuthenticated, self])

  useEffect(() => {
    // Keep fetching new deals if there is a next value
    if (customerDeals && customerDeals.next) {
      dispatch(getCustomerDealsFromURL(customerDeals.next))
    }
  }, [customerDeals])
  ////
  // END SSR issue
  ////

  useEffect(() => {
    if (successfullySentMessage && !selectedPlaybook) {
      setDisplaySuccessBanner(true)
      toast("Successfully sent communication!", {
        type: "success",
        position: "top-center",
      })
      setTimeout(() => {
        setDisplaySuccessBanner(false)
        dispatch(resetStateFlags())
        dispatch(resetAllPlaybookStates())
      }, 30000)
    }
  }, [successfullySentMessage])

  useEffect(() => {
    if (unsuccessfulMessageSend) {
      setDisplaySuccessBanner(true)
      setTimeout(() => {
        setDisplaySuccessBanner(false)
        dispatch(resetStateFlags())
      }, 30000)
    }
  }, [unsuccessfulMessageSend])

  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = match[1] ? "+1 " : ""
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
    }
    return null
  }

  const getFootnote = () => {
    let html = ""
    if (isEmail && user) {
      if (user.signature_link) {
        html = `<p>Best,</p>
          <a href="https://listwithclever.com"><img src="${user.signature_link}" alt="${user.first_name}" style="width:644px" width="644" height="187" /></a>`
      } else {
        html = `<p>Best,</p>
          <p>${user ? `${user.first_name} ${user.last_name || ""}` : ""}<br/>
            Clever Concierge<br/>
            <a href="tel: ${user.phone || "614-363-2845"}">${
          user.phone ? formatPhoneNumber(user.phone) : "614-363-2845"
        }</a><br/>
            <a href="mailto: team@movewithclever.com">team@movewithclever.com</a><br/>
            <a href="https://www.listwithclever.com/">www.listwithclever.com</a><br/>
            See our reviews on<br/>
            <img src="https://emailsignature.trustpilot.com/logo/s/1/logo@2x.png" srcset="https://emailsignature.trustpilot.com/logo/s/1/logo@2x.png" style="width:128px" width="128" /><br/>
            <img src="https://emailsignature.trustpilot.com/signature/en-US/1/5a95a55a4e08e80001875736/stars.png" srcset="https://emailsignature.trustpilot.com/signature/en-US/1/5a95a55a4e08e80001875736/stars.png" width="128" style="width:128px" /><br/>
            MO Real Estate Agent<br/>
        </p>`
      }
    } else {
      html = `Best,<br>
        ${user ? `${user.first_name} ${user.last_name || ""}` : ""}<br>
          Clever Concierge<br>
          ${user.phone || "614-363-2845"}<br>
          ${user.email || "team@movewithclever.com"}<br>`
    }
    return html
  }

  const renderWysiwygFromTemplate = async () => {
    if (
      selectedTemplate &&
      selectedTemplate &&
      selectedOptionForRole &&
      displayTemplateOptions &&
      lockRoles
    ) {
      // Check if sms opt out
      const renderedContext = {} as any
      const templateRoles = selectedTemplate.roles

      Object.keys(templateRoles).forEach((contextKey: string) => {
        const key = contextKey
        renderedContext[key] = selectedOptionForRole[key]
          ? selectedOptionForRole[key].value
          : null
      })
      if (selectedTemplate.tags.includes("email")) {
        const templateSubject = renderDjangoTemplate(
          selectedTemplate.subject,
          renderedContext
        )
        setSubject(templateSubject)
      }
      const output = renderDjangoTemplate(
        selectedTemplate.template,
        renderedContext
      )
      const html = getFootnote()
      let newOutput = output
        .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
        .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
        .replaceAll(/&nbsp;/gm, " ")
        .replaceAll(/&lt;/gm, "<")
        .replaceAll(/&gt;/gm, ">") // Hack to get the br tags to work correctly
      newOutput = newOutput.replaceAll(/{{ footnotes }}/gm, html)
      // Wait for element to become available
      const domEditableElement = await waitForElm(".ck-editor__editable")
      // Get the editor instance from the editable element.
      if (domEditableElement && newOutput) {
        const editorInstance = (domEditableElement as any).ckeditorInstance
        if (editorInstance) {
          // Use the editor instance API.
          if (!isEmail) {
            editorInstance.setData(newOutput)
            setContent(newOutput)
          } else {
            editorInstance.setData(newOutput)
            setContent(newOutput)
          }
          const plainText = viewToPlainText(
            editorInstance.editing.view.document.getRoot()
          )
          setRawText(plainText)
        }
      }
    }
  }

  useEffect(() => {
    renderWysiwygFromTemplate()
  }, [
    selectedTemplate,
    selectedOptionForRole,
    displayTemplateOptions,
    lockRoles,
  ])

  useEffect(() => {
    // Organize template roles
    // create the template dropdowns.
    // Lock em in
    // generate template
    if (selectedTemplate && Object.keys(selectedTemplate.roles).length > 0) {
      let selectedOptions = {}
      const dropdowns = Object.keys(selectedTemplate.roles as any).map(key => {
        const valueType = (selectedTemplate.roles as any)[key]
        let values = [] as any[]
        let preselectedOption = {}
        switch (valueType) {
          case "Cash Buyer":
            if (!additionalContacts || !additionalContacts.cash_buyers) {
              toast("Missing Cash Buyer Role(s)!", { type: "error" })
              break
            }
            values = values.concat(
              additionalContacts.cash_buyers.map((cb: any) => {
                return {
                  label: `${cb.firstname} ${cb.lastname}`,
                  value: cb,
                }
              })
            )
            if (additionalContacts.cash_buyers.length === 1) {
              preselectedOption = {
                [key]: {
                  label: `${additionalContacts.cash_buyers[0].firstname} ${additionalContacts.cash_buyers[0].lastname}`,
                  value: additionalContacts.cash_buyers[0],
                },
              }
            }
            break
          case "Agent":
            if (!additionalContacts || !additionalContacts.agents) {
              toast("Missing Agent Role(s)!", { type: "error" })
              break
            }
            values = values.concat(
              additionalContacts.agents.map((ag: any) => {
                return {
                  label: `${ag.firstname} ${ag.lastname}`,
                  value: ag,
                }
              })
            )
            if (additionalContacts.agents.length === 1) {
              preselectedOption = {
                [key]: {
                  label: `${additionalContacts.agents[0].firstname} ${additionalContacts.agents[0].lastname}`,
                  value: additionalContacts.agents[0],
                },
              }
            }
            break
          case "Loan Officer":
            if (!additionalContacts || !additionalContacts.loanOfficers) {
              toast("Missing Loan Officer Role(s)!", { type: "error" })
              break
            }
            values = values.concat(
              additionalContacts.loanOfficers.map((lo: any) => {
                return {
                  label: `${lo.firstname} ${lo.lastname}`,
                  value: lo,
                }
              })
            )
            if (additionalContacts.loanOfficers.length === 1) {
              preselectedOption = {
                [key]: {
                  label: `${additionalContacts.loanOfficers[0].firstname} ${additionalContacts.loanOfficers[0].lastname}`,
                  value: additionalContacts.loanOfficers[0],
                },
              }
            }
            break
          case "Customer":
            if (!additionalContacts || !additionalContacts.customers) {
              toast("Missing Customer Role(s)!", { type: "error" })
              break
            }
            values = values.concat(
              additionalContacts.customers.map((c: any) => {
                return {
                  label: `${c.firstname} ${c.lastname}`,
                  value: c,
                }
              })
            )
            if (additionalContacts.customers.length === 1) {
              preselectedOption = {
                [key]: {
                  label: `${additionalContacts.customers[0].firstname} ${additionalContacts.customers[0].lastname}`,
                  value: additionalContacts.customers[0],
                },
              }
            }
            break
          case "User":
            if (!user) {
              toast("Missing User Role(s)!", { type: "error" })
              break
            }
            values.push({
              label: `${user.first_name} ${user.last_name}`,
              value: user,
            })
            preselectedOption = {
              [key]: {
                label: `${user.first_name} ${user.last_name}`,
                value: user,
              },
            }
            break
          default:
            if (!deal && !customerDeals) {
              toast("Missing Deal Role(s)!", { type: "error" })
              break
            }
            if (customerDeals) {
              // Attach customer deals to roles.
              values = values.concat(
                customerDeals.results.map(customerDeal => {
                  return {
                    label: `${customerDeal.customer.firstname} ${customerDeal.customer.lastname} ${customerDeal.transaction_type} - ${customerDeal.hubspot_deal_id}`,
                    value: customerDeal,
                  }
                })
              )
            } else {
              values.push({
                label: `${deal.customer.firstname} ${deal.customer.lastname} ${deal.transaction_type} - ${deal.hubspot_deal_id}`,
                value: deal,
              })
            }
            if (customerDeals && customerDeals.results.length === 1) {
              preselectedOption = {
                [key]: {
                  label: `${customerDeals.results[0].customer.firstname} ${customerDeals.results[0].customer.lastname} ${customerDeals.results[0].transaction_type} - ${customerDeals.results[0].hubspot_deal_id}`,
                  value: customerDeals.results[0],
                },
              }
            } else if (customerDeals && customerDeals.results.length === 0) {
              preselectedOption = {
                [key]: {
                  label: `${deal.customer.firstname} ${deal.customer.lastname} ${deal.transaction_type} - ${deal.hubspot_deal_id}`,
                  value: deal,
                },
              }
            } else if (!customerDeals) {
              preselectedOption = {
                [key]: {
                  label: `${deal.customer.firstname} ${deal.customer.lastname} ${deal.transaction_type} - ${deal.hubspot_deal_id}`,
                  value: deal,
                },
              }
            }
            break
        }
        selectedOptions = {
          ...selectedOptions,
          ...preselectedOption,
        }
        return {
          label: key,
          type: (selectedTemplate.roles as any)[key],
          values: values,
        }
      })
      setIsEmail(selectedTemplate.tags.includes("email"))
      setSelectedOptionForRole(selectedOptions)
      setLockRoles(false)
      setRoleDropdowns(dropdowns)
    } else {
      // Handle scenario where there are no roles
      setLockRoles(true)
    }
  }, [selectedTemplate, additionalContacts])

  useEffect(() => {
    if (loadedEditor && ClassicEditor) {
      ClassicEditor.customTypes = ["footnotes"]
      ClassicEditor.defaultConfig = {
        ...ClassicEditor.defaultConfig,
        placeholderConfig: {
          types: ClassicEditor.customTypes,
        },
      }
      setLoadedConfig(true)
    }
  }, [loadedEditor])

  ////
  // Organize contacts
  ////
  const organizeContacts = () => {
    const winningAgent = contacts ? contacts.winning_agent : null
    const losingAgents = contacts ? contacts.losing_agents : []
    const loan_officer =
      loanOfficer || (contacts ? contacts.loan_officer : null)
    const customerOption = contacts
      ? contacts.customer
      : customer
      ? customer
      : null
    const cashBuyers = contacts ? contacts.cash_buyers : []
    // May need this later
    const agents = contacts ? contacts.agents : agent ? [{ ...agent }] : []

    let winningAgentOptions = [] as any
    let losingAgentOptions = [] as any
    let loanOfficerOptions = [] as any
    let customerOptions = [] as any
    let cashBuyerOptions = [] as any
    if (customerOption) {
      customerOptions = [
        { label: "Customer", value: null, disabled: true },
        {
          label: `${customerOption.firstname} ${customerOption.lastname || ""}`,
          value: customerOption,
        },
      ]
    }
    if (winningAgent) {
      winningAgentOptions = [
        { label: "Winning Agent", value: null, disabled: true },
        {
          label: `${winningAgent?.firstname} ${winningAgent?.lastname || ""}`,
          value: winningAgent,
        },
      ]
    }
    if (losingAgents && losingAgents.length > 0) {
      losingAgentOptions.push({
        label: "Losing Agents",
        value: null,
        disabled: true,
      })
      const allLosingAgents = losingAgents.map(agent => {
        return {
          label: `${agent.firstname} ${agent.lastname || ""}`,
          value: agent,
        }
      })
      losingAgentOptions.push(...(allLosingAgents as any))
    }

    if (loan_officer) {
      loanOfficerOptions.push({
        label: "Loan Officer",
        value: null,
        disabled: true,
      })
      loanOfficerOptions.push({
        label: `${loan_officer.firstname} ${loan_officer.lastname}`,
        value: loan_officer,
      })
    }

    if (cashBuyers && cashBuyers.length > 0) {
      cashBuyerOptions.push({
        label: "Cash Buyers",
        value: null,
        disabled: true,
      })
      const allCashBuyers = cashBuyers.map(buyer => {
        return {
          label: `${buyer.firstname} ${buyer.lastname || ""}`,
          value: buyer,
        }
      })
      cashBuyerOptions.push(...(allCashBuyers as any))
    }
    const filterOutUnusedAgents = agents
      .filter(ag => {
        if (loan_officer && ag.vid === loan_officer.vid) {
          return false
        }
        if (winningAgent && ag.vid === winningAgent.vid) {
          return false
        }
        const losingAgentFilter = losingAgents.filter(
          secretAgent => secretAgent.vid === ag.vid
        )
        if (losingAgentFilter.length > 0) {
          return false
        }
        return true
      })
      .map(newAg => {
        return {
          label: `${newAg.firstname} ${newAg.lastname || ""}`,
          value: newAg,
          disabled: false,
        }
      })

    if (filterOutUnusedAgents.length > 0) {
      filterOutUnusedAgents.unshift({
        label: "Other Agents",
        value: null as any,
        disabled: true,
      })
    }

    const finalOptions = [
      ...winningAgentOptions,
      ...losingAgentOptions,
      ...loanOfficerOptions,
      ...customerOptions,
      ...cashBuyerOptions,
      ...filterOutUnusedAgents,
    ] as any
    setContactOptions(finalOptions)
    setAgentOptions([
      ...winningAgentOptions,
      ...losingAgentOptions,
      ...filterOutUnusedAgents,
    ] as any)
  }

  const setUpCustomerContactOptions = () => {
    setContactOptions([
      {
        label: `${customer.firstname} ${customer.lastname || ""}`,
        value: customer,
      },
    ] as any)
  }

  const setUpAgentContactOptions = () => {
    setContactOptions([
      {
        label: `${agent.firstname} ${agent.lastname}`,
        value: agent,
      },
    ] as any)
  }

  useEffect(() => {
    if (contacts) {
      organizeContacts()
    }
  }, [contacts])

  useEffect(() => {
    if (loanOfficer) {
      // fetch deals
      organizeContacts()
      dispatch(getDealsFromCustomerId({ vid: loanOfficer.vid }))
    }
  }, [loanOfficer])

  useEffect(() => {
    if (customer) {
      setUpCustomerContactOptions()
      dispatch(getDealsFromCustomerId({ vid: customer.id }))
    }
  }, [customer])

  useEffect(() => {
    if (agent) {
      // fetch deals
      setUpAgentContactOptions()
      dispatch(getDealsFromCustomerId({ vid: agent.vid }))
    }
  }, [agent])

  ////
  // onChange Events
  ////
  const handleToggle = () => {
    setIsEmail(!isEmail)
  }

  const handleContactSelect = (value: any) => {
    setSelectedContacts(value)
    const { notOptedInFlag, names } = checkContactsForSMSOptOut(
      selectedTemplate,
      value
    )
    if (notOptedInFlag) {
      setInvalidContact(true)
      toast(
        `WARNING: One or more of the contacts ( ${names} ) that will receive one of the communications are opted out of sms or has not opted in to text messages. Please re-select a playbook that does not send this contact an sms OR skip this template.`,
        { type: "error", position: "top-center" }
      )
      // window.alert(`WARNING: One or more of the contacts ( ${OPTED_NAMES} ) that will receive one of the communications are opted out of sms or has not opted in to text messages. Please re-select a playbook that does not send this contact an sms OR skip this template.`)
    } else {
      setInvalidContact(false)
    }
  }

  const openTemplate = () => {
    setLockRoles(false)
    dispatch(toggleTemplateListModal())
  }

  const openPlaybooks = () => {
    dispatch(openPlaybookModal())
  }

  const handleTemplateChange = (event: any, editor: any) => {
    if (editor) {
      const value = editor.getData()
      const plainText = viewToPlainText(editor.editing.view.document.getRoot())
      setRawText(plainText)
      setParsedTemplate(value)
      setContent(value)
    }
  }

  const sendCommunication = () => {
    if (isEmail) {
      let newOutput = content
        .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
        .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
        .replaceAll(/&nbsp;/gm, " ")
      const html = getFootnote()
      newOutput = newOutput.replaceAll(/{{ footnotes }}/gm, html)
      const contactIds = selectedContacts.map((contact: any) => {
        if (contact.value.vid) {
          return contact.value.vid
        } else {
          return contact.value.id
        }
      })

      const emailCommunication = {
        to: contactIds,
        subject: subject,
        body: newOutput,
        cc: ccs.map((tag: any) => tag.name),
        bcc: bccs.map((tag: any) => tag.name),
      } as EmailCommunicationObject
      dispatch(sendCommunicationEmailToContacts(emailCommunication))
    } else {
      let newOutput = rawText
        .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
        .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
        .replaceAll(/&nbsp;/gm, " ")
      const html = getFootnote()
      newOutput = newOutput.replaceAll(/{{ footnotes }}/gm, html)

      const contactIds = selectedContacts.map((contact: any) => {
        if (contact.value.vid) {
          return contact.value.vid
        } else {
          return contact.value.id
        }
      })
      let smsCommunication = {
        to: contactIds,
        body: newOutput,
        use_messaging_service:
          contactIds.length > 1 ? useMessagingService : false,
      } as SMSCommunicationObject
      if (contactIds.length > 1 && cleverUserIdentity) {
        smsCommunication = {
          ...smsCommunication,
          clever_user_identity: cleverUserIdentity,
        }
      }
      dispatch(sendCommunicationSMSToContacts(smsCommunication))
    }
  }

  const onDealSearch = () => {
    if (dealSearch !== "") {
      dispatch(getContactsFromId(dealSearch))
    }
  }

  const onCustomerSearch = () => {
    if (customerSearch !== "") {
      dispatch(getCustomerFromId(customerSearch))
    }
  }

  const onAgentSearch = () => {
    if (agentSearch !== "") {
      dispatch(getAgentFromId(agentSearch))
    }
  }

  const handleBlur = (event: any, editor: any) => {
    const value = editor.getData()
    const html = getFootnote()
    let newOutput = value
      .replaceAll(/<span[^>]+>([^<]+)<\/span>/gm, "$1") // django rendered elements remove the span tags
      .replaceAll(/<span[^>]+><\/span>/gm, "") // Empty placeholder elements without this the plugin crashes
      .replaceAll(/&nbsp;/gm, " ")
    newOutput = newOutput.replaceAll(/{{ footnotes }}/gm, html)
    editor.setData(newOutput)
    let plainText = viewToPlainText(editor.editing.view.document.getRoot())
    setRawText(plainText)
  }

  const onClear = () => {
    dispatch(resetAll())
    dispatch(resetTemplate())
    setAgentSearch("")
    setCustomerSearch("")
    setDealSearch("")
    setParsedTemplate("")
    setContent("")
    setSubject("")
    setRawText("")
    setContactOptions([])
    setSelectedContacts([])
  }

  const onDelete = (i: any) => {
    const newTags = ccs.slice(0)
    newTags.splice(i, 1)
    setCCS(newTags)
  }

  const onAddition = (tag: any) => {
    const newTags = [].concat(ccs, tag)
    setCCS(newTags)
  }

  const onDeleteBcc = (i: any) => {
    const newTags = bccs.slice(0)
    newTags.splice(i, 1)
    setBCCS(newTags)
  }

  const onAdditionBCC = (tag: any) => {
    const newTags = [].concat(bccs, tag)
    setBCCS(newTags)
  }

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onValidate = (tag: any) => {
    return validateEmail(tag.name)
  }

  const closeCreatePlaybook = () => {
    dispatch(closeCreatePlaybookModal())
  }

  const handleOptionRoleChange = (key: string, value: any) => {
    //key is dropdown label value is the value of the dropdown
    const newSelectedOptionForRole = {
      ...selectedOptionForRole,
      [key]: value,
    }
    setSelectedOptionForRole(newSelectedOptionForRole)
  }

  const lockAllRoles = () => {
    if (lockRoles) {
      setLockRoles(false)
    } else {
      // Check to see that all the roles have been filled out.
      setLockRoles(true)
      if (roleDropdowns.length === Object.keys(selectedOptionForRole).length) {
        // Its ok to lock em
        setLockRoles(true)
      } else {
        toast(
          "Please make sure you'ce selected ALL the roles before continuing. If you want to fill out a dropdown but there is no option, you will need to select or create a new template.",
          { type: "error" }
        )
        // window.alert("Please make sure you've selected all the roles before continuing. If you want to fill out a dropdown but there is no option, you will need to select or create a new template.")
      }
    }
  }

  const handleNoTemplate = () => {
    setLockRoles(true)
    setNoTemplate(true)
  }

  const openUpdateFormModal = () => {
    dispatch(openUpdateModal(true))
  }

  const openCreateDealFormModal = () => {
    dispatch(openCreateDealModal(true))
  }

  const addNewContacts = () => {
    dispatch(openAddContactsModal(true))
  }

  const handleDataSender = () => {
    setDisplaySelectDataSender(true)
  }

  const onSelectDataSender = (dataSender: DataSender) => {
    setDisplaySelectDataSender(false)
    setSelectedDataSender({ data_sender: dataSender })
    setDisplayDataSenderModal(true)
  }

  const hideDataSenderFormModal = () => {
    setDisplayDataSenderModal(false)
    setSelectedDataSender(null)
    dispatch(resetDataStates())
  }

  const displayCleverUserIdentity = useMemo(() => {
    if (selectedContacts && selectedContacts?.length > 1 && !isEmail) {
      return true
    }
    return false
  }, [selectedContacts, isEmail])

  return (
    <>
      <div className="communicationModuleContainer">
        <div className="communicationTitle">
          <h2>Communications</h2>
          <div>
            {contacts && (
              <button className="defaultButton" onClick={openUpdateFormModal}>
                Update Connection
              </button>
            )}
          </div>
          <div>
            {customer && (
              <button
                className="defaultButton"
                onClick={openCreateDealFormModal}
              >
                Create Deal
              </button>
            )}
          </div>
          <div className="deals">
            <p>
              {contacts
                ? "Deal Id"
                : agent
                ? "Agent Id"
                : customer
                ? "Customer Id"
                : "Loan Officer Id"}
              : #
              {dealId
                ? dealId
                : agent
                ? agent.vid
                : customer
                ? customer.id
                : loanOfficer
                ? loanOfficer.vid
                : "N/A"}
            </p>
            {/* <a href="">Hubspot Link</a> */}
            <div>
              {contacts && (
                <button
                  className="defaultButton__small"
                  onClick={() => setViewContactDetails(!viewContactDetails)}
                >
                  {viewContactDetails
                    ? "Hide Contact Details"
                    : "Show Contact Details"}
                </button>
              )}
              <button
                className="defaultButton__small"
                onClick={() => setShowSearchFields(!showSearchFields)}
              >
                {showSearchFields ? "Hide Search Fields" : "Show Search Fields"}
              </button>
            </div>
          </div>
        </div>
        {viewContactDetails && contacts && (
          <ContactDetails contacts={contacts} />
        )}
        {showSearchFields && (
          <div className="searchById">
            <div className="field">
              <label>Search By Deal Id</label>
              <div className="searchField">
                <input
                  value={dealSearch}
                  onChange={e => setDealSearch(e.target.value)}
                />
                <button onClick={onDealSearch}>Search</button>
              </div>
            </div>
            <div className="field">
              <label>Search By Customer Id</label>
              <div className="searchField">
                <input
                  value={customerSearch}
                  onChange={e => setCustomerSearch(e.target.value)}
                />
                <button onClick={onCustomerSearch}>Search</button>
              </div>
            </div>
            <div className="field">
              <label>Search By Agent Id</label>
              <div className="searchField">
                <input
                  value={agentSearch}
                  onChange={e => setAgentSearch(e.target.value)}
                />
                <button onClick={onAgentSearch}>Search</button>
              </div>
            </div>
          </div>
        )}
        <div className="selectContactsContainer">
          <button
            className="defaultButton__small"
            onClick={() => addNewContacts()}
          >
            Add New Contact
          </button>
        </div>
        {/* If just template */}
        {!selectedPlaybook && (
          <>
            <p className="noMargin">Select Your Contacts First.</p>
            <div className="communicationsContactsContainer">
              <div className="selectContactsContainer">
                <p>Select contact(s) *</p>
                {/* Dropdown for contacts */}
                <MultiSelect
                  className="multiSelect"
                  value={selectedContacts}
                  onChange={(value: any) => handleContactSelect(value)}
                  options={contactOptions}
                  labelledBy={"select"}
                  overrideStrings={{
                    selectSomeItems: "Select Contact",
                    allItemsAreSelected: "All Contacts Selected",
                  }}
                />
              </div>
              <div className="toggleEmailContainer">
                <p>SMS</p>
                <Toggle onClick={handleToggle} on={isEmail} />
                <p>EMAIL</p>
              </div>
            </div>
            <div className="templateContainer">
              <div className="selectTemplatesContainer flex flex-row">
                <button onClick={openTemplate}>Select Template</button>
                <button className="playbookButton" onClick={openPlaybooks}>
                  Select Playbook
                </button>
                <button
                  style={{ margin: "0 0.5rem 0.5rem 0.5rem" }}
                  onClick={() => handleNoTemplate()}
                >
                  No Template
                </button>
                {contacts && (
                  <button
                    style={{ margin: "0 0.5rem 0.5rem 0.5rem" }}
                    onClick={() => handleDataSender()}
                  >
                    Data Sender
                  </button>
                )}
              </div>
            </div>
            {selectedTemplate && roleDropdowns.length > 0 && (
              <div className="playbookRoles">
                <p className="selectRoleTitle">
                  Select the template roles{" "}
                  <button className="lockButton" onClick={() => lockAllRoles()}>
                    {lockRoles ? <Locked /> : <Unlocked />}
                  </button>
                </p>
                {selectedTemplate && roleDropdowns.length > 0 && (
                  <div className="selectList">
                    {roleDropdowns.map((dropdown: any, index: any) => {
                      return (
                        <div
                          key={`dropdownItem-${index}`}
                          className="dropdownContainer"
                        >
                          <label>{dropdown.label}</label>
                          <Select
                            className="defaultSelect"
                            value={
                              selectedOptionForRole
                                ? selectedOptionForRole[dropdown.label]
                                : null
                            }
                            options={dropdown.values}
                            onChange={value =>
                              handleOptionRoleChange(dropdown.label, value)
                            }
                            isDisabled={lockRoles}
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )}
            {!isEmail && (
              <div
                style={{
                  margin: "1rem 0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                  }}
                >
                  <label style={{ fontSize: "0.875rem" }}>
                    Use Messaging Service (experimental feature)
                  </label>
                  <Toggle
                    onClick={() => setUseMessagingService(!useMessagingService)}
                    on={useMessagingService}
                  />
                </div>
                <p style={{ fontSize: "0.65rem", marginBottom: 0 }}>
                  <b style={{ fontWeight: "bolder" }}>NOTE: </b>The "Use
                  Messaging Service" will use an external Messaging Service
                  (that internally uses Twilio Provider) for sending Group Text
                  SMSs , instead of using Bandwidth Provider. This param will be
                  ignored if only one contact is selected for sending to.
                </p>
              </div>
            )}
            {displayCleverUserIdentity && (
              <div
                style={{
                  margin: "0.5rem 0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label style={{ fontSize: "0.875rem" }}>
                  Clever User Identity{" "}
                  <small>(for manually sending group texts)</small>
                </label>
                <input
                  style={{
                    fontSize: "1rem",
                    padding: "0.5rem",
                  }}
                  className="subject"
                  value={cleverUserIdentity}
                  onChange={e => setCleverUserIdentity(e.target.value)}
                />
              </div>
            )}
            {isEmail && (
              <div className="subjectContainer field">
                <label>CC</label>
                <ReactTags
                  placeholderText="Add CC"
                  tags={ccs}
                  allowNew
                  suggestions={[]}
                  onDelete={onDelete}
                  onAddition={onAddition}
                  onValidate={onValidate}
                />
              </div>
            )}
            {isEmail && (
              <div className="subjectContainer field">
                <label>BCC</label>
                <ReactTags
                  placeholderText="Add BCC"
                  tags={bccs}
                  allowNew
                  suggestions={[]}
                  onDelete={onDeleteBcc}
                  onAddition={onAdditionBCC}
                  onValidate={onValidate}
                />
              </div>
            )}
            {isEmail && (
              <div className="subjectContainer">
                <label>Subject</label>
                <input
                  className="subject"
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                />
              </div>
            )}
            {displayTemplateOptions && lockRoles && (
              <div className="wysiwygEditorContainer">
                <label>Body</label>
                {loadedEditor && loadedConfig && (
                  <CKEditor
                    onBlur={handleBlur}
                    editor={ClassicEditor}
                    data={content || ""}
                    onChange={handleTemplateChange}
                  />
                )}
              </div>
            )}
            <div className="communicationDisplay">
              <button
                disabled={!lockRoles || invalidContact}
                className="sendCommunicationButton"
                onClick={() => sendCommunication()}
              >
                Send Communication
              </button>
            </div>
          </>
        )}
        {selectedPlaybook && <PlaybookCommunication />}
        {!selectedPlaybook && (
          <div className="communicationDisplay">
            {displaySuccessBanner && successfullySentMessage && (
              <p className="success">Successfully Sent Communication</p>
            )}
            {displaySuccessBanner && unsuccessfulMessageSend && (
              <p className="error">
                There was an error sending your communication. Please try again.
              </p>
            )}
          </div>
        )}
      </div>
      {openTemplateModal && (
        <TemplateModal
          onClose={() => dispatch(toggleTemplateListModal())}
          isEmailTemplate={isEmail}
        />
      )}
      {playbookModalOpen && (
        <PlaybooksModal onClose={() => dispatch(closePlaybookModal())} />
      )}
      {createPlaybookModalOpen && (
        <CreatePlaybookModal
          isEditing={editingPlaybook !== null}
          onClose={() => closeCreatePlaybook()}
        />
      )}
      {isUpdateConnectionModalOpen && (
        <div className="templateModal">
          <div className="templateContentContainer">
            <UpdateForm />
          </div>
        </div>
      )}
      {addContactsModalOpen && (
        <div className="templateModal">
          <div className="templateContentContainer">
            <AddNewContactModal />
          </div>
        </div>
      )}
      {createDealModalOpen && (
        <div className="templateModal">
          <div className="templateContentContainer">
            <CreateDealModal />
          </div>
        </div>
      )}
      {displaySelectDataSender && (
        <div className="templateModal">
          <div className="templateContentContainer">
            <DataSenderModal
              onClose={() => setDisplaySelectDataSender(false)}
              onSelect={(dataSender: any) => onSelectDataSender(dataSender)}
            />
          </div>
        </div>
      )}
      {displayDataSenderModal && selectedDataSender && contacts && (
        <DataSenderFormModal
          contacts={contacts}
          onSkip={() => hideDataSenderFormModal()}
          onNext={() => hideDataSenderFormModal()}
          currentDataSender={selectedDataSender}
        />
      )}
      <ToastContainer newestOnTop />
    </>
  )
}

export default CommunicationModule
