import React from 'react';
import './Loading.scss';

const Loading = ({
  color = '#000',
}) => (
  <div className="loadingContainer">
    <div className="loader" style={{ color: color }} />
  </div>
);

export default Loading;