import React from 'react';
import cx from 'classnames';
import './Toggle.scss';

const Toggle = ({
  on,
  onClick
}: any) => {

  return (
    <div className={cx('toggleContainer', { ['toggleOn']: on })} onClick={onClick}>
      <div className={cx({['on']: on, ['off']: !on})}>
        <span />
      </div>
    </div>
  )
}

export default Toggle;
