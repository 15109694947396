import React, { Fragment, useEffect, useState } from "react"
import { FieldTypes } from "../../../../utils/fieldTypes"
import "react-datepicker/dist/react-datepicker.css"
import {
  Date,
  Dropdown,
  RadioText,
  RadioNumber,
  TextArea,
  TextField,
} from "../components"
import { getSwitchValue } from "../DataSenderContainer/config"
import NumberInput from "../components/NumberInput"
import Collapsible from "../components/Collapsible"

interface DefaultFormBuilderInterface {
  config: any[] | null, // Array of field configurations
  defaultData?: any, // Default data to be used in the form
}

const ConditionalWrap = ({ condition, wrap, children }: any) => condition ? wrap(children) : children;

const useDefaultFormBuilder = ({ config, defaultData = {} }: DefaultFormBuilderInterface) => {
  const [data, setData] = useState(defaultData) as any
  const [showHiddenFields, setShowHiddenFields] = useState(false);

  const deleteFieldValue = (key: string) => {
    const updatedField = Object.assign({}, { ...data })

    if (updatedField[key]) {
      delete updatedField[key]
      setData(updatedField)
    }
  }

  // Check field for data triggers
  const checkFieldCriteria = (data: any, fieldItem: any) => {
    const { triggers } = fieldItem

    // If no triggers key then its just a default field;
    if (!triggers) {
      return true
    }

    const tests = Object.keys(triggers).every(trigger => {
      const test = triggers[trigger]
      const storedValue = data[trigger]

      if (!storedValue || storedValue === null || storedValue === "") {
        // No key in the data or empty data set
        return false
      }

      // Check if the value is a function and pass the value
      if (typeof test === "function") {
        return test(storedValue)
      }

      // Check if one of the Trigger values is in the data
      if (Array.isArray(test)) {
        return test.some(value => storedValue === value)
      }

      if (typeof test === "string") {
        return storedValue === test
      }

      return false
    })

    return tests
  }

  const checkListenerFieldObject = (fieldItem: any) => {
    if (fieldItem.listenerFieldObject) {
      // get value
      const { currentFieldObject, data: customerData, listenerFieldKey } = fieldItem.listenerFieldObject;
      const newParams = {
        [listenerFieldKey]: {
          options: [data[listenerFieldKey]],
          type: 'dropdown',
        }
      }
      const newValue = getSwitchValue(customerData, currentFieldObject.item, currentFieldObject.cases, newParams)
      if (data[fieldItem.keyName] !== newValue) {
        setData((data: any) => Object.assign({ ...data, [fieldItem.keyName]: newValue }))
      }
      return newValue;
    }

    return data[fieldItem.keyName];
  }

  const getJSXFieldElement = (fieldItem: any) => {
    if (!checkFieldCriteria(data, fieldItem)) {
      deleteFieldValue(fieldItem.keyName)
      return <Fragment key={fieldItem.keyName}></Fragment>
    }

    switch (fieldItem.type) {
      case FieldTypes.number:
        return (
          <ConditionalWrap
            condition={fieldItem.hide}
            wrap={(children : any) => <Collapsible collapsed={!showHiddenFields}>{children}</Collapsible>}
          >
            <NumberInput
              disableFloat={true}
              disablePaste={false}
              disabled={fieldItem.disabled || false}
              field={fieldItem}
              onChange={(value: any) => {
                setData((data: any) => 
                  Object.assign({ ...data, [fieldItem.keyName]: value })
                )
              }}
              value={checkListenerFieldObject(fieldItem)}
              key={fieldItem.keyName}
            />
          </ConditionalWrap>
        )
      case FieldTypes.textfield:
        return (
          <ConditionalWrap
            condition={fieldItem.hide}
            wrap={(children : any) => <Collapsible collapsed={!showHiddenFields}>{children}</Collapsible>}
          >
            <TextField
              fieldItem={fieldItem}
              disabled={fieldItem.disabled || false}
              onChange={(value: string) =>
                setData((data: any) =>
                  Object.assign({ ...data, [fieldItem.keyName]: value })
                )
              }
              value={checkListenerFieldObject(fieldItem)}
              key={fieldItem.keyName}
            />
          </ConditionalWrap>
        )
      case FieldTypes.textarea:
        return (
          <ConditionalWrap
            condition={fieldItem.hide}
            wrap={(children : any) => <Collapsible collapsed={!showHiddenFields}>{children}</Collapsible>}
          >
            <TextArea
              fieldItem={fieldItem}
              onChange={(value: string) =>
                setData((data: any) =>
                  Object.assign({ ...data, [fieldItem.keyName]: value })
                )
              }
              value={data[fieldItem.keyName] || ""}
              key={fieldItem.keyName}
            />
          </ConditionalWrap>
        )
      case FieldTypes.dropdown:
        return (
          <ConditionalWrap
            condition={fieldItem.hide}
            wrap={(children : any) => <Collapsible collapsed={!showHiddenFields}>{children}</Collapsible>}
          >
            <Dropdown
              fieldItem={fieldItem}
              onChange={(value: string) =>
                setData((data: any) =>
                  Object.assign({ ...data, [fieldItem.keyName]: value })
                )
              }
              value={data[fieldItem.keyName] || ""}
              key={fieldItem.keyName}
            />
          </ConditionalWrap>
        )
      case FieldTypes.radioNumber:
        return (
          <ConditionalWrap
            condition={fieldItem.hide}
            wrap={(children : any) => <Collapsible collapsed={!showHiddenFields}>{children}</Collapsible>}
          >
            <RadioNumber
              fieldItem={fieldItem}
              onChange={(value: string) =>
                setData((data: any) =>
                  Object.assign({ ...data, [fieldItem.keyName]: value })
                )
              }
              value={data[fieldItem.keyName] || ""}
              key={fieldItem.keyName}
            />
          </ConditionalWrap>
        )
      case FieldTypes.radioText:
        return (
          <ConditionalWrap
            condition={fieldItem.hide}
            wrap={(children : any) => <Collapsible collapsed={!showHiddenFields}>{children}</Collapsible>}
          >
            <RadioText
              fieldItem={fieldItem}
              onChange={(value: string) =>
                setData((data: any) =>
                  Object.assign({ ...data, [fieldItem.keyName]: value })
                )
              }
              value={data[fieldItem.keyName] || ""}
              key={fieldItem.keyName}
            />
          </ConditionalWrap>
        )
      case FieldTypes.date:
        return (
          <ConditionalWrap
            condition={fieldItem.hide}
            wrap={(children : any) => <Collapsible collapsed={!showHiddenFields}>{children}</Collapsible>}
          >
            <Date
              fieldItem={fieldItem}
              onChange={(value: string) =>
                setData((data: any) =>
                  Object.assign({ ...data, [fieldItem.keyName]: value })
                )
              }
              value={data[fieldItem.keyName] || ""}
              key={fieldItem.keyName}
            />
          </ConditionalWrap>
        )
      case FieldTypes.header:
        return (
          <h3 key={fieldItem.keyName} className="fieldHeader">
            {fieldItem.label}
          </h3>
        )
      default:
        return <></>
    }
  }

  const getGlobalHideButton = () => {
    if (config!.filter((item: any) => item.hide).length > 0) {
      // add a display hidden fields button
      return <button className={`default-button__small ${showHiddenFields && 'button-collapsed'}`} onClick={() => setShowHiddenFields(!showHiddenFields)}>{showHiddenFields ? 'Hide Hidden Fields' : 'Show Hidden Fields'}</button>
    } else {
      return <></>
    }
  }

  const getFields = () => {
    if (config) {
      return [...config.map((field: any) => getJSXFieldElement(field)), getGlobalHideButton()]
    }

    // No fields No dice
    return []
  }
  const [allFields, setAllFields] = useState(getFields())

  useEffect(() => {
    if (config) {
      setAllFields(getFields())
    }
  }, [config])

  useEffect(() => {
    if (data) {
      setAllFields(getFields())
    }
  }, [data])

  return [allFields, data]
}

export default useDefaultFormBuilder
