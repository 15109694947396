export const getTemplates = (state: any) => state.templates.templates;
export const isEditingModalOpen = (state: any) => state.templates.openEditingTemplateModal;
export const selectEditingTemplate = (state: any) => state.templates.editingTemplate;
export const selectEditingType = (state: any) => state.templates.editingType;
export const selectCreateTemplateSuccess = (state: any) => state.templates.createTemplateSuccess;
export const selectOpenTemplateListModal = (state: any) => state.templates.openTemplateListModal;
export const getSelectedTemplate = (state: any) => state.templates.selectedTemplate;
export const selectDeleteTemplateSuccess = (state: any) => state.templates.deleteTemplateSuccess;
// Playbooks
export const selectOpenPlaybookTemplateList = (state: any) => state.templates.openPlaybookTemplateList;
export const selectSelectedPlaybookTemplate = (state: any) => state.templates.selectedPlaybookTemplate;