import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch } from '../../../store';
import { getAllTemplates } from '../../../store/Templates/actions';
import { getTemplates, selectDeleteTemplateSuccess, selectSelectedPlaybookTemplate } from '../../../store/Templates/selectors';
import { toggleEditingTemplateModal, setEditingTemplate, setTemplateType, EditingType, selectTemplate, resetState, selectPlaybookTemplate, clearSelectedPlaybookTemplate } from '../../../store/Templates/reducer';
import Template from '../../../Interfaces/template';
import { filterTemplateFoldersByString, organizeTemplatesByFolderTag } from '../../../utils/helpers';
import FolderImage from '../../../images/FolderImage.png';

const TemplatePlaybookModal = ({ onClose, playbookRoles, onSubmit }: any) => {
  const templates = useSelector((state) => getTemplates(state)) as Template[];
  const deleteTemplateSuccess = useSelector((state) => selectDeleteTemplateSuccess(state));
  const [roleTranslation, setRoleTranslation] = useState({});
  const [sendTo, setSendTo] = useState([]);
  const [folders, setFolders] = useState({}) as any;
  const [openFolder, setOpenFolder] = useState(null) as any;
  const [expandedCell, setExpandedCell] = useState(null) as any;

  const dispatch = useAppDispatch();
  const selectedPlaybookTemplate = useSelector((state) => selectSelectedPlaybookTemplate(state)) as any;

  const getTemplateRoles = () => {
    if (selectedPlaybookTemplate && selectedPlaybookTemplate.roles) {
      return selectedPlaybookTemplate.roles;
    } else {
      return {};
    }
  }
  const templateRoles = getTemplateRoles()
  
  useEffect(() => {
    if (!templates) {
      dispatch(getAllTemplates())
    }
  }, [])

  useEffect(() => {
    if (deleteTemplateSuccess) {
      dispatch(getAllTemplates())
      dispatch(resetState());
    }
  }, [deleteTemplateSuccess])

  useEffect(() => {
    if (templates) {
      const FolderObjects = organizeTemplatesByFolderTag(templates);
      setFolders(FolderObjects);
    }
  }, [templates])

  const handleCreateSMSTemplateClick = () => {
    dispatch(toggleEditingTemplateModal())
    dispatch(setEditingTemplate(null));
    dispatch(setTemplateType(EditingType.SMS))
  }

  const handleCreateEmailTemplateClick = () => {
    dispatch(toggleEditingTemplateModal())
    dispatch(setEditingTemplate(null));
    dispatch(setTemplateType(EditingType.Email))
  }

  const folderClicked = (e: any, key: string) => {
    e.preventDefault()
    e.stopPropagation();
    if (key && openFolder === key) {
      setOpenFolder(null)
    } else {
      setOpenFolder(key);
    }
  }

  const expandCell = (e: any, id: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (expandedCell === id) {
      setExpandedCell(null);
    } else {
      setExpandedCell(id);
    }
  }

  const onEdit = (template: any, e: any) => {
    e.stopPropagation();
    dispatch(setEditingTemplate(template));
    const isSMS = template.tags.indexOf('sms') > -1;
    dispatch(setTemplateType(isSMS ? EditingType.SMS : EditingType.Email))
    dispatch(toggleEditingTemplateModal());
  }

  const onSelect = (template: any, e: any) => {
    e.stopPropagation();
    dispatch(selectPlaybookTemplate(template))
  }

  const displayFolders = () => {
    return Object.keys(folders).map((folderKey: any, index: number) => (
      <tr key={`${folderKey.toLowerCase().replace(/\s/g, '_')}-${index}`} id={`${folderKey.toLowerCase().replace(/\s/g, '_')}-${index}`} className={`folderContainerMain ${openFolder === folderKey && 'folderOpen'}`} onClick={(e) => folderClicked(e, folderKey)}>
        <td colSpan={4} className="folderContainer" >
          <div className="folder">
            <div className="folderItemTitle">
              <div className="titleFolderIcon">
                <img width="20" height="20" src={FolderImage} />
                <p>{folderKey}</p>
              </div>
              <div className="arrow"></div>
            </div>
            {openFolder === folderKey && (
              <div className="folderTemplates">
                {(folders[folderKey] as any).map((template: any, templateIndex: number) => (
                  <div key={`${template.id}-${index}-${templateIndex}`} id={`${template.id}-${index}-${templateIndex}`} className={`template ${expandedCell === template.id && 'expanded'}`}>
                    <div className="blurBg" />
                    <button className="expandButton" onClick={(e) => expandCell(e, template.id)}>{expandedCell === template.id ? 'Collapse' : 'Expand'}</button>
                    <div className="descriptions">
                      <p className="smallText templateName"><strong>{template.name}</strong></p>
                      {template && template.subject && (<p className="noMarg smallText subject"><strong>Subject: </strong>{template.subject}</p>)}
                      <p className="smallText"><strong>Description: </strong>{template.description}</p>
                    </div>
                    <div className="roles">
                      <p className="smallText subject"><strong>Required Roles: {template.roles ? Object.keys(template.roles).map(role => template.roles[role]).join(', ') : ''}</strong></p>
                    </div>
                    <div className="actions">
                      <button className="editButton" onClick={(e) => onEdit(template, e)}>Edit</button>
                      <button className="selectButton" onClick={(e) => onSelect(template, e)}>Select</button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </td>
      </tr>
    ))
  }

  const reSelectTemplate = () => {
    dispatch(clearSelectedPlaybookTemplate());
    // Reset Roles and send to
    setSendTo([]);
    setRoleTranslation({})
  }

  // Get all playbook roles that match the template role object type
  const getTemplateRoleMapPossibilities = (templateRoleObjectType: any) => {
    // Filter keys to see if their object type is the same
    const playbookRoleKeysAvailable = Object.keys(playbookRoles).filter((playbookKey) => {
      return playbookRoles[playbookKey] === templateRoleObjectType;
    }).map((key) => {
      return { label: key, value: key }
    })
    return playbookRoleKeysAvailable;
  }

  // Key is the template key i.e agent_1
  // value is the react select option i.e { label: "competing_agent_1", value: "competing_agent_1"}
  const updateRoleTranslations = (key: any, value: any) => {
    // from here set the role translations
    // Map the template key to the playbook role selected
    // 1. copy the current role translations
    const newObject = Object.assign({}, roleTranslation) as any;
    // 2. Update/add new key value option
    newObject[key] = value.value;
    setRoleTranslation(newObject);
  }

  // Goal: return { label: "", value: "" }
  // From the Template Role Key name get the Role Translation Value
  const getRoleTranslationValue = (templateRole: string) => {
    // get value from role translation
    if (Object.keys(roleTranslation).length > 0 && (roleTranslation as any)[templateRole]) {
      if ((roleTranslation as any)[templateRole]) {
        return { label: (roleTranslation as any)[templateRole], value: (roleTranslation as any)[templateRole]}
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const sendToOptionsFromPlaybook = () => {
    let options = Object.keys(playbookRoles).map((key: any) => {
      // Filter out roles that are "deals" you cannot send messages to deals
      if (playbookRoles[key] === 'Deal') {
        return null;
      }
      return { label: key, value: key }
    })
    options = options.filter((option) => option !== null)
    return options as any;
  }

  const handleSubmit = () => {
    // return sendTo, template, and role translation
    if (sendTo.length === 0) {
      toast('You must select a valid contact for the Send To field.', { type: 'error' })
      // window.alert("You must select a valid contact for the Send To field.")
    } else if (Object.keys(roleTranslation).length !== Object.keys(templateRoles).length) {
      toast('Please map all template roles to playbook roles', { type: 'warning' });
      // window.alert("Please map all template roles to playbook roles")
    }  else {
      const templateObjWithRoleTranslation = {
        send_to: sendTo,
        template: selectedPlaybookTemplate,
        roles_translation: roleTranslation,
      }
      onSubmit(templateObjWithRoleTranslation);
    }
  }

  const onTemplateFilter = (e: any) => {
    const FolderList = filterTemplateFoldersByString(templates, e.target.value);
    setFolders(FolderList);
  }
  const folderUI = displayFolders()
  return (
    <div className="templateModal">
      <div className="templateContentContainer">
        <div className="templateRow">
          <h2>Templates</h2>
          <button className="close" onClick={onClose} />
        </div>
        {/* <div className="templateRow">
          <button className="defaultButton" onClick={() => dispatch(getAllTemplates())}>Refresh Templates</button>
        </div> */}
        {!selectedPlaybookTemplate && (
          <>
            <h4>Templates</h4>
            <div className="inputField">
              <div className="field">
                <label>Search</label>
                <input className="defaultInput" onChange={(e) => onTemplateFilter(e)} />
              </div>
            </div>
            <div className="availableTemplateContainer white">
              <table>
                <thead>
                  <tr>
                    <td width="20%" colSpan={4} className="folderTitle">Folders</td>
                  </tr>
                </thead>
                <tbody>
                  {folderUI}
                </tbody>
              </table>
            </div>
            <button className="createTemplateButton" onClick={() => handleCreateEmailTemplateClick()}>Create a New Email Template</button>
            <button className="createTemplateButton" onClick={() => handleCreateSMSTemplateClick()}>Create a New SMS Template</button>
          </>
        )}
        {selectedPlaybookTemplate && (
          <>
            <h4>Selected Template</h4>
            <div className="availableTemplateContainer">
              <table className="templateTable">
                <thead>
                  <tr>
                    <td width="33%">Template Name</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><p>{selectedPlaybookTemplate.name}</p><p className="subjectSmall">{selectedPlaybookTemplate.subject}</p></td>
                    <td>
                      <div className="buttonContainer">
                        <button className="deleteButton" onClick={() => reSelectTemplate()}>Re-select</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        {selectedPlaybookTemplate && (
          <div className="templateFieldsContainer">
            {/* if no template roles our roles is just empty */}
            {templateRoles && Object.keys(templateRoles).length > 0 && (
              <div className="field">
                <label>Map Roles</label>
                <div className="rolesContainer">
                  {/* Template Roles: Playbook Roles */}
                  <div className="rowHeader">
                    <h4>Template Key Name</h4>
                    <h4>Template Key Object Type</h4>
                    <h4>Map Playbook Types to Template</h4>
                  </div>
                  {Object.keys(templateRoles).map((key, index) => (
                    <div className="roleRow" key={`${key}-${index}`}>
                      <p>{key}</p>
                      <p>{(templateRoles as any)[key]}</p>
                      <div className="mapper">
                        <Select
                          className="w-full z-20"
                          value={getRoleTranslationValue(key)}
                          options={getTemplateRoleMapPossibilities((templateRoles as any)[key])}
                          onChange={(value: any) => updateRoleTranslations(key, value)}
                          labelledBy="select"
                          isSearchable
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {/* Only show this once roles have been mapped */}
            <div className="field">
              <label>User(s) Receiving Message</label>
              {/* Select Template Role Names */}
              <Select
                className="w-full z-20"
                value={sendTo}
                options={sendToOptionsFromPlaybook()}
                onChange={(value: any) => setSendTo(value)}
                labelledBy="select"
                isSearchable
                isMulti
              />
            </div>
          </div>
        )}
        {selectedPlaybookTemplate && (
          <button className="defaultButton" onClick={handleSubmit}>Add Template Connection</button>
        )}
      </div>
      {/* <ToastContainer newestOnTop /> */}
    </div>
  )
}

export default TemplatePlaybookModal;
