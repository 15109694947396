import React, { useEffect } from "react"

const TextArea = ({ fieldItem, dealErrors, onChange, value }: any) => {
  useEffect(() => {
    if (fieldItem.default) {
      onChange(fieldItem.default)
    }
  }, [])

  return (
    <div
      className={`fieldContainer ${
        dealErrors &&
        (dealErrors[fieldItem.keyName] ||
          dealErrors[`${fieldItem.keyName}_string`])
          ? "error"
          : ""
      }`}
      key={fieldItem.keyName}
    >
      <label>
        <b>
          {fieldItem.label}
          {fieldItem.required && " *"}
        </b>
      </label>
      <textarea
        data-testid={fieldItem.keyName}
        className="defaultInput"
        value={value}
        onChange={e => onChange(e.target.value)}
        defaultValue={fieldItem.default}
      />
    </div>
  )
}

export default TextArea
