import React from 'react';
import cn from 'classnames';

const h2Classes = 'h2Class';

const TableHeader = () => {
  return (
    <div style={{ zIndex: 1, }} className="tableHeaderContainer">
      <div className="tableHeaderContent">
        <h2 className={h2Classes}>Playbook</h2>
        <h2 className={h2Classes}>Templates</h2>
        <h2 className={cn(h2Classes, 'actions')}>
          Actions
        </h2>
      </div>
    </div>
  );
};

export default TableHeader;
