export const selectPlaybookLoading = (state : any) => state.playbooks.loading;
export const selectPlaybookErrors = (state : any) => state.playbooks.hasErrors;

export const selectPaginatedPlaybooks = (state: any) => state.playbooks.paginatedPlaybooks;
export const selectEditingPlaybook = (state : any) => state.playbooks.editingPlaybook;

export const selectSuccessfullyCreatedPlaybook = (state: any) => state.playbooks.successfullyCreatedPlaybook;
export const selectSuccessfullyUpdatedPlaybook = (state: any) => state.playbooks.successfullyUpdatedPlaybook;
export const selectSuccessfullyDeletedPlaybook = (state: any) => state.playbooks.successfullyDeletedPlaybook;

export const selectSuccessfullyCreatedTemplatePlaybookConnection = (state: any) => state.playbooks.successfullyCreatedTemplatePlaybookConnection;
export const selectSuccessfullyUpdatedTemplatePlaybookConnection = (state: any) => state.playbooks.successfullyUpdatedTemplatePlaybookConnection;
export const selectSuccessfullyDeletedTemplatePlaybookConnection = (state: any) => state.playbooks.successfullyDeletedTemplatePlaybookConnection;

export const selectIsPlaybookModalOpen = (state: any) => state.playbooks.openPlaybookModal;
export const selectIsCreatePlaybookModalOpen = (state: any) => state.playbooks.openCreatePlaybookModal;

export const selectCreatedPlaybook = (state: any) => state.playbooks.createdPlaybook;
export const selectRefreshPlaybookList = (state: any) => state.playbooks.refreshPlaybookList;

export const selectSelectedPlaybook = (state: any) => state.playbooks.selectedPlaybook;