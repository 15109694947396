import React from 'react';
import cn from 'classnames';
import './index.scss';

const NumberOptionsField = ({ fieldItem, selected, option, type } : any) => {

  return (
    <div className={cn([{["numericRadioOption"]: type === "numeric", ["textRadioOption"]: type === "text", ["selected"]: selected }])}>
      <label>
        <input data-testid={fieldItem.keyName} type="radio" value={option} name={fieldItem.keyName} checked={selected} />
        {option}
      </label>
    </div>
  )
}

export default NumberOptionsField;
