import * as React from 'react';
import './DefaultButton.scss';
// do something with next()
interface DefaultButton {
  text: any;
  callback: (arg: any) => any;
  disabled?: false;
  className: string;
}
const DefaultButton = ({ text, callback, disabled, className }: DefaultButton) => (
  <button
    className={`${className || ''} default-button`}
    disabled={disabled}
    onClick={(next) => callback(next)}
    type="submit"
  >
    {text}
  </button>
);

export default DefaultButton;
