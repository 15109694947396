import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useAppDispatch } from '../../../store';
import { resetDataStates, setErrors } from '../../../store/DataSender/reducer';
import { selectSuccessfullySentData } from '../../../store/DataSender/selector';
import DataSenderContainerV1 from './DataSenderContainer/DataSenderContainerV1';

const DataSenderFormModal = ({ contacts, onSkip, currentDataSender, onNext }: any) => {
  const dispatch = useAppDispatch();
  const [selectedContact, setSelectedContact] = useState<any>(null);
  const [listOfContacts, setListOfContacts] = useState<any[]>([]);
  const success = useSelector((state) => selectSuccessfullySentData(state)) as boolean;

  useEffect(() => {
    if (success) {
      dispatch(resetDataStates());
      setSelectedContact(null);
      dispatch(setErrors(null));
      // Go to the next datasender or close the modal
      onNext();
    }
  }, [success])

  useEffect(() => {
    let values = [] as any[];
    if (contacts.customers) {
      values = values.concat(contacts.customers.map((c: any) => {
        return {
          label: `${c.firstname} ${c.lastname}`,
          value: c,
        }
      }));
    }

    if (contacts.customers.length === 1 && values.length > 0) {
      setSelectedContact(values[0]);
    }
    setListOfContacts(values);
  }, [contacts])

  const handleContactChange = (contact: any) => {
    setSelectedContact(contact);
  };

  return (
    <>
      <div className="templateModal">
        <div className="templateContentContainer">
          <div className="dsRow">
            <h2>Data Sender: {currentDataSender.data_sender.name || currentDataSender.data_sender.id}</h2>
            <button className="defaultButton" onClick={() => onSkip()}>Skip</button>
          </div>
          <p>Please Select a contact</p>
          <div className="dropdownContainer">
            <label>Select Contact</label>
            <Select
              className="defaultSelect"
              value={selectedContact}
              options={listOfContacts}
              onChange={(value) => handleContactChange(value)}
            />
          </div>
          {selectedContact && currentDataSender && (
            <DataSenderContainerV1 dataSenderRequestObject={currentDataSender.data_sender} selectedVid={selectedContact.value.id || selectedContact.value.vid} />
          )}
        </div>
      </div>
    </>
  )
}

export default DataSenderFormModal;