import React, { useState, forwardRef, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import cuid from 'cuid';
import './TimeField.scss';

type TimeInputProps = {
  id?: string,
  placeholder?: string,
  handleValue?: any,
  value?: string,
  error?: any,
  handleBlur?: any,
}


const TimeField = forwardRef(({
  id,
  placeholder = "Enter your details",
  handleValue = () => {},
  value = undefined,
  error,
}: TimeInputProps, wrapperRef) => {
  const textId = id || useMemo(() => `TimeInput-${cuid()}`, []);
  const [time, setTime] = useState("0:00");
  useEffect(() => {
    // Handle time ui
    if (value) {
      const seconds = Math.max(0, getSecondsFromHHMMSS(`${value}`));
      const t = toHHMMSS(seconds);
      setTime(t);
    }
  }, [value])

  const onChange = (event: any) => {
    setTime(event.target.value);
  };

  const toHHMMSS = (secs: any) => {
    const secNum = parseInt(secs.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== "00" || index > 0)
      .join(":")
      .replace(/^0/, "");
};

  const getSecondsFromHHMMSS = (value: any) => {
    const [str1, str2, str3] = value.split(":");

    const val1 = Number(str1);
    const val2 = Number(str2);
    const val3 = Number(str3);

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    // seconds
      return val1;
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    // minutes * 60 + seconds
      return val1 * 60 + val2;
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    // hours * 60 * 60 + minutes * 60 + seconds
      return val1 * 60 * 60 + val2 * 60 + val3;
    }

    return 0;
  };

  const onBlur = (event: any) => {
    const value = event.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value));
    handleValue(seconds);
    const time = toHHMMSS(seconds);
    setTime(time);
  };

  return (
    <label
      htmlFor={textId}
      ref={wrapperRef as any}
      className={classNames("timeInput", {
        ["error"]: error,
      })}
    >
      <input 
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={time}
      />
    </label>
  );
});

export default TimeField;
