export const FieldTypes = {
  'textarea': 'textarea',
  'textfield': 'textfield',
  'dropdown': 'dropdown',
  'object': 'object',
  'number': 'number',
  'radio': 'radio',
  'radioText': 'radioText',
  'radioNumber': 'radioNumber',
  'date': 'date',
  'header': 'header',
  'paragraph': 'paragraph',
}