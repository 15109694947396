import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Agent from '../../../Interfaces/agent';
import { LoanOfficer } from '../../../Interfaces/contacts';
import Customer from '../../../Interfaces/customer';
import { useAppDispatch } from '../../../store';
import { openAddContactsModal } from '../../../store/AddContacts/reducer';
import { getAgentFromId, getCustomerFromId, getLoanOfficerFromId } from '../../../store/Communications/actions';
import { resetObjectFlags } from '../../../store/Communications/reducer';
import { getAllAdditionalContacts, successfullyFoundAgent, successfullyFoundCustomer, successfullyFoundLoanOfficer } from '../../../store/Communications/selectors';
import './AddNewContactModal.scss';

const AddNewContactModal = () => {
  const dispatch = useAppDispatch();
  const [agentVid, setAgentVid] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [loanOfficerId, setLoanOfficerId] = useState('');
  const additionalContacts = useSelector(state => getAllAdditionalContacts(state));
  const successfullyGotAgent = useSelector(state => successfullyFoundAgent(state));
  const successfullyGotCustomer = useSelector(state => successfullyFoundCustomer(state));
  const successfullyGotLoanOfficer = useSelector(state => successfullyFoundLoanOfficer(state));

  useEffect(() => {
    // reset flags right away
    dispatch(resetObjectFlags());
  }, [])

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetObjectFlags());
    }, 15000)
  }, [successfullyGotAgent, successfullyGotCustomer, successfullyGotLoanOfficer])

  const onClose = () => {
    dispatch(openAddContactsModal(false));
  };

  const addAgent = () => {
    if (agentVid) {
      dispatch(getAgentFromId(agentVid));
    }
  }
  
  const addCustomer = () => {
    if (customerId) {
      dispatch(getCustomerFromId(customerId))
    }
  }

  const addLoanOfficer = () => {
    if (loanOfficerId) {
      dispatch(getLoanOfficerFromId(loanOfficerId));
    }
  }

  return (
    <div className="updateForm">
      <div className="templateRow">
        <h2>Update Connection</h2>
        <button className="close" onClick={onClose} />
      </div>
      <p>*Please note you can only add one of each contact for now.</p>
      <div className="fields">
        <div className="column">
          <label>Add Agent by VID</label>
          <input className="defaultInput" value={agentVid} onChange={(e) => setAgentVid(e.target.value)} />
        </div>
        <button className="defaultButton" onClick={() => addAgent()}>Add</button>
        {successfullyGotAgent && <p className="greenText">Successfully Added!</p>}
      </div>
      <div className="fields">
        <div className="column">
          <label>Add Customer by ID</label>
          <input className="defaultInput" value={customerId} onChange={(e) => setCustomerId(e.target.value)} />
        </div>
        <button className="defaultButton" onClick={() => addCustomer()}>Add</button>
        {successfullyGotCustomer && <p className="greenText">Successfully Added!</p>}
      </div>
      <div className="fields">
        <div className="column">
          <label>Add Loan Officer by ID</label>
          <input className="defaultInput" value={loanOfficerId} onChange={(e) => setLoanOfficerId(e.target.value)} />
        </div>
        <button className="defaultButton" onClick={() => addLoanOfficer()}>Add</button>
        {successfullyGotLoanOfficer && <p className="greenText">Successfully Added!</p>}
      </div>
      <div className="currentContacts">
        <h2>Current Contacts:</h2>
        <p className="text-strong"><strong>Agents:</strong></p>
        {additionalContacts.agents && additionalContacts.agents.map((a: Agent) => (
          <p>{a.firstname} {a.lastname}</p>
        ))}
        <p className="text-strong"><strong>Loan Officers:</strong></p>
        {additionalContacts.loanOfficers && additionalContacts.loanOfficers.map((lo: LoanOfficer) => (
          <p>{lo.firstname} {lo.lastname}</p>
        ))}
        <p className="text-strong"><strong>Customers:</strong></p>
        {additionalContacts.customers && additionalContacts.customers.map((cu: Customer) => (
          <p>{cu.firstname} {cu.lastname}</p>
        ))}
      </div>
    </div>
  )
}

export default AddNewContactModal;
