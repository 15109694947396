import React, { useEffect } from 'react';

const NumberInput = ({
  field,
  value,
  onChange,
  type = 'number',
  disablePaste = false,
  disableFloat = false,
  disabled = false,
} : any) => {
  const isInt = (val: any) => {
    const reg = /^[0-9]+$/gm;
    return reg.test(val);
  };

  useEffect(() => {
    if (field.default) {
      onChange(field.default)
    }
  }, [field])

  const preventNonNumeric = (e: any) => {
    // allow decimals if float enabled
    if (!disableFloat && e.key === '.') {
      return;
    }

    if (e.key === 'Backspace') {
      return;
    }
    // if float is disabled prevent any non numerical key

    if (disableFloat && !isInt(e.key)) {
      e.preventDefault();
    }
    if (Number.isNaN(Number(e.key)) && ![8, 46].includes(e.keyCode)) {
      e.preventDefault();
    }
  };

  const onPaste = (e: any) => {
    if (disablePaste) {
      // if paste is disabled prevent value
      e.preventDefault();
    } else {
      const copiedText = e.clipboardData.getData('text');
      // if is integer its fine
      if (isInt(copiedText)) {
        return;
      }
      // if float is disabled prevent paste
      if (disableFloat) {
        e.preventDefault();
        return;
      }
      const numericalVal = parseFloat(copiedText);
      if (!Number.isNaN(numericalVal) && !disableFloat) {
        return;
      }
      e.preventDefault();
    }
  };

  return (
    <div
      className={`fieldContainer radioContainer`}
    >
       <label>
        {field.label}
        {field.required && " *"}
      </label>

      <input
        id={field.keyName}
        type={type}
        disabled={disabled}
        name={field.keyName}
        onPaste={(e: any) => onPaste(e)}
        placeholder={field.placeholder}
        className="defaultInput"
        onKeyDown={event => preventNonNumeric(event)}
        onChange={event => onChange(event.target.value)}
        onBlur={event => onChange(event.target.value)}
        value={value}
      />
    </div>
  );
};

export default NumberInput;
