import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch } from '../../../store';
import { patchAgent, patchCustomer, patchDeal, patchLoanOfficer } from '../../../store/EditingFields/actions';
import { resetEditFieldState, setOpenEditFieldsModal } from '../../../store/EditingFields/reducer';
import { selectEditingErrors, selectEditingFieldObject, selectEditingType, selectSuccessfullyUpdatedFields } from '../../../store/EditingFields/selectors';
import { isValidURL } from '../../../utils/helpers';
import './EditFieldsModal.scss';

export const FieldObjectTypes = {
  'Agent': 'Agent',
  'Customer': 'Customer',
  'Loan Officer': 'Loan Officer',
  'Deal': 'Deal',
  'Cash Buyer': 'Cash Buyer',
}

export const FieldTypes = {
  'textarea': 'textarea',
  'textfield': 'textfield',
  'dropdown': 'dropdown',
  'object': 'object',
  'number': 'number',
  'radio': 'radio',
  'date': 'date',
  'address': 'address',
  'timesecondsfield': 'timesecondsfield',
}

const AgentFields = [
  {
    label: 'First name',
    keyName: 'firstname',
    type: FieldTypes.textfield,
  },
  {
    label: 'Last name',
    keyName: 'lastname',
    type: FieldTypes.textfield,
  },
  {
    label: 'Brokerage name',
    keyName: 'brokerage_name',
    type: FieldTypes.textfield,
  },
  {
    label: 'Email',
    keyName: 'email',
    type: FieldTypes.textfield,
  },
  {
    label: 'Profile Links',
    keyName: 'profile_links',
    type: FieldTypes.object,
  },
]

const CustomerFields = [
  {
    label: 'First name',
    keyName: 'firstname',
    type: FieldTypes.textfield,
  },
  {
    label: 'Last name',
    keyName: 'lastname',
    type: FieldTypes.textfield,
  },
  {
    label: 'Acquisition Channel',
    keyName: 'acquisition_channel',
    type: FieldTypes.dropdown,
    options: [
      'PPC',
      'SEO - REW',
      'SEO - Clever',
      'SEO - Inside1031',
      'Mortgage Partners',
      'MRC',
      'Direct Mail',
      'Other',
    ]
  },
  {
    label: 'Email',
    keyName: 'email',
    type: FieldTypes.textfield,
  }
]

const LoanOfficerFields = [
  {
    label: 'First name',
    keyName: 'firstname',
    type: FieldTypes.textfield,
  },
  {
    label: 'Last name',
    keyName: 'lastname',
    type: FieldTypes.textfield,
  },
  {
    label: 'Email',
    keyName: 'email',
    type: FieldTypes.textfield,
  },
  {
    label: 'Company Name',
    keyName: 'company_name',
    type: FieldTypes.textfield,
  },
]

const DealFields = [
  {
    label: 'Home Value',
    keyName: 'home_value',
    type: FieldTypes.number,
  },
  {
    label: 'Listing Address',
    keyName: 'listing_address',
    type: FieldTypes.textfield,
  },
  {
    label: 'Buying Market',
    keyName: 'buying_market',
    type: FieldTypes.textfield,
  },
  {
    label: 'Buying Price High',
    keyName: 'buying_price_high',
    type: FieldTypes.number,
  },
  {
    label: 'Buying Price Low',
    keyName: 'buying_price_low',
    type: FieldTypes.number,
  },
  {
    label: 'Property Type',
    keyName: 'property_type',
    type: FieldTypes.dropdown,
    options: [
      'Single Family',
      'Condo',
      'Multifamily',
      'Commercial',
      'Land',
      'Townhouse',
      'Other',
      'Cooperative',
      'Manufactured or Mobile Home',
      'Mixed Use Residential',
      'Farm',
      'Home and Business Combined',
    ]
  },
]

const FieldsFromType = (type: string) => {
  switch (type) {
    case FieldObjectTypes.Agent:
      return AgentFields;
    case FieldObjectTypes.Customer:
      return CustomerFields;
    case FieldObjectTypes['Loan Officer']:
      return LoanOfficerFields;
    default:
      return DealFields;
  }
}

const EditFieldsModal = () => {
  const dispatch = useAppDispatch();
  const editingType = useSelector((state) => selectEditingType(state));
  const editingObject = useSelector((state) => selectEditingFieldObject(state));
  const errors = useSelector((state) => selectEditingErrors(state));
  const successfullyUpdatedFields = useSelector((state) => selectSuccessfullyUpdatedFields(state))
  const [editingObjectFields, setEditingObjectFields] = useState({}) as any;
  const [newProfileLink, setNewProfileLink] = useState(false);
  const [newProfileLinkValue, setNewProfileLinkValue] = useState('');
  const [newProfileNameValue, setNewProfileNameValue] = useState('');
  const [profileLinks, setProfileLinks] = useState<any>([])
  const [originalLinks, setOriginalLinks] = useState(null) as any
  const [readyToRender, setReadyToRender] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (errors && !showError) {
      const errorKeys = Object.keys(errors);
      let errorMessage = '';
      errorKeys.map((key) => {
        errorMessage += `Error (${key}): ${errors[key][0]}\n`
      })
      setShowError(true);
      toast(errorMessage, { type: 'error' })
      // window.alert(errorMessage);
    }
  }, [errors])

  useEffect(() => {
    if (editingObject) {
      setEditingObjectFields(editingObject);
      
      if (editingType === FieldObjectTypes.Agent) {
        if (!originalLinks) {
          setOriginalLinks(editingObject.profile_links);
        }
        setProfileLinks(editingObject.profile_links);
      }
      setReadyToRender(true);
    }
  }, [editingObject]);

  useEffect(() => {
    if (successfullyUpdatedFields) {
      dispatch(setOpenEditFieldsModal(false))
      dispatch(resetEditFieldState());
    }
  }, [successfullyUpdatedFields])

  const updateFieldWithValue = (key: string, value: string | number) => {
    const updatedField = { ...editingObjectFields }
    updatedField[key] = value;
    setEditingObjectFields(updatedField)
  }

  const handleOptionChange = (key: string, value: any) => {
    const updatedField = { ...editingObjectFields }
    updatedField[key] = value.value;
    setEditingObjectFields(updatedField)
  }

  const clearNewProfileFields = () => {
    setNewProfileLinkValue('');
    setNewProfileNameValue('');
  }

  const removeProfileLink = (index: any) => {
    const newProfileLinks = [...profileLinks] as any[];
    newProfileLinks.splice(index, 1);
    setProfileLinks(newProfileLinks);
  }

  const addNewProfileLink = () => {
    // handle save here.
    if (newProfileNameValue !== '' && newProfileLinkValue !== '') {
      // check link validity
      if (!isValidURL(newProfileLinkValue)) {
        toast('Invalid profile url. Please enter a valid url', { type: 'warning' })
        // window.alert("Invalid profile url. Please enter a valid url")
        return;
      }
      const newProfileLinks = [...profileLinks];
      newProfileLinks.push({ link: newProfileLinkValue, name: newProfileNameValue });
      setProfileLinks(newProfileLinks);
      setNewProfileLink(false);
      clearNewProfileFields()
    }
  };

  const onProfileLinkChange = (e: any, name: any) => {
    const newLinks = profileLinks.map((value: any) => value.name === name ? { link: e.target.value, name: name } : value);
    setProfileLinks(newLinks);
  }

  const onProfileNameChange = (e: any, link: any) => {
    const newLinks = profileLinks.map((value: any) => value.link === link ? { link: link, name: e.target.value } : value);
    setProfileLinks(newLinks);
  }

  const save = () => {
    const badLinks = profileLinks.filter((value: any) => {
      return !isValidURL(value.link)
    })
    if (badLinks.length) {
      toast('Invalid link. Please enter a valid link', { type: 'warning' })
      // window.alert("Invalid link. Please enter a valid link")
      // reset profile links back to original links
      setProfileLinks(originalLinks);
      return
    }
  }

  const getObjectFields =  () => {
    // links = [ { link: '', name: '' }]
    if (profileLinks) {
      const Inputs = profileLinks.map((value: any, index: number) => 
        (
          <div key={`profile-link-${index}`} className="flex flex-row items-center mb-2 inputSetContainer">
            <input type="text" placeholder="Enter Profile Link" className="defaultInput w-1/2 mr-2" id={`link-${index}`} value={value.link} onChange={(e) => onProfileLinkChange(e, value.name)} onBlur={() => save()} />
            <input type="text" placeholder="Enter Profile Name" className="defaultInput w-1/2" id={value.name} value={value.name} onChange={(e) => onProfileNameChange(e, value.link)} onBlur={() => save()} />
            <button className="deleteButton ml-3 minHeightSet" onClick={() => removeProfileLink(index)}>Remove</button>
          </div>
        )
      );
      return Inputs;
    } else {
      return <></>
    }
  };

  const getJSXFieldElement = (fieldItem: any) => {
    if (Object.keys(editingObjectFields).length === 0) {
      return;
    }
    switch (fieldItem.type) {
      case FieldTypes.number:
      case FieldTypes.textfield:
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            <input className="defaultInput" value={editingObjectFields[fieldItem.keyName]} type={fieldItem.type} onChange={(e) => updateFieldWithValue(fieldItem.keyName, e.target.value)}/>
          </div>
        )
      case FieldTypes.textarea:
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            <textarea className="defaultInput" value={editingObjectFields[fieldItem.keyName]} onChange={(e) => updateFieldWithValue(fieldItem.keyName, e.target.value)} />
          </div>
        )
      case FieldTypes.dropdown:
        const options = fieldItem.options.map((opt: string) => {
          return { label: opt, value: opt }
        });

        const currentOption = options.filter((item: any) => item.label === editingObjectFields[fieldItem.keyName])
        let currentValue = null;
        if (currentOption.length > 0) {
          currentValue = currentOption[0];
        }
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            <Select
              className="defaultSelect"
              value={currentValue}
              options={options}
              onChange={(val) => handleOptionChange(fieldItem.keyName, val)}
            />
          </div>
        )
      case FieldTypes.object:
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            {getObjectFields()}
            {newProfileLink && (
              <>
                <div className="flex flex-row mb-2 linkConnection">
                  <input placeholder="Enter Profile Link" className="w-1/2 mr-2 defaultInput" id={''} value={newProfileLinkValue} onChange={(e) => setNewProfileLinkValue(e.target.value)} />
                  <input placeholder="Enter Profile Name" className="w-1/2 mr-2 defaultInput" id={''} value={newProfileNameValue} onChange={(e) => setNewProfileNameValue(e.target.value)} />
                  <button className="defaultButton" onClick={addNewProfileLink}>Save</button>
                </div>
              </>
            )}
            <button className="defaultButton" onClick={() => setNewProfileLink(true)}><span />Add Profile Link</button>
          </div>
        )
          

      default:
        return <></>
    }
  }

  const getAllFields = () => {
    const fields = FieldsFromType(editingType);
    // map fields to jsx items
    const fieldsJSX = fields.map((field) => {
      return getJSXFieldElement(field);
    })
    return fieldsJSX;
  }
  
  const updateFields = () => {
    setShowError(false);
    switch (editingType) {
      case FieldObjectTypes.Agent:
        const patchableAgentFields = AgentFields.map((field) => field.keyName);
        const agentPatchData = {} as any;
        patchableAgentFields.forEach((field) => {
          agentPatchData[field] = editingObjectFields[field];
        });

        const agentData = {
          id: editingObject.vid,
          data: {
            ...agentPatchData,
            profile_links: profileLinks,
          }
        } as any;

        dispatch(patchAgent(agentData));
        return;
      case FieldObjectTypes.Customer:
        // handle customer
        const patchableCustomerFields = CustomerFields.map((field) => field.keyName);
        const customerPatchData = {} as any;
        patchableCustomerFields.forEach((field) => {
          const fieldObject = CustomerFields.filter((f) => f.keyName === field)[0] as any
          if (fieldObject.validation) {
            const isValid = fieldObject.validation.test(editingObjectFields[field]);
            if (!isValid) {
              toast(`${field}: Invalid format please try again and enter a valid format.`, { type: 'error' })
              return;
            }
            customerPatchData[field] = editingObjectFields[field];
          } else {
            customerPatchData[field] = editingObjectFields[field];
          }
          
        })
        const customerData = {
          id: editingObject.id,
          data: {
            ...customerPatchData,
          }
        }
        dispatch(patchCustomer(customerData));
        return;

      case FieldObjectTypes.Deal:

        const patchableDealFields = DealFields.map((field) => field.keyName);
        const dealPatchData = {} as any;
        patchableDealFields.forEach((field) => {
          dealPatchData[field] = editingObjectFields[field];
        })
        const dealData = {
          id: editingObject.id,
          data: {
            ...dealPatchData,
          }
        }
        dispatch(patchDeal(dealData));
        return;
      case FieldObjectTypes['Loan Officer']:
        const patchableLOFields = LoanOfficerFields.map((field) => field.keyName);
        const loPatchData = {} as any;
        patchableLOFields.forEach((field) => {
          loPatchData[field] = editingObjectFields[field];
        })
        const loData = {
          id: editingObject.vid,
          data: {
            ...loPatchData,
          }
        }
        dispatch(patchLoanOfficer(loData));
        return;
      default:
        return;
    }
  }

  const onClose = () => {
    dispatch(setOpenEditFieldsModal(false))
    dispatch(resetEditFieldState());
  }

  return (
    <div className="editFieldsModal">
      <div className="editFieldsModalContainer">
        <div className="templateRow">
        <h2>Edit {editingType} Fields</h2>
        <button className="close" onClick={onClose} />
        </div>
        {readyToRender && getAllFields()}
        {/* Display all the fields associated with the current selected type */}
        <button className="defaultButton" onClick={() => updateFields()}>Update</button>
      </div>
      {/* <ToastContainer newestOnTop /> */}
    </div>
  )
}

export default EditFieldsModal;
