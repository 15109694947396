import React, { useState } from 'react';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ObjectValues = [
  { label: "Agent", value: "Agent" },
  { label: "Loan Officer", value: "Loan Officer" },
  { label: "Customer", value: "Customer" },
  { label: "User", value: "User" },
  { label: "Deal", value: "Deal" },
  { label: "Cash Buyer", value: "Cash Buyer"}
];

const KeyMapModal = ({ onClose, onSubmit }: any) => {
  const [keyName, setKeyName] = useState('');
  const [selectedValue, setSelectedValue] = useState(null)
  const handleKeyNameChange = (e: any) => {
    const value = e.target.value.replace(/\s/g, '_')
      .replace(/-/g, '')
      .toLowerCase();
    setKeyName(value);
  }

  const submit = () => {
    if (keyName !== '' && selectedValue !== null) {
      const newKeyMap = { [keyName]: (selectedValue as any).value }
      onSubmit(newKeyMap);
    } else {
      toast('Please create a key name and select a value');
      // window.alert('Please create a key name and select a value')
    }
  }

  return (
    <div className="editModal">
      <div className="editModalContainer minHeight">
        <div className="templateRow">
          <h2>Create Key Map Item</h2>
          <button className="close" onClick={onClose} />
        </div>
        <p>Here you create the mapping between placeholder values and the Objects they represent. i.e agent_1 =&gt; map to an Agent object</p>
        <div className="templateFieldsContainer">
          <div className="field">
            <label>Key Name* (needs to be lowercased and underscores for spaces)</label>
            <input value={keyName} className="defaultInput" onChange={handleKeyNameChange} />
          </div>

          <div className="field">
            <label>Key Value</label>
            <Select
              className="w-full z-20"
              value={selectedValue}
              options={ObjectValues}
              onChange={(value: any) => setSelectedValue(value)}
              labelledBy="select"
              isSearchable
            />
          </div>
        </div>
        <button className="defaultButton" onClick={submit}>Create Key Map Item</button>
      </div>
      {/* <ToastContainer newestOnTop /> */}
    </div>
  )
}

export default KeyMapModal;
