import React, { useState } from 'react';
import Select from 'react-select';
import { FieldTypes } from '../EditFieldsModal/EditFieldsModal';
import { UpdateStageOptions } from './Fields';

const DefaultFields = [
  {
    label: 'Update Stage',
    keyName: 'Update Stage',
    type: FieldTypes.dropdown,
    options: UpdateStageOptions,
  },
  {
    label: 'Notes',
    keyName: 'Notes',
    type: FieldTypes.textarea,
  },
  {
    label: 'Internal Notes',
    keyName: 'Internal Notes',
    type: FieldTypes.textarea,
  },
]

const useDefaultFormHook = (fields = DefaultFields) => {
  const [data, setData] = useState({}) as any;
  
  const handleOptionChange = (key: string, value: any) => {
    const updatedField = { ...data }
    updatedField[key] = value.value;
    setData(updatedField)
  }

  const updateFieldWithValue = (key: string, value: string | number) => {
    const updatedField = { ...data }
    updatedField[key] = value;
    setData(updatedField)
  }

  const getJSXFieldElement = (fieldItem: any) => {
    // Since all default fields are textareas we do not need the switch logic
    switch (fieldItem.type) {
      case FieldTypes.number:
      case FieldTypes.textfield:
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            <input className="defaultInput" value={data[fieldItem.keyName]} type={fieldItem.type} onChange={(e) => updateFieldWithValue(fieldItem.keyName, e.target.value)}/>
          </div>
        );
      case FieldTypes.textarea:
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            <textarea className="defaultInput" value={data[fieldItem.keyName]} onChange={(e) => updateFieldWithValue(fieldItem.keyName, e.target.value)} />
          </div>
        )
      default:
        const currentOption = fieldItem.options.filter((item: any) => item.value === data[fieldItem.keyName])
        let currentValue = null;
        if (currentOption.length > 0) {
          currentValue = currentOption[0];
        }
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            <Select
              className="defaultSelect"
              value={currentValue}
              options={fieldItem.options}
              onChange={(val) => handleOptionChange(fieldItem.keyName, val)}
            />
          </div>
        )
    }
  }

  const getAllFields = () => {
    const fieldsJSX = fields.map((field) => {
      return getJSXFieldElement(field);
    })
    return fieldsJSX;
  }


  return [getAllFields(), data]
}

export default useDefaultFormHook