import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store';
import { deletePlaybook, getAllPlaybooks, getIndividualPlaybook, Playbook, PlaybookPaginatedList } from '../../../store/Playbooks/actions';
import { selectPaginatedPlaybooks, selectPlaybookLoading, selectRefreshPlaybookList, selectSuccessfullyDeletedPlaybook, selectSuccessfullyUpdatedTemplatePlaybookConnection } from '../../../store/Playbooks/selectors';
import TablePagination from '../../Pagination/TablePagination';
import TableHeader from '../../Table/TableHeader';
import './PlaybooksModal.scss';
import { clearRefreshPlaybookList, openCreatePlaybookModal, resetPlaybookStates, selectPlaybook } from '../../../store/Playbooks/reducer';
import { resetStateFlags } from '../../../store/Communications/reducer';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

const PlaybooksModal = ({ onClose }: any) => {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const isLoading = useSelector((state) => selectPlaybookLoading(state));
  const playbookList = useSelector((state) => selectPaginatedPlaybooks(state)) as PlaybookPaginatedList;
  const successfullyDeleted = useSelector((state) => selectSuccessfullyDeletedPlaybook(state));
  const refreshPlaybookList = useSelector((state) => selectRefreshPlaybookList(state));

  useEffect(() => {
    if (successfullyDeleted) {
      // Re-fetch playbooks
      dispatch(getAllPlaybooks(currentPage));
      // clear states
      dispatch(resetPlaybookStates());
    }
  }, [successfullyDeleted])

  useEffect(() => {
    if (refreshPlaybookList) {
      // Re-fetch playbooks
      dispatch(getAllPlaybooks(currentPage));
      // clear state
      dispatch(clearRefreshPlaybookList());
    }
  }, [refreshPlaybookList])

  useEffect(() => {
    dispatch(getAllPlaybooks(currentPage));
  }, [currentPage])

  const handleSelect = (playbook: Playbook) => {
    dispatch(resetStateFlags());
    dispatch(selectPlaybook(playbook));
    onClose();
  }

  const handleDelete = (id: number) => {
    confirmAlert({
      message: 'Are you sure you want to delete this playbook?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(deletePlaybook(id))
        },
        {
          label: 'Cancel',
          onClick: () => null
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    })
  };

  const handleEdit = (id: number) => {
    // Get Individual item
    dispatch(getIndividualPlaybook(id));
  };

  const createNewPlaybook = () => {
    dispatch(openCreatePlaybookModal());
  }

  return (
    <div className="playbooksModal">
      <div className="playbooksContentContainer">
        <div className="playbooksRow">
          <h2>Playbooks</h2>
          <button className="close" onClick={onClose} />
        </div>
        {!isLoading && playbookList && Object.keys(playbookList).length > 0 && (
          <div className="availablePlaybooksContainer">
            <TableHeader />
            <ul className="noSpacing" id="resultsList">
            {playbookList.results?.length > 0 &&
              playbookList.results.map((result, i) => (
                <li key={`playbook-item-${i}`} className={
                  classNames('listClasses', {
                    'offGray': i % 2 !== 0
                  })
                }>
                  <div className="defaultFlexWithPadding">
                    <h3 className="nameHeader">{result.name}</h3>
                    <p className="smallText">{result.description}</p>
                  </div>
                  <div className="defaultFlexWithPadding">
                    {result.templates.map((template) => (
                      <div className="sendToItem" key={`${template.id}-${result.id}`}>
                        <p className="blueText"><strong>{(template.template as any).name}</strong></p>
                        <p><strong>Sending to:</strong> {(template as any).send_to.concat(',').splice((template as any).send_to.length - 1 , 1)}</p>
                      </div>
                    ))}
                  </div>
                  <div className="defaultFlexWithPadding keyMapRow">
                    <button className="editButton" onClick={() => handleEdit(result.id)}>Edit</button>
                    <button className="deleteButton" onClick={() => handleDelete(result.id)}>Delete</button>
                    <button className="selectButton" onClick={() => handleSelect(result)}>Select</button>
                  </div>
                </li>
              ))}
              {playbookList.results?.length === 0 && (
                <p>There are currently no playbooks. Create a new one below</p>
              )}
            </ul>

            <TablePagination count={playbookList.count} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </div>
        )}
        <button className="createTemplateButton" onClick={createNewPlaybook}>Create New Playbook</button>
      </div>
    </div>
  )
}

export default PlaybooksModal;
