import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch } from '../../../store';
import { createDataSenderPlaybookConnection, createPlaybook, CreatePlaybookDataSenderConnectionProps, CreatePlaybookProps, CreatePlaybookTemplateConnectionProps, createTemplatePlaybookConnection, deleteDataSenderPlaybookConnection, deleteTemplatePlaybookConnection, getAllPlaybooks, getIndividualPlaybook, Playbook, PlaybookDataSenderDeleteProps, PlaybookTemplateConnectionProps, PlaybookTemplateDeleteProps, updateIndividualPlaybook, UpdatePlaybookProps, updateTemplatePlaybookConnection } from '../../../store/Playbooks/actions';
import { resetCreatedPlaybook, resetPlaybookStates, resetTemplatePlaybookConnectionStates, resetUpdatedPlaybook } from '../../../store/Playbooks/reducer';
import { selectCreatedPlaybook, selectEditingPlaybook, selectSuccessfullyCreatedPlaybook, selectSuccessfullyDeletedTemplatePlaybookConnection, selectSuccessfullyUpdatedPlaybook } from '../../../store/Playbooks/selectors';
import { clearSelectedPlaybookTemplate, togglePlaybookTemplateList } from '../../../store/Templates/reducer';
import { selectOpenPlaybookTemplateList } from '../../../store/Templates/selectors';
import DataSenderModal from '../DataSenderModal/DataSenderModal';
import KeyMapModal from '../KeyMapModal/KeyMapModal';
import TemplatePlaybookModal from '../TemplatePlaybookModal/TemplatePlaybookModal';

const CreatePlaybookModal = ( { isEditing, onClose }: any ) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  // const [templates, setTemplates] = useState(null);
  const [roles, setRoles] = useState({});
  const [openKeyMapModal, setOpenKeyMapModal] = useState(false);
  const [currentTemplates, setCurrentTemplates] = useState([]) as any[];
  const [currentDataSenders, setCurrentDataSenders] = useState([]) as any[];
  const [dontLeave, setDontLeave] = useState(false);
  const [editingPlaybookTemplateConnection, setEditingPlaybookTemplateConnection] = useState(false);
  const [editingTemplateIndex, setEditingTemplateIndex] = useState(null) as any;
  const [openDataSenderModal, setOpenDataSenderModal] = useState(false);

  const playbookTemplateListOpen = useSelector((state) => selectOpenPlaybookTemplateList(state));
  const createdPlaybook = useSelector((state) => selectCreatedPlaybook(state)) as Playbook | null;
  const successfullyCreatedPlaybook = useSelector((state) => selectSuccessfullyCreatedPlaybook(state));
  const editingPlaybook = useSelector((state) => selectEditingPlaybook(state)) as any;

  const successfullyDeletedPlaybookTemplate = useSelector((state) => selectSuccessfullyDeletedTemplatePlaybookConnection(state));
  const successfullyUpdatedPlaybook = useSelector((state) => selectSuccessfullyUpdatedPlaybook(state));

  useEffect(() => {
    if (editingPlaybook && successfullyUpdatedPlaybook) {
      // Update playbook templates...
      if (typeof window !== 'undefined') {
        if (!(window as any).logs) {
          (window as any).logs = {
            location: 'Successfully Updated Playbook'
          };
        } else {
          (window as any).logs.location = 'Successfully Updated Playbook';
        }
      }
      currentDataSenders.forEach((dataSender: any) => {
        const playbookDataSenderConnectionProps = {
          playbook_id: editingPlaybook.id,
          data_sender_id: dataSender.id,
          roles_translation: {},
        } as CreatePlaybookDataSenderConnectionProps
        dispatch(createDataSenderPlaybookConnection(playbookDataSenderConnectionProps));
      })

      toast('Successfully Updated Playbook!', { type: 'success' })
      currentTemplates.forEach((tempObject: any) => {
        const templateExists = tempObject.id;
        const templateId = tempObject.id;
        if (typeof window !== 'undefined') {
          if (!(window as any).logs) {
            (window as any).logs = {
              tempObject: tempObject
            };
          } else {
            (window as any).logs.tempObject = tempObject;
          }
        }
        if (templateExists) {
          const playbookTemplateConnectionProps = {
            playbook_template_id: templateId,
            roles_translation: tempObject.roles_translation,
            send_to: tempObject.send_to.map((item: any) => {
              if (typeof item === 'object') {
                return item.label;
              } else {
                return item;
              }
            }),
          } as PlaybookTemplateConnectionProps
          // update template connection
          dispatch(resetPlaybookStates());
          dispatch(updateTemplatePlaybookConnection(playbookTemplateConnectionProps));
        } else {
          const playbookTemplateConnectionProps = {
            template_id: tempObject.template.id,
            playbook_id: editingPlaybook.id,
            roles_translation: tempObject.roles_translation,
            send_to: tempObject.send_to.map((item: any) => {
              if (typeof item === 'object') {
                return item.label;
              } else {
                return item;
              }
            })
          } as CreatePlaybookTemplateConnectionProps;
          // create a new one
          dispatch(resetPlaybookStates());
          dispatch(createTemplatePlaybookConnection(playbookTemplateConnectionProps));
        }
      })
      if (!dontLeave) {
        setDontLeave(false);
        dispatch(resetUpdatedPlaybook());
        dispatch(resetPlaybookStates());
        dispatch(resetTemplatePlaybookConnectionStates());
        // dispatch(getAllPlaybooks(1));
        onClose();
      }
      
    }
  }, [successfullyUpdatedPlaybook])

  useEffect(() => {
    if (successfullyDeletedPlaybookTemplate) {
      // get updated playbook again and clear delete state
      dispatch(getIndividualPlaybook(editingPlaybook.id));
      dispatch(resetTemplatePlaybookConnectionStates());
    }
  }, [successfullyDeletedPlaybookTemplate])

  useEffect(() => {
    if (editingPlaybook) {
      const editingRoles = editingPlaybook.roles;
      setRoles(editingRoles);
      const editingTemplates = editingPlaybook.templates;
      setCurrentTemplates(editingTemplates);
      setName(editingPlaybook.name);
      setDescription(editingPlaybook.description);
      const editingDataSenders = editingPlaybook.data_senders.map((sender: any) => sender.data_sender);
      setCurrentDataSenders(editingDataSenders);
    }
  }, [editingPlaybook])

  useEffect(() => {
    // Playbook has been created now connect the templates
    if (createdPlaybook && successfullyCreatedPlaybook) {
      // array of these
      /*
        {
          send_to: [strings],
          template: Template,
          roles_translation: object
        }
      */
      currentTemplates.forEach((tempObject: any) => {
        const playbookTemplateConnectionProps = {
          playbook_id: createdPlaybook.id,
          template_id: tempObject.template.id,
          roles_translation: tempObject.roles_translation,
          send_to: tempObject.send_to.map((item: any) => item.label),
        } as CreatePlaybookTemplateConnectionProps
        dispatch(createTemplatePlaybookConnection(playbookTemplateConnectionProps));
      })
      currentDataSenders.forEach((dataSender: any) => {
        const playbookDataSenderConnectionProps = {
          playbook_id: createdPlaybook.id,
          data_sender_id: dataSender.id,
          roles_translation: {},
        } as CreatePlaybookDataSenderConnectionProps
        dispatch(createDataSenderPlaybookConnection(playbookDataSenderConnectionProps));
      })
      dispatch(resetCreatedPlaybook());
    }
  }, [createdPlaybook])

  const updateKeyMap = (keyMapItem: object) => {
    const newRoles = {
      ...roles,
      ...keyMapItem,
    }
  
    setRoles(newRoles);
    setOpenKeyMapModal(false);
  }

  const openTemplates = () => {
    if (Object.keys(roles).length === 0) {
      toast('You should set some roles before you select a template. You can do it without but there might be some issues.', { type: 'warning' });
      dispatch(togglePlaybookTemplateList());
    } else {
      dispatch(togglePlaybookTemplateList());
    }
  }

  const addTemplate = (templateObjWithRoleTranslation: any) => {
    /*
      {
        send_to: [strings],
        template: Template,
        roles_translation: object
      }
    */
   // Remove old template
   // Create new one...
    if (editingPlaybookTemplateConnection && editingTemplateIndex !== null) {
      if (isEditing) {

      }
      setEditingPlaybookTemplateConnection(false);
      const templates = [...currentTemplates];
      const removedTemplate = currentTemplates[editingTemplateIndex];
      templates.splice(editingTemplateIndex, 1, templateObjWithRoleTranslation);
      setCurrentTemplates(templates);
      dispatch(togglePlaybookTemplateList());
      dispatch(clearSelectedPlaybookTemplate());
      if (isEditing) {
        const playbookTemplateConnectionProps = {
          playbook_template_id: removedTemplate.id,
          roles_translation: templateObjWithRoleTranslation.roles_translation,
          send_to: templateObjWithRoleTranslation.send_to.map((item: any) => {
            if (typeof item === 'object') {
              return item.label;
            } else {
              return item;
            }
          }),
        } as PlaybookTemplateConnectionProps
        // update template connection
        dispatch(updateTemplatePlaybookConnection(playbookTemplateConnectionProps));
      }

      setEditingTemplateIndex(null);
    } else {
      const templates = [...currentTemplates];
      templates.push(templateObjWithRoleTranslation);
      setCurrentTemplates(templates);
      dispatch(togglePlaybookTemplateList());
      dispatch(clearSelectedPlaybookTemplate());
    }
    
  }

  const createNewPlaybook = () => {
    if (currentTemplates.length !== 0) {
      const createPlaybookProps = {
        description: description,
        name: name,
        roles: roles,
      } as CreatePlaybookProps
  
      dispatch(createPlaybook(createPlaybookProps))
    } else {
      toast('You must select at least one template in order to create a playbook', { type: 'warning' })
    }
  }
  
  const removeItem = (index: number) => {
    const templates = [...currentTemplates];
    const removedTemplate = currentTemplates[index];
    templates.splice(index, 1);

    setCurrentTemplates(templates);
    if (isEditing) {
      const deletionProps = {
        playbook_template_id: removedTemplate.id,
      } as PlaybookTemplateDeleteProps;
      dispatch(deleteTemplatePlaybookConnection(deletionProps));
    }
  }

  const removeDataSender = (index: number) => {
    const dataSenders = [...currentDataSenders];
    const removedDataSender = dataSenders[index];
    dataSenders.splice(index, 1);
    setCurrentDataSenders(dataSenders);

    if (isEditing) {
      const removeDataSender = editingPlaybook.data_senders.filter((sender: any) => sender.data_sender.id === removedDataSender.id);
      if (removeDataSender.length > 0) {
        const deletionProps = {
          playbook_data_sender_id: removeDataSender[0].id,
        } as PlaybookDataSenderDeleteProps;
        dispatch(deleteDataSenderPlaybookConnection(deletionProps));
      } else {
        const deletionProps = {
          playbook_data_sender_id: removedDataSender.id,
        } as PlaybookDataSenderDeleteProps;
        dispatch(deleteDataSenderPlaybookConnection(deletionProps));
      }
      
    }
  }

  const removeRole = (key: string) => {
    const newRoles = Object.assign({}, roles);
    const templatesThatContainRemovedRole = [] as string[];
    //roleTranslations:  [templateKey]: [playbookKey]
    currentTemplates.forEach((template: any) => {
      Object.keys(template.roles_translation).forEach((roleTranslationKey: any) => {
        // get the template[key] 

        const keyComparison = template.roles_translation[roleTranslationKey];
        if (keyComparison === key) {
          templatesThatContainRemovedRole.push(template.template.name);
        }
      })
    })
    if (templatesThatContainRemovedRole.length > 0) {
      toast(`The Following templates contain role translations that match the current role (${key}) you are removing: ${templatesThatContainRemovedRole.join(', ')}. If you want to remove the role thats ok but you must remove the template.`, { type: 'warning' });
    }

    if (Object.keys(newRoles).length > 0) {
      delete (newRoles as any)[key]
    }
    setRoles(newRoles)
    if (isEditing) {
      setDontLeave(true);
      const updatedPlaybook = {
        ...editingPlaybook,
        roles: newRoles,
      }
      const updatePlaybookProps = {
        playbook: updatedPlaybook,
        id: editingPlaybook.id,
      } as UpdatePlaybookProps
      dispatch(updateIndividualPlaybook(updatePlaybookProps))
    }
  }

  const updatePlaybook = () => {
    const updatedPlaybook = {
      ...editingPlaybook,
      description: description,
      name: name,
      roles: roles,
    }

    const updatePlaybookProps = {
      playbook: updatedPlaybook,
      id: editingPlaybook.id,
    } as UpdatePlaybookProps
    dispatch(updateIndividualPlaybook(updatePlaybookProps))
  }

  const onSelectDataSender = (dataSender: any) => {
    // handle data sender selection here
    setOpenDataSenderModal(false);
    const dataSenders = [...currentDataSenders]
    dataSenders.push(dataSender);
    setCurrentDataSenders(dataSenders);
  }

  
  return (
    <>
      <div className="templateModal">
        <div className="editModalContainer">
          <div className="templateRow">
            <h2>{isEditing ? 'Editing ' : 'Create '}Playbook</h2>
            <button className="close" onClick={onClose} />
          </div>
          <div className="templateFieldsContainer">
            <div className="field">
              <label>Playbook Name*</label>
              <input value={name} className="defaultInput" onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="field">
              <label>Playbook Description*</label>
              <textarea value={description} className="defaultTextArea" onChange={e => setDescription(e.target.value)} />
            </div>
            <div className="field">
              <div className="keyMapRow">
                <label>Playbook Roles</label>
                <button onClick={() => setOpenKeyMapModal(true)}>Add New Role</button>
              </div>
              <div className="roleItems">
                <div className="roleHeaders">
                  <h4 className="oneThird">Role Name</h4>
                  <h4 className="oneThird">Role Object Type</h4>
                  <h4 className="oneThird">Actions</h4>
                </div>
                {Object.keys(roles).length > 0 && Object.keys(roles).map((key, index) => (
                  <div key={`role-${index}`} className="roleItem">
                    <p className="oneThird">{key}</p>
                    <p className="oneThird">{(roles as any)[key]}</p>
                    <div className="keyMapRow oneThird">
                      <button onClick={() => removeRole(key)}>Remove</button>
                    </div>
                  </div>
                ))}
                {Object.keys(roles).length === 0 && <p>There are no roles yet</p>}
              </div>
            </div>
            <div className="field">
              <div className="keyMapRow">
                <label>Templates</label>
                <button onClick={() => openTemplates()}>Add Templates</button>
                <button onClick={() => setOpenDataSenderModal(true)}>Add Data Sender</button>
              </div>
              <div className="roleHeaders">
                  <h4 className="oneThird">Template Name</h4>
                  <h4 className="oneThird">Sending To</h4>
                  <h4 className="oneThird">Actions</h4>
                </div>
              <div className="roleItems">
                {currentTemplates.length > 0 && currentTemplates.map((template: any, index: number) => (
                  <div key={`template-item-${index}`} className="roleItem">
                    <p className="oneThird">{template.template.name}</p>
                    <p className="oneThird">{template.send_to.map((item: any) => item.label || item).join(',')}</p>
                    <div className="keyMapRow oneThird">
                      <button onClick={() => removeItem(index)}>Remove</button>
                      {/* <button onClick={() => onEditTemplate(template, index)}>Edit</button> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="field">
              <div className="roleHeaders">
                  <h4 className="oneThird">Data Sender Name</h4>
                  <h4 className="oneThird">Data Sender ID</h4>
                  <h4 className="oneThird">Actions</h4>
                </div>
              <div className="roleItems">
                {currentDataSenders.length > 0 && currentDataSenders.map((dataSender: any, index: number) => (
                  <div key={`template-item-${index}`} className="roleItem">
                    <p className="oneThird">{dataSender.name || 'N/A'}</p>
                    <p className="oneThird">{dataSender.id}</p>
                    <div className="keyMapRow oneThird">
                      <button onClick={() => removeDataSender(index)}>Remove</button>
                      {/* <button onClick={() => onEditTemplate(template, index)}>Edit</button> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {!isEditing && (<button className="defaultButton" onClick={() => createNewPlaybook()}>Create Playbook</button>)}
            {isEditing && (<button className="defaultButton" onClick={() => updatePlaybook()}>Update Playbook</button>)}
          </div>
        </div>
      </div>
      {openKeyMapModal && (
        <KeyMapModal onClose={() => setOpenKeyMapModal(false)} onSubmit={(keyMapItem: any) => updateKeyMap(keyMapItem)} />
      )}
      {playbookTemplateListOpen && (
        <TemplatePlaybookModal onSubmit={(templateObjWithRoleTranslation: any) => addTemplate(templateObjWithRoleTranslation)} playbookRoles={roles} onClose={() => dispatch(togglePlaybookTemplateList())} />
      )}
      {openDataSenderModal && (
        <DataSenderModal onSelect={(dataSender: any) => onSelectDataSender(dataSender)} onClose={() => setOpenDataSenderModal(false)} />
      )}
      {/* <ToastContainer newestOnTop /> */}
    </>
  )
}

export default CreatePlaybookModal;
