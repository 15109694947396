import React, { useMemo, useState } from 'react';
import cn from 'classnames';

const resultsPerPage = 10;
const pageRangeDisplayed = 3;

const TablePagination = ({ count, currentPage, setCurrentPage }: any) => {
  const [pageCount, setPageCount] = useState(1);
  useMemo(() => {
    setPageCount(Math.ceil(count / resultsPerPage));
  }, [count]);
 
  const createPageView = (idx:number) => {
    const isCurrentPage = idx + 1 === currentPage;
  
      return (
        <li key={idx} className="rubik">
          <button
            className={cn({ 'defaultBlue': isCurrentPage })}
            onClick={() => setCurrentPage(idx + 1)}
            disabled={isCurrentPage}
          >
            {idx + 1}
          </button>
        </li>
      );
  }

  const getForwardJump = () => {
    const forwardJump = currentPage + pageRangeDisplayed;
    return forwardJump >= pageCount ? pageCount - 1 : forwardJump;
  };

  const getBackwardJump = () => {
    const backwardJump = currentPage - 1;
    return backwardJump < 0 ? 0 : backwardJump;
  };

  const pagination = () => {
    let items: any[] = [];
    if (pageCount <= pageRangeDisplayed) {
      return Array.from({ length: pageCount }, (_, i) => {
        return createPageView(i);
      });
    } else {
      let leftSide = pageRangeDisplayed / 2;
      let rightSide = pageRangeDisplayed - leftSide;

      if (currentPage > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - currentPage;
        leftSide = pageRangeDisplayed - leftSide;
      } else if (currentPage < pageRangeDisplayed / 2) {
        leftSide = currentPage;
        rightSide = pageRangeDisplayed - leftSide;
      }

      let index;
      let page;
      let breakView;

      for (index = 0; index < pageCount; index += 1) {
        page = index + 1;
        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (page <= 1) {
          items.push(createPageView(index));
          continue;
        }
        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (page > pageCount - 1) {
          items.push(createPageView(index));
          continue;
        }
        // If the page index is near the selected page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (index >= currentPage - leftSide && index <= currentPage + rightSide) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (items[items.length - 1] !== breakView) {

          const s = getForwardJump();
          const b = getBackwardJump();
          let nextIndex = s;
          if (currentPage === 0) {
            nextIndex = currentPage + index + 1;
          }
          if (currentPage === 1) {
            nextIndex = currentPage + index;
          }
          if (index < currentPage) {
            nextIndex = b;
          }

          breakView = (
            <li className="rubik">
              <button
                // eslint-disable-next-line
                onClick={() => setCurrentPage(nextIndex)}
              >
                ...
              </button>
            </li>
          );
          items.push(breakView);
        }
      }
      return items;
    }
  }
  return (
    <div className="paginationContainer">
      <ol
        className="paginationContent"
        id="paginationItemsList"
      >
        <li className="rubik">
          <button
            className={cn({ 'gray300': currentPage === 1 })}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {`<`}
          </button>
        </li>

        {pagination()}

        <li className="rubik">
          <button
            className={cn({ 'gray300': currentPage === pageCount })}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === pageCount || pageCount === 0}
          >
            {`>`}
          </button>
        </li>
      </ol>
    </div>
  );
};

export default TablePagination;
