import React, { useEffect } from "react"
import NumberOptionsField from "../NumberOptionsField/NumberOptionsField"

const RadioNumber = ({ fieldItem, dealErrors, onChange, value }: any) => {
  useEffect(() => {
    if (fieldItem.default) {
      onChange(fieldItem.default)
    }
  }, [])

  return (
    <div
      key={fieldItem.keyName}
      className={`fieldContainer radioContainer ${
        dealErrors &&
        (dealErrors[fieldItem.keyName] ||
          dealErrors[`${fieldItem.keyName}_string`])
          ? "error"
          : ""
      }`}
    >
      <label>
        <b>
          {fieldItem.label}
          {fieldItem.required && " *"}
        </b>
      </label>

      <div
        className="radioOptionsContainer"
        onChange={(event: any) => onChange(event.target.value)}
      >
        {fieldItem.options.map((option: string, index: number) => (
          <NumberOptionsField
            key={`radio-number-${fieldItem.keyName}-${index}`}
            fieldItem={fieldItem}
            option={option}
            type="numeric"
            selected={value === option || false}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioNumber
