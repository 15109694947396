import React from 'react';
import './CommunicationLayout.scss';

const CommunicationLayout = ({ children }: any) => (
  <div className="communicationLayoutContainer">
    {children}
  </div>
);

export default CommunicationLayout;
