import React from 'react';
import Contacts from '../../../Interfaces/contacts';
import './ContactDetails.scss';
const ContactDetails = ({ contacts }: { contacts: Contacts}) => (
  <div className="contactDetailsContainer">
    <div className="contactDetails">
      <p><strong>Agents</strong></p>
      {contacts.agents.map((agent) => (
        <div className="contact">
          <p className="underlined">{agent.firstname} {agent.lastname}</p>
          <p><strong>Is SMS Opted In: </strong> {agent.sms_opt_out ? 'Agent has opted out of sms' : 'false'}</p>
          <p><strong>Opted In Text Consent: </strong> {!agent.text_consent_opt_in ? 'Agent has not consented to sms' : 'true'}</p>
        </div>
      ))}
    </div>
    <div className="contactDetails">
      <p><strong>Loan Officer</strong></p>
      {contacts.loan_officer && (
        <div className="contact">
          <p className="underlined">{contacts.loan_officer.firstname} {contacts.loan_officer.lastname}</p>
          <p><strong>Is SMS Opted In: </strong> {contacts.loan_officer.sms_opt_out ? 'LO has opted out of sms' : 'false'}</p>
          <p><strong>Opted In Text Consent: </strong> {!contacts.loan_officer.text_consent_opt_in ? 'LO has not consented to sms' : 'true'}</p>
        </div>
      )}
    </div>
    <div className="contactDetails">
      <p><strong>Cash Buyers</strong></p>
      {contacts.cash_buyers.map((cashBuyer) => (
        <div className="contact">
          <p className="underlined">{cashBuyer.firstname} {cashBuyer.lastname}</p>
          <p><strong>Is SMS Opted In: </strong> {cashBuyer.sms_opt_out ? 'Cash Buyer has opted out of sms' : 'false'}</p>
          <p><strong>Opted In Text Consent: </strong> {!cashBuyer.text_consent_opt_in ? 'Cash Buyer has not consented to sms' : 'true'}</p>
        </div>
      ))}
    </div>
    <div className="contactDetails">
      <p><strong>Customer</strong></p>
      {contacts.customer && (
        <div className="contact">
          <p className="underlined">{contacts.customer.firstname} {contacts.customer.lastname}</p>
          <p><strong>Is SMS Opted In: </strong> {contacts.customer.sms_opt_out ? 'Customer has opted out of sms' : 'false'}</p>
          <p><strong>Has Text Consent: </strong> {!contacts.customer.text_consent_opt_in ? 'Customer has not consented to sms' : 'true'}</p>
        </div>
      )}
    </div>
  </div>
);

export default ContactDetails;
