import React from 'react';

const Collapsible = ({ children, collapsed }: any) => {
  
  return (
    <div className={`collapsible ${collapsed ? 'collapsed': ''}`}>
      {children}
    </div>
  )
}

export default Collapsible;
