import { navigate } from 'gatsby-link';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../../store';
import useDefaultFormBuilder from '../FormHook/defaultFormHook';
import './fields.scss';
import DefaultButton from '../../../Buttons/DefaultButton';
import { dataSenderFields } from './config';
import { resetSurvey } from '../../../../store/Surveys/reducer';
import { selectDataSenderData, selectDataSenderErrors, selectDataSenderLoading, selectDataSenderObject, selectSuccessfullySentData } from '../../../../store/DataSender/selector';
import LoadingSmall from '../../../Loading/LoadingSmall';
import { DataSenderDataModel, DataSenderModel, DataSenderProps, getCustomerDataSender, getCustomerDataSenderData, sendCustomerDataSender, sendData } from '../../../../store/DataSender/actions';
import { resetDataStates, setErrors } from '../../../../store/DataSender/reducer';
import { number } from 'prop-types';
import { filterSendThroughKeys } from '../../../../utils/helpers';

interface DataSenderContainerParams {
  selectedVid: number,
  dataSenderRequestObject: DataSenderModel,
}

const DataSenderContainerV1 = ({ selectedVid, dataSenderRequestObject }: DataSenderContainerParams) => {
  const dispatch = useAppDispatch();
  const [config, setConfig] = useState(null) as any[];
  const [fields, data] = useDefaultFormBuilder({ config });
  const success = useSelector((state) => selectSuccessfullySentData(state)) as boolean;
  const loading = useSelector((state) => selectDataSenderLoading(state)) as boolean;
  const errors = useSelector((state) => selectDataSenderErrors(state));

  // const dataSenderRequestObject = useSelector((state) => selectDataSenderObject(state)) as DataSenderModel;
  const dataSenderData = useSelector((state) => selectDataSenderData(state)) as DataSenderDataModel;


  useEffect(() => {
    if (errors) {
      const errorString = errors.join(', ')
      toast(errorString, { type: 'error' });
      dispatch(resetSurvey())
    }
  }, [errors])

  useEffect(() => {
    if (selectedVid) {
      // dispatch(getCustomerDataSender(selectedVid));
      dispatch(getCustomerDataSenderData(selectedVid));
    }
  }, [selectedVid])

  useEffect(() => {
    if (dataSenderData && dataSenderRequestObject) {
      const config = dataSenderFields(dataSenderData, dataSenderRequestObject);
      setConfig(config);
    }
  }, [dataSenderData, dataSenderRequestObject])

  const handleFormSubmit = () => {
    if (data) {
      const newData = filterSendThroughKeys(config, data);

      const params = {
        data_sender_id: dataSenderRequestObject.id,
        data: { ...newData },
        vid: selectedVid,
      }
      dispatch(sendCustomerDataSender(params));

    } else {
      toast("There is missing data, please enter all the correct information", { type: 'error' });
    }
  }

  return (
    <div className="defaultFormContainer">
      <div className="defaultFormBody">
        <p>Enter all the fields below and click Submit.</p>
        <div>
          {fields}
          <DefaultButton text={loading ? <LoadingSmall /> : 'SUBMIT'} className="defaultButton" callback={() => handleFormSubmit()} />
        </div>
      </div>
    </div>
  )
}

export default DataSenderContainerV1;
