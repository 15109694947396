/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
import React, {
  useEffect,
  useRef,
  useMemo,
} from 'react';
import cuid from 'cuid';
import classNames from 'classnames';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import PropTypes from 'prop-types';
import './AddressField.scss';
import TextInput from '../TextInput/TextInput';

// THIS COMPONENT WAS MODIFIED FROM CLEVER-COMPONENTS-V2
const AddressField = ({
  addressPlaceholder,
  addressType, // https://docs.mapbox.com/api/search/geocoding/#data-types
  addressValue,
  disableMap,
  handleAddressChange,
  handleUnitChange,
  hideUnitField,
  unitValue,
  TESTING_KEY,
}: any) => {
  const addressRef = useRef<any>(null);
  const addressId = useMemo<any>(() => `Address-${cuid()}`, []);
  const unitId = useMemo(() => `Unit-${cuid()}`, []);

  useMemo(() => {
    if (addressRef.current && addressValue) {
      const setInputAndMarker = () => {
        if (addressRef.current) {
          const mapboxInput = addressRef.current.querySelector('.mapboxgl-ctrl-geocoder--input');
          if (mapboxInput) {
            mapboxInput.value = addressValue.placeName;
          } else {
            // If input doesn't exist yet, we need to wait for it to be created
            setTimeout(setInputAndMarker, 100);
          }
        }
      };

      setInputAndMarker();
    }
  }, [addressRef]);

  useEffect(() => {
    if (addressRef.current) {
      const geocoder = new MapboxGeocoder({
          accessToken: process.env.GATSBY_MAPBOX_ACCESS_TOKEN || TESTING_KEY,
          countries: 'us',
          placeholder: addressPlaceholder,
          types: addressType,
          mapboxgl,
        } as any);

      geocoder.on('result', ({ result }) => {
        if (result) {
          const { place_name, context, center } = result;
          const info = context.reduce((acc: any, next: any) => {
            const type = next.id.match(/(.+)\./)[1];
            acc[type] = next.text;
            return acc;
          }, {});
  
          if (result.place_type[0] === 'postcode' && !info.postcode) info.postcode = result.text;
  
          handleAddressChange({ placeName: place_name, info, coordinates: center });
  
          if (addressRef.current) {
            addressRef.current.querySelector('.mapboxgl-ctrl-geocoder--input').blur();
          }
        }
      });

      geocoder.on('clear', () => {
        handleAddressChange({placeName: ''});
      });

      geocoder.on('loading', ({ query }) => {
        // Enter any address.
        // console.log("LOADING", query);
        handleAddressChange({ placeName: query, info: null, coordinates: null})
      })

      if (disableMap) {
        geocoder.addTo(addressRef.current);
      }
    }
  }, [addressRef]);

  return (
    <section className={"addressField"} >
      <fieldset className={"inputs"}>
        <TextInput
          id={addressId}
          name="Address"
          ref={addressRef}
          hideInput
          icon={(
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 17" className={classNames({ ["valid"]: !!addressValue })}>
              {/* eslint-disable-next-line max-len */}
              <path d="M6.5.58A5.54 5.54 0 00.96 6.12c0 4.16 5.54 10.3 5.54 10.3s5.54-6.14 5.54-10.3A5.54 5.54 0 006.5.58zM2.54 6.12a3.96 3.96 0 017.92 0c0 2.28-2.28 5.7-3.96 7.83-1.65-2.12-3.96-5.57-3.96-7.83zm1.98 0a1.98 1.98 0 113.96 0 1.98 1.98 0 01-3.96 0z" fill="currentColor" />
            </svg>
          )}
        />

        {!hideUnitField && (
          <TextInput
            id={unitId}
            name="Unit #"
            placeholder="Unit # (optional)"
            handleValue={handleUnitChange}
            value={unitValue}
          />
        )}
      </fieldset>
    </section>
  );
};

AddressField.defaultProps = {
  addressPlaceholder: 'Enter Your Address',
  addressValue: null,
  addressType: 'address',
  disableMap: false,
  hideUnitField: false,
  handleUnitChange: () => { },
  unitValue: null,
  TESTING_KEY: null,
};

AddressField.propTypes = {
  addressPlaceholder: PropTypes.string,
  addressType: PropTypes.string,
  addressValue: PropTypes.object,
  disableMap: PropTypes.bool,
  handleAddressChange: PropTypes.func.isRequired,
  handleUnitChange: PropTypes.func,
  hideUnitField: PropTypes.bool,
  unitValue: PropTypes.string,
  TESTING_KEY: PropTypes.string,
};

export default AddressField;
