import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommunicationLayout from '../components/Communications/CommunicationLayout';
import Background from '../components/Communications/Components/Background';
import CommunicationModule from '../components/Communications/Components/CommunicationModule';
import EditTemplateModal from '../components/Communications/EditTemplateModal/EditTemplateModal';
import { useAppDispatch } from '../store';
import { getAgentFromId, getContactsFromId, getCustomerFromId, getLoanOfficerFromId } from '../store/Communications/actions';
import { getTokensFromMagicLink } from '../store/MagicLinks/actions';
import { selectMagicLinksLoading, selectMagicLinksSuccess } from '../store/MagicLinks/selectors';
import { isEditingModalOpen, selectEditingTemplate, selectEditingType } from '../store/Templates/selectors';
import { selectIsLoading } from '../store/Communications/selectors';
import Loading from '../components/Loading/Loading';
import { useAuthenticationStatus } from '../components/hooks';


const baseURL: string | undefined = process.env.GATSBY_API_URL;

const Communications = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(state => selectIsLoading(state));
  const [authed, setAuthed] = useState(false)
  const [isAuthenticated, authLoading] = useAuthenticationStatus();
  const magicLinkSuccess = useSelector(state =>
    selectMagicLinksSuccess(state),
  ) as boolean;
  const magicLinkLoading = useSelector((state) => selectMagicLinksLoading(state));

  const openEditingTemplateModal = useSelector(state => isEditingModalOpen(state));
  const editingTemplate = useSelector(state => selectEditingTemplate(state));
  const editingType = useSelector(state => selectEditingType(state));

  const requestDealOrContact = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const vid = urlParams.get('vid');
    const type = urlParams.get('type');
    const dealId = urlParams.get('deal_id');
    if (dealId) {
      dispatch(getContactsFromId(dealId));
    } else if (vid && (type === "customer" || type === "Customer")) {
      // customer request
      dispatch(getCustomerFromId(vid));
    } else if (vid && (type === "agent" || type === "Agent")) {
      // agent request
      dispatch(getAgentFromId(vid));
    } else if (vid && (type === "loan_officer" || type === "Loan Officer")) {
      // lo request
      dispatch(getLoanOfficerFromId(vid));
    }
    setAuthed(true);
  }
  // Callback for magic links request invitation once authed
  useEffect(() => {
    if (magicLinkSuccess && !magicLinkLoading) {
      // TODO: get deal/communications stuff
      requestDealOrContact();
      
    }
  }, [magicLinkSuccess, magicLinkLoading]);

  useEffect(() => {
    if (isAuthenticated && !authLoading && !magicLinkLoading) {
      requestDealOrContact();
    }
  }, [isAuthenticated, authLoading])

  // On mount check magic links and contact and deal ids
  useEffect(() => {
    if (authLoading || magicLinkLoading) {
      return;
    }
    if (!isAuthenticated) {
      const urlParams = new URLSearchParams(window.location.search);
      const magicUuid = urlParams.get('magic_uuid');
      if (magicUuid) {
        dispatch(getTokensFromMagicLink(magicUuid!));
      } 
      
    }
  }, [isAuthenticated, authLoading]);

  return (
    <>
      {authed && !authLoading && !magicLinkLoading && (
        <CommunicationLayout>
          <Background>
            <CommunicationModule />
          </Background>
        </CommunicationLayout>
      )}
      {isLoading && <Loading />}
      {authed && !authLoading && !magicLinkLoading && openEditingTemplateModal && <EditTemplateModal isEditing={editingTemplate !== null} editingType={editingType} />}
    </>
  )
}

export default Communications;
