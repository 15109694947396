import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import confetti from 'canvas-confetti';
import Customer from '../../../Interfaces/customer';
import User from '../../../Interfaces/user';
import { useAppDispatch } from '../../../store';
import { openCreateDealModal } from '../../../store/AddContacts/reducer';
import { getCustomerFromId, getCustomerHubspotInfo } from '../../../store/Communications/actions';
import { selectCustomer, selectCustomerHubspotInfo } from '../../../store/Communications/selectors';
import { buyerSLALookup, CommissionModels, createDeal, sellerSLALookup, SLALookupRequestProps } from '../../../store/Deal/actions';
import { resetDealStates, setErrors } from '../../../store/Deal/reducer';
import {  selectDealCreationErrors,  selectSuccessfullyCreatedDeal } from '../../../store/Deal/selector';
import { setEditingObject, setEditingType, setOpenEditFieldsModal } from '../../../store/EditingFields/reducer';
import { selectOpenEditFieldsModal } from '../../../store/EditingFields/selectors';
import { selectUser } from '../../../store/User/selectors';
import EditFieldsModal, { FieldObjectTypes, FieldTypes } from '../EditFieldsModal/EditFieldsModal';
import useDealFormBuilderHook from './Form/useDealFormBuilder';
import useDefaultDealFormHook from './Form/useDefaultDealFormBuilder';
import Loading from '../../Loading/Loading';
import useSLAFormBuilder from './Form/useSLAFormBuilder';
// BOTH REQUIRE:
// "agent_notes",
// "conversion_source",
// "customer", - provided as the vid of the customer
// "hubspot_owner_id", - should be able to grab this from hubspot api
// "internal_notes",
// "lead_confidence_score", - 1 2 or 3
// "listing_link",
// "loan_officer",
// "property_type",
// "preferred_appointment_date",
// "preferred_appointment_time",

// if Buyer
// "buying_market_string",
// "buying_price_high",
// "buying_price_low",

// If Seller
// "home_value", 
// "listing_address_string", 
// "zestimate" 

const RequiredFields = [
  {
    label: 'Buyer or Seller Deal?',
    keyName: 'buyer_or_seller',
    type: FieldTypes.dropdown,
    options: [
      'Seller',
      'Buyer',
      'Both'
    ]
  },
  {
    label: 'Agent Notes',
    keyName: 'agent_notes',
    type: FieldTypes.textarea,
  },
  {
    label: 'Conversion Source',
    keyName: 'conversion_source',
    type: FieldTypes.dropdown,
    options: [
      'Warm',
      'Cold',
      'AutoHunt',
      'Intercom',
      'Message',
      'Expired Listing',
      'Withdrawn Listing',
      'FSBO',
      'Personal Referral',
      'Traditional Referral Experiment'
    ]
  },
  {
    label: 'Internal Notes',
    keyName: 'internal_notes',
    type: FieldTypes.textarea,
  },
  {
    label: 'Lead Confidence Score',
    keyName: 'lead_confidence_score',
    type: FieldTypes.dropdown,
    options: [
      "1",
      "2",
      "3",
    ]
  },
  {
    label: 'Listing Link',
    keyName: 'listing_link',
    type: FieldTypes.textfield,
  },
  {
    label: 'Property Type',
    keyName: 'property_type',
    type: FieldTypes.dropdown,
    options: [
      'Single Family',
      'Condo',
      'Multifamily',
      'Commercial',
      'Land',
      'Townhouse',
      'Other',
      'Cooperative',
      'Manufactured or Mobile Home',
      'Mixed Use Residential',
      'Farm',
      'Home and Business Combined',
    ]
  },
  {
    label: 'Preferred Appointment Date',
    keyName: 'preferred_appointment_date',
    format: 'yyyy-MM-dd',
    type: FieldTypes.date,
    minDate: new Date(),
  },
  {
    label: 'Preferred Appointment Time',
    keyName: 'preferred_appointment_time',
    type: FieldTypes.dropdown,
    options: [
      "Morning",
      "Afternoon",
      "Evening",
      "All Day",
    ],
  },
  {
    label: 'Amount',
    keyName: 'net_revenue',
    type: FieldTypes.number,
  }
];
// -----------------------------
// Sample data for testing below
// -----------------------------
// const sampleCustomerHubspotData = {
//   "hubspot_owner_id": "38242868",
//   "connection_owner": "38242869",
//   "net_revenue": "1879.4750000000001",
//   "lastname": "Carlos Patriawan",
//   "phone": "+14086275831",
//   "ae_owner": "31489305",
//   "hs_analytics_source": "DIRECT_TRAFFIC",
//   "acquisition_channel": "SEO - Clever",
//   "is_in_lead_jail": false,
//   "email": "carlos@carlos.net",
//   "text_consent_opt_in": true,
//   "lead_status_id": 18,
//   "is_click_to_claim": "True",
//   "firstname": "Muhamad",
//   "contact_type": "Customer",
//   "listing_address": "243 Lawton Street, Antioch, CA, USA",
//   "is_opendoor": false,
//   "closedate": "1627686598811",
//   "listing_link": "http://www.zillow.com/homedetails/211-Rachael-Pl-Pleasanton-CA-94566/25076141_zpid/",
//   "conversion_source": "AutoHunt",
//   "buying_intent": "Unqualified"
// }

// const sampleCustomerHubspotData2 = {
//   "lead_confidence_score": "3",
//   "hubspot_owner_id": "92128593",
//   "net_revenue": "4775",
//   "lastname": "Bird",
//   "phone": "+19524521930",
//   "internal_notes": "Seller requested 3 Agent matches but understands if we do not have 3 available. Thank you!",
//   "hs_analytics_source": "PAID_SEARCH",
//   "acquisition_channel": "PPC",
//   "is_in_lead_jail": false,
//   "email": "jamesbird616@gmail.com",
//   "text_consent_opt_in": true,
//   "lead_status_id": 18,
//   "buying_price_high": "500000",
//   "agent_notes": "Who is selling: James\n\nWhy they're selling: Interested in moving closer to his son's high school in Chaska, MN.\n\nTimeframe: ASAP.\n\nMost Important to seller: Excellent Service and Savings. Professional Pricing Recommendation. Clever Cash Back.\n\nNotes about the property: 4 Bedroom, 4 Bathroom House. 2,393 Sqft.\n\nOther important notes: Seller is interested in working with Listing Agent as his Buyer's Agent as well. Please contact Seller right away. Thank you!",
//   "preferred_appointment_date": "1631491200000",
//   "is_click_to_claim": "True",
//   "buying_market": "55318",
//   "firstname": "James",
//   "contact_type": "Customer",
//   "listing_address": "4750 Bulrush Blvd, Shakopee, MN 55379",
//   "buying_price_low": "500000",
//   "listing_link": "https://www.zillow.com/homedetails/4750-Bulrush-Blvd-Shakopee-MN-55379/64880188_zpid/",
//   "conversion_source": "Warm",
//   "buying_intent": "Unqualified",
//   "gclid": "CjwKCAjw7fuJBhBdEiwA2lLMYauHb2p0aPLXdaXncvu1r_LtK14yEaR9H82HrjpltWwjL3SYtP0Y2hoC6BMQAvD_BwE",
//   "buyer_commission_model": "Clever Cashback ($250)"
// }

// const hubspotInfo = {
//   "hubspot_owner_id": "95151413",
//   "connection_owner": "38242869",
//   "net_revenue": "1000",
//   "lastname": "Soloman",
//   "phone": "+12192265186",
//   "internal_notes": "Agents Eligible for C2C (and why):\n\nAgents To Call and Confirm (and why):\n\nNotes For Hard Sourcing (what’s important to the customer - brokerage, neighborhood, etc):",
//   "hs_analytics_source": "DIRECT_TRAFFIC",
//   "acquisition_channel": "SEO - Clever",
//   "is_in_lead_jail": false,
//   "email": "stephaniesolo@test.com",
//   "text_consent_opt_in": true,
//   "lead_status_id": 17,
//   "agent_notes": "Who is selling: \n\nWhy they’re selling:\n\nTimeframe:\n\nMost Important to seller:\n\nReal Estate Experience:\n\nNotes about the property:\n\nOther important notes:",
//   "is_click_to_claim": "True",
//   "firstname": "Stephanie",
//   "seller_commission_model": "Flat Fee Model",
//   "contact_type": "Customer",
//   "listing_address": "1501 Locust St, St. Louis, MO 63103, USA",
//   "conversion_source": "AutoHunt",
//   "buying_intent": "Unqualified"
// }

const CreateDealModal = () => {
  const dispatch = useAppDispatch();
  const dealErrors = useSelector((state) => selectDealCreationErrors(state));
  const successfullyCreatedDeal = useSelector((state) => selectSuccessfullyCreatedDeal(state));
  const customer = useSelector((state) => selectCustomer(state)) as Customer;
  const customerHubspotInfo = useSelector((state) => selectCustomerHubspotInfo(state));
  const user = useSelector((state) => selectUser(state)) as User
  const isEditFieldsModalOpen = useSelector((state) => selectOpenEditFieldsModal(state)) as boolean;

  const [defaultFields, data] = useDefaultDealFormHook(RequiredFields);
  const [specificFields, specificData] = useDealFormBuilderHook({ selectedDealStage: data && data.buyer_or_seller ? data.buyer_or_seller : null, defaultData: customerHubspotInfo })
  const [slaFields, slaData] = useSLAFormBuilder({ currentData: data});
  const [isFetching, setIsFetching] = useState(false);
  const [fetchedInitial, setFetchedInitial] = useState(false);
  useEffect(() => {
    if (customer && !isFetching && !fetchedInitial) {
      setIsFetching(true);
      setFetchedInitial(true);
      dispatch(getCustomerHubspotInfo(customer.id))
      dispatch(getCustomerFromId(customer.id))
    }
  }, [customer])

  useEffect(() => {
    if (customerHubspotInfo) {
      setIsFetching(false);
    }
  }, [customerHubspotInfo])

  useEffect(() => {
    if (successfullyCreatedDeal) {
      setIsFetching(false);
      const myCanvas = document.getElementById('confetti') as HTMLCanvasElement;
      if (myCanvas) {
        const myConfetti = confetti.create(myCanvas, {
          resize: true,
          useWorker: true,
        })
        myConfetti({
          particleCount: 100,
          spread: 160,
        })
  
        setTimeout(() => {
          myConfetti.reset();
        }, 10000)
      }
    }
  }, [successfullyCreatedDeal])

  useEffect(() => {
    if (dealErrors && Object.keys(dealErrors).length > 0) {
      setIsFetching(false);
      Object.keys(dealErrors).map((errorKey) => {
        toast(`${dealErrors[errorKey]}`, { type: 'error' })
      })
      // Reset errors after 10 seconds
      setTimeout(() => {
        dispatch(resetDealStates());
      }, 10000)
    }
  }, [dealErrors])

  const onClose = () => {
    dispatch(openCreateDealModal(false));
    dispatch(resetDealStates())
  }

  const hasUppercase = (str: string) => /[A-Z]/.test(str);

  const handleErrors = () => {
    /// FOR TESTING PURPOSES
    console.log(`------ SPECIFIC DATA ------\n\n`)
    console.log({ specificData })
    console.log(`------ DEFAULT DATA ------\n\n`)
    console.log({ data })
    console.log(`------ CUSTOMER ------\n\n`)
    console.log({ customer })
    console.log(`------ SLA DATA ------\n\n`)
    console.log({ slaData })
    if (hasUppercase(specificData.loan_officer_email)) {
      toast('The loan officer email has uppercase characters in it', { type: 'error'})
      return false;
    }
    if (!customer) {
      toast('There is no customer', { type: 'error' })
      return false
    }
    if (!user) {
      toast('There is an error a user is not available', { type: 'error' })
      return false;
    }
    if (!data.buyer_or_seller) {
      dispatch(setErrors({
        'buyer_and_seller': 'Buyer or Seller has not been selected'
      }))
      return false;
    }
    if (data.buyer_or_seller === "Seller") {
      let errors = {} as any;
      if (!specificData.listing_address || specificData.listing_address === '') {
        errors.listing_address = "Please enter a valid listing address"
      }
      if (!specificData.home_value) {
        errors.home_value = "Please enter a valid home value"
      }
      if (!specificData.seller_commission_model) {
        errors.seller_commission_model = "Please select a Seller Commission Model"
      }
      if (Object.keys(errors).length > 0) {
        dispatch(setErrors(errors));
        return false;
      }
    }
    if (data.buyer_or_seller === "Buyer") {
      let errors = {} as any;
      if (!specificData.buyer_commission_model) {
        errors.buyer_commission_model = "Please select a Buyer Commission Model";
      }
      if (!specificData.buying_market || specificData.buying_market === '') {
        errors.buying_market = "Please enter a valid Buying Market Address";
      }
      if (!specificData.buying_price_high) {
        errors.buying_price_high = "Please enter a valid Buying Price High";
      }
      if (!specificData.buying_price_low) {
        errors.buying_price_low = "Please enter a valid Buying Price Low"
      }
      if (Object.keys(errors).length > 0) {
        dispatch(setErrors(errors));
        return false;
      }
    }
    if (data.buyer_or_seller === "Both") {
      let errors = {} as any;
      if (!specificData.buyer_commission_model) {
        errors.buyer_commission_model = "Please select a Buyer Commission Model";
      }
      if (!specificData.buying_market || specificData.buying_market === '') {
        errors.buying_market = "Please enter a valid Buying Market Address";
      }
      if (!specificData.buying_price_high) {
        errors.buying_price_high = "Please enter a valid Buying Price High";
      }
      if (!specificData.buying_price_low) {
        errors.buying_price_low = "Please enter a valid Buying Price Low"
      }
      if (!specificData.listing_address || specificData.listing_address === '') {
        errors.listing_address = "Please enter a valid listing address"
      }
      if (!specificData.home_value) {
        errors.home_value = "Please enter a valid home value"
      }
      if (!specificData.seller_commission_model) {
        errors.seller_commission_model = "Please select a Seller Commission Model"
      }
     
      if (Object.keys(errors).length > 0) {
        dispatch(setErrors(errors));
        return false;
      }
    }
    return true
  }

  const onCreate = () => {
    if (Object.keys(data).length > 0) {
      // We good to go
      const noErrors = handleErrors();
      if (!noErrors) {
        return;
      }
      setIsFetching(true);
      let preferredAptDate = data.preferred_appointment_date;
      const regex = /\d{4}-\d{2}-\d{2}/g
      if (data.preferred_appointment_date && !regex.test(data.preferred_appointment_date)) {
        preferredAptDate = new Date(parseInt(preferredAptDate)).toLocaleString('en-US', { timeZone: 'GMT' });
        preferredAptDate = format(new Date(preferredAptDate), 'yyyy-MM-dd');
      }
      
      let props = {
        customer: customer.id,
        property_type: data.property_type,
        lead_confidence_score: data.lead_confidence_score,
        listing_link: data.listing_link,
        conversion_source: data.conversion_source,
        internal_notes: data.internal_notes,
        amount: data.net_revenue,
        agent_notes: data.agent_notes,
        preferred_appointment_date: preferredAptDate,
        preferred_appointment_time: data.preferred_appointment_time,
      } as any

      if (data.buyer_or_seller === "Buyer") {
        props.buyer_commission_model_id = specificData.buyer_commission_model ? (CommissionModels as any)[specificData.buyer_commission_model] : null;
        props.buying_price_high = specificData.buying_price_high;
        props.loan_officer_email = specificData.loan_officer_email;
        props.buying_price_low = specificData.buying_price_low;
        props.buying_market_string = specificData.buying_market;
        
      } else if (data.buyer_or_seller === "Seller") {
        props.seller_type = specificData.seller_type || null;
        props.home_value = specificData.home_value;
        props.seller_commission_model_id = specificData.seller_commission_model ? (CommissionModels as any)[specificData.seller_commission_model] : null;
        props.listing_address_string = specificData.listing_address;
        props.unit_number = specificData.unit_number;
        
      } else if (data.buyer_or_seller === "Both") {
        props.seller_type = specificData.seller_type || null;
        props.home_value = specificData.home_value;
        props.seller_commission_model_id = specificData.seller_commission_model ? (CommissionModels as any)[specificData.seller_commission_model] : null;
        props.listing_address_string = specificData.listing_address;
        props.unit_number = specificData.unit_number;
        props.buyer_commission_model_id = specificData.buyer_commission_model ? (CommissionModels as any)[specificData.buyer_commission_model] : null;
        props.buying_price_high = specificData.buying_price_high;
        props.loan_officer_email = specificData.loan_officer_email;
        props.buying_price_low = specificData.buying_price_low;
        props.buying_market_string = specificData.buying_market;
        
      } else {
        toast("There was an error with the selected buyer seller option.", { type: 'error' })
        return;
      }
      if (slaData) {
        props = { ...props, ...slaData }
      }
      dispatch(createDeal(props));
    } else {
      toast('Please fill out the fields', { type: 'warning' })
    }
  }

  const editCustomer = () => {
    dispatch(setEditingType(FieldObjectTypes.Customer));
    dispatch(setEditingObject(customer))
    dispatch(setOpenEditFieldsModal(true))
  }

  const slaCheck = () => {
    // Handle SLA Lookup
    let props = {} as SLALookupRequestProps
    if (data.buyer_or_seller === 'Seller' && specificData.listing_address && specificData.home_value) {
      // Seller fetch the sla look up
      props = {
        deal_type: 'Sell',
        home_value: specificData.home_value,
        address: specificData.listing_address
      }
      dispatch(sellerSLALookup(props))
      return;
    }

    if (data.buyer_or_seller === 'Buyer' && specificData.buying_market && specificData.buying_price_high) {
      // Seller fetch the sla look up
      props = {
        deal_type: 'Buy',
        home_value: specificData.buying_price_high,
        address: specificData.buying_market
      }
      dispatch(buyerSLALookup(props))
      return;
    }

    if (data.buyer_or_seller === 'Both' && specificData.buying_market && specificData.buying_price_high && specificData.home_value && specificData.listing_address) {
      // Seller fetch the sla look up
      props = {
        deal_type: 'Buy',
        home_value: specificData.buying_price_high,
        address: specificData.buying_market
      }
      let sellerProps = {
        deal_type: 'Sell',
        home_value: specificData.home_value,
        address: specificData.listing_address,
      } as SLALookupRequestProps
      dispatch(buyerSLALookup(props))
      dispatch(sellerSLALookup(sellerProps))
      return;
    }
    toast('Please make sure you have filled out the necessary fields before running an sla lookup. (Buying Price High and Buying Market for Buyer deals, Home Value and Listing address for Seller deals)', { type: 'error' })
  }

  return (
    <>
      <div className="updateForm">
        <div className="templateRow">
          <h2>Create Deal</h2>
          <button className="close" onClick={onClose} />
        </div>
        <button className="defaultButton" onClick={editCustomer}>Edit Customer</button>
        {!successfullyCreatedDeal && (
          <>
            {defaultFields}
            {specificFields}
            {slaFields}
            <div className="rowContainer">
              <button disabled={isFetching} data-testid="slaButton" className="defaultButton" onClick={slaCheck}>Update SLA Fields</button>
              <button disabled={isFetching} data-testid="actionButton"  className="defaultButton" onClick={onCreate}>Create Deal</button>
            </div>
          </>
        )}
        
        {successfullyCreatedDeal && <p className="success centered">Successfully Created Deal!</p>}
        {successfullyCreatedDeal && <button className="defaultButton" onClick={onClose}>Go Back</button>}
        <canvas id="confetti" width="800" height="400" />
      </div>
      {isFetching && (<Loading />)}
      {isEditFieldsModalOpen && (
        <EditFieldsModal />
      )}
    </>
  )
}

export default CreateDealModal;
