import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './TemplateModal.scss';
import { useAppDispatch } from '../../../store';
import { getAllTemplates } from '../../../store/Templates/actions';
import { getTemplates, selectDeleteTemplateSuccess } from '../../../store/Templates/selectors';
import { toggleEditingTemplateModal, setEditingTemplate, setTemplateType, EditingType, selectTemplate, resetState } from '../../../store/Templates/reducer';
import { filterEmailTemplatesByString, filterTemplateFoldersByString, organizeTemplatesByFolderTag } from '../../../utils/helpers';
import FolderImage from '../../../images/FolderImage.png';

const TemplateModal = ({ onClose, isEmailTemplate }: any) => {
  const templates = useSelector((state) => getTemplates(state)) as any;
  const deleteTemplateSuccess = useSelector((state) => selectDeleteTemplateSuccess(state));
  const [folders, setFolders] = useState({}) as any;
  const [openFolder, setOpenFolder] = useState(null) as any;
  const [expandedCell, setExpandedCell] = useState(null) as any;
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!templates) {
      dispatch(getAllTemplates())
    }
  }, [])

  useEffect(() => {
    if (deleteTemplateSuccess) {
      dispatch(getAllTemplates())
      dispatch(resetState());
    }
  }, [deleteTemplateSuccess])

  useEffect(() => {
    if (templates) {
      const FolderObjects = organizeTemplatesByFolderTag(templates);
      setFolders(FolderObjects);
    }
  }, [templates])

  const handleCreateEmailTemplateClick = () => {
    dispatch(toggleEditingTemplateModal())
    dispatch(setEditingTemplate(null));
    const editingType = EditingType.Email;
    dispatch(setTemplateType(editingType))
  }

  const handleCreateSMSTemplateClick = () => {
    dispatch(toggleEditingTemplateModal())
    dispatch(setEditingTemplate(null));
    const editingType = EditingType.SMS
    dispatch(setTemplateType(editingType))
  }

  const onEdit = (template: any) => {
    dispatch(setEditingTemplate(template));
    const isSMS = template.tags.indexOf('sms') > -1;
    dispatch(setTemplateType(isSMS ? EditingType.SMS : EditingType.Email))
    dispatch(toggleEditingTemplateModal());
  }

  const folderClicked = (e: any, key: string) => {
    e.preventDefault()
    e.stopPropagation();
    if (key && openFolder === key) {
      setOpenFolder(null)
    } else {
      setOpenFolder(key);
    }
  }

  const expandCell = (e: any, id: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (expandedCell === id) {
      setExpandedCell(null);
    } else {
      setExpandedCell(id);
    }
  }

  const generateKey = (pre: any) => {
    return `${ pre }_${ new Date().getTime() }`;
}

  const displayFolders = () => {
    return Object.keys(folders).map((folderKey: any, index: number) => (
      <tr key={generateKey(folderKey.toLowerCase().replace(/\s/g, '_'))} id={generateKey(folderKey.toLowerCase().replace(/\s/g, '_'))} className={`folderContainerMain ${openFolder === folderKey && 'folderOpen'}`} onClick={(e) => folderClicked(e, folderKey)}>
        <td colSpan={4} className="folderContainer" >
          <div className="folder">
            <div className="folderItemTitle">
              <div className="titleFolderIcon">
                <img width="20" height="20" src={FolderImage} />
                <p>{folderKey}</p>
              </div>
              <div className="arrow"></div>
            </div>
            {openFolder === folderKey && (
              <div className="folderTemplates">
                {(folders[folderKey] as any).map((template: any, templateIndex: number) => (
                  <div key={generateKey(`${template.id}-${index}-${templateIndex}`)} id={`${template.id}-${index}-${templateIndex}`} className={`template ${expandedCell === template.id && 'expanded'}`}>
                    <div className="blurBg" />
                    <button className="expandButton" onClick={(e) => expandCell(e, template.id)}>{expandedCell === template.id ? 'Collapse' : 'Expand'}</button>
                    <div className="descriptions">
                      <p className="smallText templateName"><strong>{template.name}</strong></p>
                      {template && template.subject && (<p className="noMarg smallText subject"><strong>Subject: </strong>{template.subject}</p>)}
                      <p className="smallText"><strong>Description: </strong>{template.description}</p>
                    </div>
                    <div className="sendTo">
                    </div>
                    <div className="roles">
                      <p className="smallText subject"><strong>Required Roles: {template.roles ? Object.keys(template.roles).map(role => template.roles[role]).join(', ') : ''}</strong></p>
                    </div>
                    <div className="actions">
                      <button className="editButton" onClick={() => onEdit(template)}>Edit</button>
                      <button className="selectButton" onClick={() => dispatch(selectTemplate(template))}>Select</button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </td>
      </tr>
    ))
  }

  const displayTemplates = () => {
    return <div>
    {filteredTemplates.map((template: any, index: number) => (
      <div key={generateKey(`filtered-${template.id}-${index}`)} id={`filtered-${template.id}-${index}`} className={`template expanded`}>
        <div className="descriptions">
          <p className="smallText templateName"><strong>{template.name}</strong></p>
          {template && template.subject && (<p className="noMarg smallText subject"><strong>Subject: </strong>{template.subject}</p>)}
          <p className="smallText"><strong>Description: </strong>{template.description}</p>
        </div>
        <div className="sendTo">
        </div>
        <div className="roles">
          <p className="smallText subject"><strong>Required Roles: {template.roles ? Object.keys(template.roles).map(role => template.roles[role]).join(', ') : ''}</strong></p>
        </div>
        <div className="actions flex flex-row">
          <button className="editButton" onClick={() => onEdit(template)}>Edit</button>
          <button className="selectButton" onClick={() => dispatch(selectTemplate(template))}>Select</button>
        </div>
      </div>
    ))}
  </div>
  }

  const onTemplateFilter = (e: any) => {
    if (!templates) {
      return;
    }
    if (e.target.value === '') {
      setFilteredTemplates(templates);
      setIsFiltering(false);
      return
    }
    setIsFiltering(true);
    const filtered = filterEmailTemplatesByString(templates, Object.keys(templates), e.target.value);
    
    setFilteredTemplates(filtered)
  }
  
  return (
    <div className="templateModal">
      <div className="templateContentContainer">
        <div className="templateRow">
          <h2>Templates</h2>
          <button className="close" onClick={onClose} />
        </div>
        {/* Search Field Here */}
        <div className="inputField">
          <div className="field">
            <label>Search</label>
            <input className="defaultInput" onChange={(e) => onTemplateFilter(e)} />
          </div>
        </div>
        <div className="availableTemplateContainer">
          <table>
            {!isFiltering && (<thead className="folderHead">
              <tr>
                <td width="20%" colSpan={4} className="folderTitle">Folders</td>
              </tr>
            </thead>
            )}
            <tbody>
              {Object.keys(folders).length > 0 && !isFiltering && displayFolders()}
              {isFiltering && displayTemplates()}
            </tbody>
          </table>
        </div>
        <button className="createTemplateButton" onClick={() => handleCreateEmailTemplateClick()}>Create a New Email Template</button>
        <button className="createTemplateButton" onClick={() => handleCreateSMSTemplateClick()}>Create a New SMS Template</button>
      </div>
    </div>
  )
}

export default TemplateModal;