import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldTypes } from '../../EditFieldsModal/EditFieldsModal';
import { useSelector } from 'react-redux';
import AddressField from '../../../AddressField';
import { selectDealCreationErrors } from '../../../../store/Deal/selector';
import { selectCustomerHubspotInfo } from '../../../../store/Communications/selectors';

const useDefaultDealFormHook = (fields: any[]) => {
  const customerHubspotInfo = useSelector((state) => selectCustomerHubspotInfo(state));
  const [data, setData] = useState(customerHubspotInfo ? customerHubspotInfo : {}) as any;


  const updateData = (key: any, value: any) => {
    setData((data: any) => Object.assign({}, {...data, [key]: value }))
  }
  const dealErrors = useSelector((state) => selectDealCreationErrors(state));

  const getJSXFieldElement = (fieldItem: any, key: string) => {
    // Since all default fields are textareas we do not need the switch logic
    switch (fieldItem.type) {
      case FieldTypes.number:
      case FieldTypes.textfield:
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <input data-testid={fieldItem.keyName} className="defaultInput" value={data[fieldItem.keyName]} type={fieldItem.type} onChange={(e) => updateData(fieldItem.keyName, e.target.value)}/>
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        );
      case FieldTypes.textarea:
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <textarea data-testid={fieldItem.keyName} className="defaultInput" value={data[fieldItem.keyName]} onChange={(e) => updateData(fieldItem.keyName, e.target.value)} />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.address:
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <AddressField
              disableMap
              hideUnitField={!fieldItem.unitKeyName}
              handleAddressChange={(value) => updateData(fieldItem.keyName, value && value.placeName ? value.placeName : '')}
              handleUnitChange={(value) => updateData(fieldItem.unitKeyName, value)}
              unitValue={data[fieldItem.unitKeyName] || ''}
              addressValue={{ placeName: data[fieldItem.keyName] || '', coordinates: []}}
            />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.radio:
        return (
          <div key={key} className={`fieldContainer radioContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`} onChange={(e: any) => updateData(fieldItem.keyName, e.target.value)}>
            <label><b>{fieldItem.label}</b></label>
            {fieldItem.options.map((option: string) => (
              <div className="radioOption">
                <input data-testid={fieldItem.keyName} type="radio" value={option} name={fieldItem.keyName} /> {option}
              </div>
            ))}
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.date:
        let date = data[fieldItem.keyName];
        const regex = /\d{4}-\d{2}-\d{2}/g
        if (date && regex.test(date)) {
          // Convert to GMT
          date = new Date(date).toLocaleString('en-US', { timeZone: 'GMT' });
        } else if (date && !regex.test(date)) {
          date = new Date(parseInt(date)).toLocaleString('en-US', { timeZone: 'GMT' });
        }
        return (
          <div key={key} className={`fieldContainer dateContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <DatePicker
              data-testid={fieldItem.keyName}
              className="datepicker"
              minDate={fieldItem.minDate || null}
              // value={data[fieldItem.keyName] ? new Date(data[fieldItem.keyName]) : null as any}
              selected={date ? new Date(date) : null}
              onChange={(val: any) => updateData(fieldItem.keyName, format(val, fieldItem.format))}
            />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.dropdown:
        const options = fieldItem.options.map((opt: string) => {
          return { label: opt, value: opt }
        });

        const currentOption = options.filter((item: any) => item.label === data[fieldItem.keyName])

        let currentValue = null;
        if (currentOption.length > 0) {
          currentValue = currentOption[0];
        }
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`])  ? 'error' : ''}`}>
            <label htmlFor={fieldItem.keyName}>{fieldItem.label}</label>
            <Select
              data-testid={fieldItem.keyName}
              name={fieldItem.keyName}
              inputId={fieldItem.keyName}
              className="defaultSelect"
              value={currentValue}
              options={options}
              onChange={(val) => updateData(fieldItem.keyName, val.value)}
            />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        ) 
      default:
        return <></>
    }
  }

  const getAllFields = () => {
    const fieldsJSX = fields.map((field) => {
      return getJSXFieldElement(field, `default-field-${field.keyName}`);
    })
    return fieldsJSX;
  }
  const [allFields, setAllFields] = useState(getAllFields());

  useEffect(() => {
    if (customerHubspotInfo) {
      const newData = Object.assign({}, { ...customerHubspotInfo });
      if (customerHubspotInfo.seller_commission_model && customerHubspotInfo.buyer_commission_model) {
        newData.buyer_or_seller = "Both";
      } else if (customerHubspotInfo.seller_commission_model) {
        newData.buyer_or_seller = "Seller";
      } else if (customerHubspotInfo.buyer_commission_model) {
        newData.buyer_or_seller = "Buyer";
      }
      setData(newData);
    }
  }, [customerHubspotInfo])

  useEffect(() => {
    if (data) {
      setAllFields(getAllFields() as any);
    }
  }, [data])

  return [allFields, data]
}

export default useDefaultDealFormHook