import React from 'react';
import cn from 'classnames';
import './StepDots.scss';

const StepDots = ({
  numberOfSteps,
  currentStep,
}: any) => {

  const getRows = () => {
    let rows = [];
    for (let i = 0; i < numberOfSteps; i += 1) {
      rows.push(
        <div key={`dot-${i}`} className={cn(['dot', { ['selected']: currentStep === i }])} />
      )
    }
    return rows;
  }

  return (
    <div className={'stepDotsContainer'}>
      {getRows()}
    </div>
  )
}

export default StepDots;