import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldTypes } from '../../EditFieldsModal/EditFieldsModal';
import { useSelector } from 'react-redux';
import AddressField from '../../../AddressField';
import { selectBuyerSLA, selectDealCreationErrors, selectSellerSLA } from '../../../../store/Deal/selector';
import { BuyerSLALookup, SellerSLALookup } from '../../../../store/Deal/actions';
import TimeField from '../../../TimeField';

const BuyerFields = [
  {
    label: 'Buyer Number of Agents',
    keyName: 'buyer_number_of_agents',
    type: FieldTypes.number,
  },
  {
    label: 'Buyer Time to First Match (hh:mm:ss)',
    keyName: 'buyer_time_to_first_match',
    type: FieldTypes.timesecondsfield,
  },
  {
    label: 'Buyer Time to Final Match (hh:mm:ss)',
    keyName: 'buyer_time_to_final_match',
    type: FieldTypes.timesecondsfield,
  },
]
const SellerFields = [
  {
    label: 'Seller Number of Agents',
    keyName: 'seller_number_of_agents',
    type: FieldTypes.number,
  },
  {
    label: 'Seller Time to First Match (hh:mm:ss)',
    keyName: 'seller_time_to_first_match',
    type: FieldTypes.timesecondsfield,
  },
  {
    label: 'Seller Time to Last Match (hh:mm:ss)',
    keyName: 'seller_time_to_final_match',
    type: FieldTypes.timesecondsfield,
  },
]

const useSLAFormBuilder = ({ currentData }: any) => {
  const sellerSLA = useSelector((state) => selectSellerSLA(state)) as SellerSLALookup;
  const buyerSLA = useSelector((state) => selectBuyerSLA(state)) as BuyerSLALookup;
  const stringifiedData = JSON.stringify(currentData);
  const [data, setData] = useState({}) as any;

  const updateData = (key: any, value: any) => {
    setData((data: any) => Object.assign({}, {...data, [key]: value }))
  }

  const dealErrors = useSelector((state) => selectDealCreationErrors(state));

  const getJSXFieldElement = (fieldItem: any, key: string) => {
    // Since all default fields are textareas we do not need the switch logic
    switch (fieldItem.type) {
      case FieldTypes.number:
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <input data-testid={fieldItem.keyName} className="defaultInput" value={data[fieldItem.keyName]} type={fieldItem.type} onChange={(e) => updateData(fieldItem.keyName, e.target.value !== '' ? Math.abs(parseInt(e.target.value)) : 0)}/>
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        );
      case FieldTypes.timesecondsfield:
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <TimeField value={data[fieldItem.keyName]} handleValue={(seconds?: number) => updateData(fieldItem.keyName, seconds || 0)} />
          </div>
        )
      case FieldTypes.textfield:
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <input data-testid={fieldItem.keyName} className="defaultInput" value={data[fieldItem.keyName]} type={fieldItem.type} onChange={(e) => updateData(fieldItem.keyName, e.target.value)}/>
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        );
      case FieldTypes.textarea:
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <textarea data-testid={fieldItem.keyName} className="defaultInput" value={data[fieldItem.keyName]} onChange={(e) => updateData(fieldItem.keyName, e.target.value)} />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.address:
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <AddressField
              disableMap
              hideUnitField={!fieldItem.unitKeyName}
              handleAddressChange={(value) => updateData(fieldItem.keyName, value && value.placeName ? value.placeName : '')}
              handleUnitChange={(value) => updateData(fieldItem.unitKeyName, value)}
              unitValue={data[fieldItem.unitKeyName] || ''}
              addressValue={{ placeName: data[fieldItem.keyName] || '', coordinates: []}}
            />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.radio:
        return (
          <div key={key} className={`fieldContainer radioContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`} onChange={(e: any) => updateData(fieldItem.keyName, e.target.value)}>
            <label><b>{fieldItem.label}</b></label>
            {fieldItem.options.map((option: string) => (
              <div className="radioOption">
                <input data-testid={fieldItem.keyName} type="radio" value={option} name={fieldItem.keyName} /> {option}
              </div>
            ))}
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.date:
        let date = data[fieldItem.keyName];
        const regex = /\d{4}-\d{2}-\d{2}/g
        if (date && regex.test(date)) {
          // Convert to GMT
          date = new Date(date).toLocaleString('en-US', { timeZone: 'GMT' });
        } else if (date && !regex.test(date)) {
          date = new Date(parseInt(date)).toLocaleString('en-US', { timeZone: 'GMT' });
        }
        return (
          <div key={key} className={`fieldContainer dateContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <DatePicker
              data-testid={fieldItem.keyName}
              className="datepicker"
              minDate={fieldItem.minDate || null}
              // value={data[fieldItem.keyName] ? new Date(data[fieldItem.keyName]) : null as any}
              selected={date ? new Date(date) : null}
              onChange={(val: any) => updateData(fieldItem.keyName, format(val, fieldItem.format))}
            />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.dropdown:
        const options = fieldItem.options.map((opt: string) => {
          return { label: opt, value: opt }
        });

        const currentOption = options.filter((item: any) => item.label === data[fieldItem.keyName])

        let currentValue = null;
        if (currentOption.length > 0) {
          currentValue = currentOption[0];
        }
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`])  ? 'error' : ''}`}>
            <label htmlFor={fieldItem.keyName}>{fieldItem.label}</label>
            <Select
              data-testid={fieldItem.keyName}
              name={fieldItem.keyName}
              inputId={fieldItem.keyName}
              className="defaultSelect"
              value={currentValue}
              options={options}
              onChange={(val) => updateData(fieldItem.keyName, val.value)}
            />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        ) 
      default:
        return <></>
    }
  }

  const getAllFields = () => {
    if (sellerSLA && !buyerSLA && currentData && currentData.buyer_or_seller === 'Seller') {
      const fieldsJSX = SellerFields.map((field) => {
        return getJSXFieldElement(field, `seller-default-field-${field.keyName}}`);
      }) 
      return fieldsJSX;
    } else if (buyerSLA && !sellerSLA && currentData && currentData.buyer_or_seller === 'Buyer') {
      const fieldsJSX = BuyerFields.map((field) => {
        return getJSXFieldElement(field, `buyer-default-field-${field.keyName}}`);
      }) 
      return fieldsJSX;
      
    } else if (buyerSLA && sellerSLA) {
      if (currentData && currentData.buyer_or_seller === 'Buyer') {
        const fieldsJSX = BuyerFields.map((field) => {
          return getJSXFieldElement(field, `buyer-default-field-${field.keyName}}`);
        }) 
        return fieldsJSX;
      }
      if (currentData && currentData.buyer_or_seller === 'Seller') {
        const fieldsJSX = SellerFields.map((field) => {
          return getJSXFieldElement(field, `seller-default-field-${field.keyName}`);
        }) 
        return fieldsJSX;
      }
      // If the option is both
      const fieldsJSX = [...SellerFields, ...BuyerFields].map((field) => {
        return getJSXFieldElement(field, `both-default-field-${field.keyName}`);
      }) 
      return fieldsJSX;
    } else {
      return [];
    }
  }
  const [allFields, setAllFields] = useState(getAllFields());

  useEffect(() => {
    const newData = Object.assign({}, { ...sellerSLA, ...buyerSLA })
    setData(newData);
    setAllFields(getAllFields());
    // if (sellerSLA && currentData && (currentData.buyer_or_seller === "Both" || currentData.buyer_or_seller === 'Seller')) {
    //   if (currentData && currentData.buyer_or_seller === 'Both') {
    //     const newData = Object.assign({}, { ...data, ...sellerSLA });
    //     setData(newData);
    //   } else {
    //     const newData = Object.assign({}, { ...sellerSLA });
    //     setData(newData);
    //   }
     
    // }
    // if (buyerSLA && currentData && (currentData.buyer_or_seller === "Both" || currentData.buyer_or_seller === 'Buyer')) {
    //   if (currentData && currentData.buyer_or_seller === 'Both') {
    //     const newData = Object.assign({}, { ...data, ...buyerSLA });
    //     setData(newData);
    //   } else {
    //     const newData = Object.assign({}, { ...buyerSLA });
    //     setData(newData);
    //   }
    // }

  }, [sellerSLA, buyerSLA])

  useEffect(() => {
    if (currentData) {
      if (currentData.buyer_or_seller === "Buyer" && buyerSLA) {
        const fieldsJSX = BuyerFields.map((field) => {
          return getJSXFieldElement(field, `current-buyer-default-field-${field.keyName}}`);
        })
        setAllFields(fieldsJSX)
      } else if (currentData.buyer_or_seller === "Seller" && sellerSLA) {
        const fieldsJSX = SellerFields.map((field) => {
          return getJSXFieldElement(field, `current-sellerdefault-field-${field.keyName}}`);
        })
        setAllFields(fieldsJSX)
      } else if (currentData.buyer_or_seller === "Both" && buyerSLA && sellerSLA) {
        const fieldsJSX = [...BuyerFields, ...SellerFields].map((field) => {
          return getJSXFieldElement(field, `current-both-default-field-${field.keyName}}`);
        })
        setAllFields(fieldsJSX)
      } else {
        setAllFields([]);
      }
    }
  }, [stringifiedData])


  useEffect(() => {
    setAllFields(getAllFields() as any);
  }, [data])

  return [allFields, data]
}

export default useSLAFormBuilder;
