import React, { useEffect, useState, useMemo } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { FieldTypes } from '../../EditFieldsModal/EditFieldsModal';
import { useSelector } from 'react-redux';
import { selectDealCreationErrors } from '../../../../store/Deal/selector';
import AddressField from '../../../AddressField';
import NumberInput from '../../DataSenderFormModal/components/NumberInput';

const SellerFields = [
  {
    label: 'Seller Commission Model',
    keyName: 'seller_commission_model',
    type: FieldTypes.dropdown,
    options: [
      'Flat Fee Model',
      '1% Model',
      'Under 125k Model',
      'Traditional Model',
      '1.5% Model',
      'Mr Cooper Seller CCB',
    ]
  },
  {
    label: 'Seller Type',
    keyName: 'seller_type',
    type: FieldTypes.dropdown,
    options: [
      'Cash Buyer',
    ]
  },
  {
    label: 'Home Value',
    keyName: 'home_value',
    type: FieldTypes.number,
  },
  {
    label: 'Listing Address',
    keyName: 'listing_address',
    type: FieldTypes.address,
    unitKeyName: 'unit_number',
  },
]

const BuyerFields = [
  {
    label: 'Loan Officer Email (optional)',
    keyName: 'loan_officer_email',
    type: FieldTypes.textfield,
  },
  {
    label: 'Buyer Commission Model',
    keyName: 'buyer_commission_model',
    type: FieldTypes.dropdown,
    options: [
      'Buyer No Rebate Model',
      'Buyer $1K Rebate Model',
      'Buyer 1% Rebate Model',
      'Buyer Tiered Rebate Model',
      'Clever Cash Back (0.5%)',
      'Clever Cash Back ($250)',
      'Buyer Model (new $250)',
      'Mr Cooper Buyer CCB',
      'Gravy Buyer',
    ]
  },
  {
    label: 'Buying Market Address',
    keyName: 'buying_market',
    type: FieldTypes.address,
  },
  {
    label: 'Buying Price High',
    keyName: 'buying_price_high',
    type: FieldTypes.number,
  },
  {
    label: 'Buying Price Low',
    keyName: 'buying_price_low',
    type: FieldTypes.number,
  }
]

const HubspotCommissionModelMapping: any = {
  "Flat Fee Model": "Flat Fee Model",
  "Flat Fee Model (1)": "Flat Fee Model",
  "1% Model": "1% Model",
  "1.5% Model": "1.5% Model",
  "<125k Model": "Under 125k Model",
  "<125K Model": "Under 125k Model",
  "Under 125k Model": "Under 125k Model",
  "<100k Model": "Under 125k Model",
  "<100K Model": "Under 125k Model",
  "<75k Model": "Under 75k Model",
  "<75K Model": "Under 75k Model",
  "Buyer Model": "Buyer No Rebate Model",
  "Buyer No Rebate Model": "Buyer No Rebate Model",
  "Buyer No Rebate": "Buyer No Rebate Model",
  "Buyer Flat Rebate": "Buyer $1K Rebate Model",
  "Buyer $1K Rebate Model": "Buyer $1K Rebate Model",
  "Buyer 1% Rebate Model": "Buyer 1% Rebate Model",
  "Buyer Percent Rebate": "Buyer 1% Rebate Model",
  "Traditional Model": "Traditional Model",
  "Buyer Tiered Rebate Model": "Buyer Tiered Rebate Model",
  "Clever Cashback": "Clever Cash Back (0.5%)",
  "Clever Cash Back": "Clever Cash Back (0.5%)",
  "Clever Cash Back (0.5%)": "Clever Cash Back (0.5%)",
  "Clever Cash Back ($150k+)": "Clever Cash Back (0.5%)",
  "Clever Cashback ($250)": "Clever Cash Back ($250)",
  "Clever Cash Back ($250)": "Clever Cash Back ($250)",
  "Buyer Model (new $250)": "Buyer Model (new $250)",
  "Mr Cooper Seller CCB": "Mr Cooper Seller CCB",
  "Mr Cooper Buyer CCB": "Mr Cooper Buyer CCB",
  "Gravy Buyer": "Gravy Buyer"
}

interface FormBuilderInterface {
  selectedDealStage: string | null,
  defaultData: any,
};

const useDealFormBuilderHook = ({
  selectedDealStage,
  defaultData,
}: FormBuilderInterface) => {
  // return fields and other data
  const [data, setData] = useState(defaultData ? defaultData : {}) as any;

  const dealErrors = useSelector((state) => selectDealCreationErrors(state));

  const updateData = (key: any, value: any) => {
    setData((data: any) => Object.assign({}, {...data, [key]: value }))
  }

  const getJSXFieldElement = (fieldItem: any, key: any) => {
    // if (Object.keys(data).length === 0) {
    //   return;
    // }
    switch (fieldItem.type) {
      case FieldTypes.number:
        return (
          <NumberInput
            disableFloat={true}
            disablePaste={false}
            disabled={fieldItem.disabled || false}
            field={fieldItem}
            onChange={(value: any) => {
              setData((data: any) => 
                Object.assign({}, { ...data, [fieldItem.keyName]: value })
              )
            }}
            value={data[fieldItem.keyName]}
            key={fieldItem.keyName}
          />
        )
      case FieldTypes.textfield:
        return (
          <div className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`} key={key}>
            <label>{fieldItem.label}</label>
            <input data-testid={fieldItem.keyName} className="defaultInput" value={data[fieldItem.keyName] || ''} type={fieldItem.type} onChange={(e) => updateData(fieldItem.keyName, e.target.value)}/>
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.address:
        return (
          <div key={key} className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <AddressField
              disableMap
              hideUnitField={!fieldItem.unitKeyName}
              handleAddressChange={(value) => updateData(fieldItem.keyName, value && value.placeName ? value.placeName : '')}
              handleUnitChange={(value) => updateData(fieldItem.unitKeyName, value)}
              unitValue={data[fieldItem.unitKeyName] || ''}
              addressValue={{ placeName: data[fieldItem.keyName] || '', coordinates: []}}
            />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.textarea:
        return (
          <div className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`} key={key}>
            <label>{fieldItem.label}</label>
            <textarea data-testid={fieldItem.keyName} className="defaultInput" value={data[fieldItem.keyName]} onChange={(e) => updateData(fieldItem.keyName, e.target.value)} />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.dropdown:
        const options = fieldItem.options.map((opt: string) => {
          return { label: opt, value: opt }
        });

        const currentOption = options.filter((item: any) => {
          return item.label === data[fieldItem.keyName]
        })


        let currentValue = null;
        if (currentOption.length > 0) {
          currentValue = currentOption[0];
        } else {
          if (data[fieldItem.keyName]) {
            const unassignedOption = HubspotCommissionModelMapping[data[fieldItem.keyName] as any];
            if (unassignedOption) {
              const newCurrentOption = options.filter((item: any) => {
                return item.label === unassignedOption
              })
              updateData(fieldItem.keyName, newCurrentOption[0].value)
              if (newCurrentOption.length > 0) {
                currentValue = newCurrentOption[0];
              }
            }
          }
        }

        return (
          <div className={`fieldContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) ? 'error' : ''}`} key={key}>
            <label>{fieldItem.label}</label>
            <Select
              className="defaultSelect"
              value={currentValue}
              data-testid={fieldItem.keyName}
              options={options}
              onChange={(val) => updateData(fieldItem.keyName, val.value)}
            />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        ) 
      case FieldTypes.radio:
        return (
          <div key={key} className={`fieldContainer radioContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`} onChange={(e: any) => updateData(fieldItem.keyName, e.target.value)}>
            <label><b>{fieldItem.label}</b></label>
            {fieldItem.options.map((option: string) => (
              <div className="radioOption">
                <input data-testid={fieldItem.keyName} type="radio" value={option} name={fieldItem.keyName} /> {option}
              </div>
            ))}
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )
      case FieldTypes.date:
        return (
          <div key={key} className={`fieldContainer dateContainer ${dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`]) ? 'error' : ''}`}>
            <label>{fieldItem.label}</label>
            <DatePicker
              data-testid={fieldItem.keyName}
              className="datepicker"
              minDate={fieldItem.minDate || null}
              // value={data[fieldItem.keyName] ? new Date(data[fieldItem.keyName]) : null as any}
              selected={data[fieldItem.keyName] ? new Date(data[fieldItem.keyName]) : null}
              onChange={(val: any) => updateData(fieldItem.keyName, format(val, fieldItem.format))}
            />
            {dealErrors && (dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]) && <p className="error-text">{dealErrors[fieldItem.keyName] || dealErrors[`${fieldItem.keyName}_string`] || dealErrors[`${fieldItem.keyName}_id`]}</p>}
          </div>
        )

      default:
        return <></>
    }
  }

  const getFieldsFromSelectedDealType = () => {
    if (selectedDealStage) {
      switch(selectedDealStage) {
        case 'Buyer':
          return BuyerFields;
        case 'Seller':
          return SellerFields;
        default:
          return [
            ...SellerFields,
            ...BuyerFields,
          ]
      }
    } else {
      return [];
    }
  }

  const getFields = () => {
    const fieldsFromDealType = getFieldsFromSelectedDealType() as any;
    const fieldsJSX = fieldsFromDealType.map((field: any) => {
      return getJSXFieldElement(field, `extra-field-${field.keyName}`);
    })
    return fieldsJSX;
  }
  const [allFields, setAllFields] = useState(getFields());

  useEffect(() => {
    setAllFields(getFields());
  }, [])
  
  useEffect(() => {
    // Triggers a refresh
    setAllFields(getFields());
  }, [data])

  useEffect(() => {
    setAllFields(getFields());
  }, [selectedDealStage])

  useMemo(() => {
    if (defaultData) {
      setData(defaultData);
    }
  }, [defaultData])

  return [allFields, data]
}

export default useDealFormBuilderHook;
