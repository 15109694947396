import { FieldTypes } from '../EditFieldsModal/EditFieldsModal';

const UpdateStageTypes = {
  Communicating: 'Communicating',
  'Not Connected': 'Not Connected',
  'Meeting Scheduled': 'Meeting Scheduled',
  'Met / House Hunted': 'Met / House Hunted',
  'Agreement Signed': 'Agreement Signed',
  'On Market / Making Offers': 'On Market / Making Offers',
  'Under Contract': 'Under Contract',
  'Awaiting Payment': 'Awaiting Payment',
  Lost: 'Lost',
  Closed: 'Closed',
  Underpaid: 'Underpaid',
};

const UpdateStageOptions = [
  {
    label: UpdateStageTypes.Communicating,
    value: 9,
  },
  {
    label: UpdateStageTypes['Not Connected'],
    value: 5,
  },
  {
    label: UpdateStageTypes['Meeting Scheduled'],
    value: 12,
  },
  {
    label: UpdateStageTypes['Met / House Hunted'],
    value: 10,
  },
  {
    label: UpdateStageTypes['Agreement Signed'],
    value: 13,
  },
  {
    label: UpdateStageTypes['On Market / Making Offers'],
    value: 14,
  },
  {
    label: UpdateStageTypes['Under Contract'],
    value: 15,
  },
  {
    label: UpdateStageTypes['Awaiting Payment'],
    value: 16,
  },
  {
    label: UpdateStageTypes.Lost,
    value: 1,
  },
  {
    label: UpdateStageTypes.Closed,
    value: 18,
  },
  {
    label: UpdateStageTypes.Underpaid,
    value: 17,
  }
]

const CommunicatingFields = [
  {
    label: 'Follow-up Date',
    keyName: 'Follow-up Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
    minDate: new Date(),
  }
];

const NotConnectedFields = [
  {
    label: 'Follow-up Date',
    keyName: 'Follow-up Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
    minDate: new Date(),
  }
];

const MeetingScheduledFields = [
  {
    label: 'Appointment Date',
    keyName: 'Appointment Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
  }
]

const MetHouseHuntedFields = [
  {
    label: 'Appointment Date',
    keyName: 'Appointment Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
  }
];

const AgreementSignedFields = [
  {
    label: 'Follow-up Date',
    keyName: 'Follow-up Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
    minDate: new Date(),
  }
]

const OnMarketMakingOffersFields: any[] = [];

const UnderContractFields = [
  {
    label: 'Closing Date',
    keyName: 'Closing Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
  }
]

const AwaitingPaymentFields: any[] = [];
const LostFields = [
  {
    label: 'Release Reason',
    keyName: 'Release Reason',
    type: FieldTypes.dropdown,
    options: [
      'Customer chose a different agent',
      'Customer decided not to sell',
      'Customer decided to sell FSBO',
      'Customer went cold / became unreachable',
      'Customer had incorrect contact information',
      "I'm no longer interested in working with this customer",
      'Home Sold - Uncollectible/No Receipt of Payment',
    ]
  },
];

const ClosedFields = [
  {
    label: 'Amount Paid',
    keyName: 'Amount Paid',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Agent Reported Sale Price',
    keyName: 'Agent Reported Sale Price',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Reported Agent Commission Amount',
    keyName: 'Reported Agent Commission Amount',
    type: FieldTypes.number,
    decimals: 2,
  },
  {
    label: 'Reported Other Agent Commission Amount',
    keyName: 'Reported Other Agent Commission Amount',
    type: FieldTypes.number,
    decimals: 2,
  },
  {
    label: 'Expected Referral Fee',
    keyName: 'Expected Referral Fee',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Check Number',
    keyName: 'Check Number',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Check Date',
    keyName: 'Check Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
  },
  {
    label: 'Check Sent Date',
    keyName: 'Check Sent Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
  },
  {
    label: 'Commission Documents Sent Date',
    keyName: 'Commission Documents Sent Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
  },
  {
    label: 'Final Sale Price',
    keyName: 'Final Sale Price',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Is Commission Verified',
    keyName: 'Is Commission Verified',
    type: FieldTypes.radio,
    options: [
      'Verified, agent charged correct amount',
      'Verified, agent over-charged customer',
      'Unverified',
    ]
  },
  {
    label: 'Commission Documents Sent',
    keyName: 'Commission Documents Sent',
    type: FieldTypes.radio,
    options: [
      'Yes, in the envelope with the check',
      'Yes, by email',
      'No',
    ]
  },
  {
    label: 'Payment Notes',
    keyName: 'Payment Notes',
    type: FieldTypes.textarea,
  }
];

const UnderpaidFields = [
  {
    label: 'Amount Paid',
    keyName: 'Amount Paid',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Agent Reported Sale Price',
    keyName: 'Agent Reported Sale Price',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Reported Agent Commission Amount',
    keyName: 'Reported Agent Commission Amount',
    type: FieldTypes.number,
    decimals: 2,
  },
  {
    label: 'Reported Other Agent Commission Amount',
    keyName: 'Reported Other Agent Commission Amount',
    type: FieldTypes.number,
    decimals: 2,
  },
  {
    label: 'Expected Referral Fee',
    keyName: 'Expected Referral Fee',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Check Number',
    keyName: 'Check Number',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Check Date',
    keyName: 'Check Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
  },
  {
    label: 'Check Sent Date',
    keyName: 'Check Sent Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
  },
  {
    label: 'Commission Documents Sent Date',
    keyName: 'Commission Documents Sent Date',
    type: FieldTypes.date,
    format: 'MMM dd, yyyy',
  },
  {
    label: 'Final Sale Price',
    keyName: 'Final Sale Price',
    type: FieldTypes.number,
    decimals: 0,
  },
  {
    label: 'Is Commission Verified',
    keyName: 'Is Commission Verified',
    type: FieldTypes.radio,
    options: [
      'Verified, agent charged correct amount',
      'Verified, agent over-charged customer',
      'Unverified',
    ]
  },
  {
    label: 'Commission Documents Sent',
    keyName: 'Commission Documents Sent',
    type: FieldTypes.radio,
    options: [
      'Yes, in the envelope with the check',
      'Yes, by email',
      'No',
    ]
  },
  {
    label: 'Payment Notes',
    keyName: 'Payment Notes',
    type: FieldTypes.textarea,
  },
  {
    label: 'Underpaid Differencce',
    keyName: 'Underpaid Differencce',
    type: FieldTypes.number,
    decimals: 0,
  },
]


export {
  UpdateStageTypes,
  CommunicatingFields,
  NotConnectedFields,
  MeetingScheduledFields,
  MetHouseHuntedFields,
  AgreementSignedFields,
  OnMarketMakingOffersFields,
  UnderContractFields,
  AwaitingPaymentFields,
  LostFields,
  ClosedFields,
  UnderpaidFields,
  UpdateStageOptions
}