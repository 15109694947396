import Agent from "../Interfaces/agent";
import Contacts, { CashBuyer, LoanOfficer } from "../Interfaces/contacts";
import Customer from "../Interfaces/customer";
import { Deal } from "../Interfaces/deal";
import User from "../Interfaces/user";

const SampleAgent = {
  agent_conversion_source: '',
  agent_profile_link: '',
  avg_review_score: 0,
  bios: {
    sms: {},
    email: {}
  },
  brokerage_name: '',
  commission_models_accepted: [],
  completed_cleanse: true,
  created_at: '',
  eligible: false,
  email: '',
  firstname: '',
  form_submission: true,
  home_base: {
    address: '',
    latitude: '',
    longitude: '',
  },
  hubspot_profile_link: '',
  invalid_reason: '',
  lastname: '',
  lead_jail_reason: '',
  metrics: {
    num_deals_in_flight: 1,
    in_flight_rate: 1,
    num_signed_not_closed: 1,
    signed_rate: 1,
    num_closed: 1,
    closed_rate: 1,
    num_lost: 1,
    lost_rate: 1,
    num_total_deals: 1,
    average_claim_time: 1,
    schedule_rate: 1,
  },
  most_recent_referral: '',
  num_listings: 1,
  phone: '',
  profile_links: {},
  text_consent_opt_in: false,
  sms_opt_out: false,
  profile_notes: [
    {
      id: 1,
      note: '',
      sourcer: '',
      created_at: '',
      updated_at: '',
    }
  ],
  total_reviews: 1,
  vid: 1,
  past_sale_states: [],
} as Agent

const SampleLoanOfficer = {
  bio: '',
  clever_owner: {
    email: '',
    first_name: '',
    hubspot_owner_id: '',
    last_name: '',
    license_number: '',
    meeting_link: '',
    phone: '',
    signature: '',
    team: '',
  },
  company_name: '',
  created_at: '',
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  mobile_phone: '',
  preapproval_link: '',
  vid: 1,
  sms_opt_out: false,
  text_consent_opt_in: true,
} as LoanOfficer

const SampleCashBuyer = {
  id: 1,
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  company_name: '',
  sms_opt_out: false,
  text_consent_opt_in: true
} as CashBuyer

const SampleCustomer = {
  email: '',
  firstname: '',
  id: 1,
  lastname: '',
  phone: '',
  acquisition_channel: '',
  initial_mortgage_status: '',
  buying_price_high: '',
  buying_price_low: '',
  buying_market: {
    address: '',
    city: '',
    state: '',
  },
  sms_opt_out: false,
  text_consent_opt_in: true
} as Customer;

const SampleContacts = {
  agents: [SampleAgent],
  customer: SampleCustomer,
  loan_officer: SampleLoanOfficer,
  losing_agents: [SampleAgent],
  winning_agent: SampleAgent,
  cash_buyers: [SampleCashBuyer],
} as Contacts;

const SampleDeal = {
  id: '1',
  account_executive: '',
  is_blend: false,
  transaction_type: '',
  agent_notes: '',
  internal_notes: '',
  customer: SampleCustomer,
  commission_model_name: '',
  commission_model_internal_name: '',
  closing_date: '',
  listing_address: '',
  home_value: '',
  property_type: '',
  preferred_appointment_date: '',
  preferred_appointment_time: '',
  buying_price_high: 1,
  buying_price_low: 1,
  location: {
    address: '',
    latitude: 1,
    longitude: 1,
    state: '',
  },
  lead_confidence_score: 1,
  territory: '',
  short_commission_model_name: '',
} as Deal

const SampleUser = {
  email: '',
  first_name: '',
  last_name: '',
  username: '',
  phone: '',
} as User



export {
  SampleAgent,
  SampleLoanOfficer,
  SampleCustomer,
  SampleContacts,
  SampleDeal,
  SampleUser,
  SampleCashBuyer
};
