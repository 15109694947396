import nunjucks from 'nunjucks';

const renderDjangoTemplate = (template: string, context: object) => {
  nunjucks.configure({ autoescape: true })
  const renderedString = nunjucks.renderString(template, context);
  return renderedString;
}

export {
  renderDjangoTemplate,
}