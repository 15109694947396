import { DataSenderDataModel, DataSenderModel } from "../../../../store/DataSender/actions"
import { toast } from 'react-toastify';
import { FieldTypes } from "../../../../utils/fieldTypes"


const getKeysFromStatusOptions = (statusOption: string) => {
  switch (statusOption) {
    case "Attempted contact (1)":
      return "test flag"
    default:
      return statusOption
  }
}

const getFields = (vendor: string, agentInfo: any) => {
  let statusOptions = [
    "Attempted contact (1)",
    "Lost (1)",
    "Future Appointment (2)",
    "Not Ready to Buy > 12 months",
    "Not Ready to Buy for 6-12 months (1)",
    "Buyer Requested LO Contact (2)",
    "Rate Shopping (3)",
    "Missed Appointment / LO not responding (4)",
    "Client Requested New LO (4)",
    "Matched with Agent (1)",
    "Spoke/met with Agent (1)",
    "Made Offer (1)",
    "Under Contract (1)",
    "Closed (1)",
  ]

  if (vendor === "19") {
    statusOptions = [
      ...statusOptions,
      "Matched with New Agent (1)",
      "Spoke with Client > searching for agents (1)",
      "Client requested a new agent to interview > searching for agents (1)",
      "Actively House Hunting (1)",
      "Client requested a new agent to interview (2)",
    ]
  }

  if (vendor === "26") {
    statusOptions = [
      ...statusOptions,
      "Matched with New Agent (1)",
      "Spoke with Client > searching for agents (1)",
      "Actively House Hunting (1)",
      "Client requested a new agent to interview (2)",
    ]
  }

  if (vendor !== "26") {
    statusOptions = [
      ...statusOptions,
      "Immediate Appointment (3)",
      "Day 1 No Contact (0)",
    ]
  }

  const showAgentFields = (status: string) =>
    ["Matched with Agent (1)", "Matched with New Agent (1)"].includes(status) &&
    vendor === "19" || ["Matched with Agent (1)", "Matched with New Agent (1)"].includes(status) && vendor === "26"

  return [
    {
      type: FieldTypes.header,
      keyName: "dataSenderHeader",
      label: `Data Sender`,
    },
    {
      label: "Notes",
      keyName: "notes",
      type: FieldTypes.textarea,
      required: true,
    },
    {
      label: "Status",
      keyName: "status",
      type: FieldTypes.dropdown,
      required: true,
      options: statusOptions,
    },
    {
      label: "Working With Clever?",
      keyName: "working_with_clever",
      type: FieldTypes.radioText,
      options: ["Yes", "No"],
      required: true,
    },
    {
      type: FieldTypes.header,
      keyName: "agentInfoHeader",
      label: "Please confirm the agent's details",
      triggers: {
        status: (value: string) => showAgentFields(value),
      },
    },
    {
      label: "Brokerage Name",
      keyName: "agent_brokerage_name",
      type: FieldTypes.textfield,
      default: agentInfo.agent_brokerage_name,
      triggers: {
        status: (value: string) => showAgentFields(value),
      },
    },
    {
      label: "Name",
      keyName: "agent_name",
      type: FieldTypes.textfield,
      default: agentInfo.agent_name,
      triggers: {
        status: (value: string) => showAgentFields(value),
      },
    },
    {
      label: "Address",
      keyName: "agent_address",
      type: FieldTypes.textfield,
      default: agentInfo.agent_address,
      triggers: {
        status: (value: string) => showAgentFields(value),
      },
    },
    {
      label: "City",
      keyName: "agent_city",
      type: FieldTypes.textfield,
      default: agentInfo.agent_city,
      triggers: {
        status: (value: string) => showAgentFields(value),
      },
    },
    {
      label: "State",
      keyName: "agent_state",
      type: FieldTypes.textfield,
      default: agentInfo.agent_state,
      triggers: {
        status: (value: string) => showAgentFields(value),
      },
    },
    {
      label: "Zip Code",
      keyName: "agent_zipcode",
      type: FieldTypes.textfield,
      default: agentInfo.agent_zipcode,
      triggers: {
        status: (value: string) => showAgentFields(value),
      },
    },
    {
      label: "Phone",
      keyName: "agent_phone",
      type: FieldTypes.textfield,
      default: agentInfo.agent_phone,
      triggers: {
        status: (value: string) => showAgentFields(value),
      },
    },
    {
      label: "Email",
      keyName: "agent_email",
      type: FieldTypes.textfield,
      default: agentInfo.agent_email,
      triggers: {
        status: (value: string) => showAgentFields(value),
      },
    },
  ]
}

enum Types {
  hardcoded_value = "hardcoded_value", // Static value does not change LOCKED VALUE
  value = "value", // has a default value
  merge = "merge", // merge combines two fields
  switch = "switch", // uses the item value and checks the cases for the correct value LOCKED VALUE
  any = "any", // uses the items value and checks to see if one of the items has the a value and uses that
  dropdown = "dropdown"
}

// // 3 level depth object check
const getDefaultValue = (data: DataSenderDataModel, value: string) => {
  const matches = value.match(/([^\.{{}}]+)+/g)
  if (matches && matches.length > 0) {
    const item: any = matches.reduce((prev, current) => {
      if (prev && prev[current]) {
        return prev[current]
      } else {
        return null;
      }
    }, data as any);
    if (item) {
      return item;
    }
  }
  return '';
}

const getDefaultAnyValue = (data: DataSenderDataModel, items: string[]) => {
  let defaultVal = '';
  items.forEach((item) => {
    const matches = item.match(/([^\.{{}}]+)+/g)
    if (matches && matches.length > 0) {
      const item: any = matches.reduce((prev, current) => {
        if (prev && prev[current]) {
          return prev[current]
        } else {
          return null;
        }
      }, data as any);
      if (item && !defaultVal) {
        defaultVal = item;
      }
    }
  })
  if (defaultVal) {
    return defaultVal;
  } else {
    return '';
  }
}

const getDefaultMergeValue = (data: DataSenderDataModel, value: string) => {
  if (value) {
    const newString = value.replace(/{{([^}}]+)}}+/g, (match) => getDefaultValue(data, match));
    return newString;
  } else {
    return '';
  }
}

const getSwitchValue = (data: DataSenderDataModel, item: string, cases: any, params: any) => {
  const matches = item.match(/([^\.{{}}]+)+/g);
  if (matches && matches.length > 0) {
    let item: any;
    if (matches.length === 1) {
      // If there is only one match means that the value is not a data property but a field key
      if (!params[matches[0]]) {
        toast("There is an error with the configuration of a switch field. Please check the configuration and try again.", { type: 'error' });
        return '';
      }
      const field = params[matches[0]]; // Get Field Object
      switch (field.type) {
        case 'dropdown':
          item = field.options[0];
          break;
        case 'hardcoded_value':
          item = field.value;
          break;
        case 'value':
          item = getDefaultValue(data, field.value);
          break;
        default:
          break;
      }
      
    } else {
      item = matches.reduce((prev, current) => {
        if (prev && prev[current]) {
          return prev[current]
        } else {
          return null;
        }
      }, data as any);
    }
    
    if (item) {
      if (cases[item]) {
        return cases[item];
      }
    }
  }
  return '';
}

const switchListenerField = (item: string, params: any) => {
  const matches = item.match(/([^\.{{}}]+)+/g);
  if (matches && matches.length > 0) {
    if (matches.length === 1) {
      if (!params[matches[0]]) {
        return null;
      }
      // If there is only one match means that the value is not a data property but a field key
      const field = params[matches[0]]; // Get Field Object
      if (field.type === 'dropdown') {
        return matches[0];
      }
    }
  }
  return null;
}

const getFieldType = (fieldObject: any) => {
  let field = FieldTypes.textfield;
  if (fieldObject.field) {
    switch (fieldObject.field) {
      case 'integer':
        field = FieldTypes.number;
        break;
      case 'string':
        field = FieldTypes.textfield;
        break;
      case 'text':
        field = FieldTypes.textarea;
        break;
      case 'boolean':
        field = FieldTypes.radioText;
        break;
      case 'dropdown':
        field = FieldTypes.dropdown;
      default:
        break;
    }
  }
  return field;
}

const gatherFields = (data: DataSenderDataModel, keys: any[], params: any) => {
  const fields = [] as any[];
  keys.forEach((key) => {
    const fieldObject = params[key];
    switch (fieldObject.type) {
      case Types.value:
        // handle value type fields
        let field = getFieldType(fieldObject);
        let item = {
          label: fieldObject.label || key,
          keyName: key,
          type: field,
          default: getDefaultValue(data as any, fieldObject.item) || '',
          send_through: fieldObject.hasOwnProperty('send_through') ? fieldObject.send_through : true,
          hide: fieldObject.hasOwnProperty('hide') ? fieldObject.hide : false,
        } as any;
        if (field === FieldTypes.radioText) {
          item["options"] = ["true", "false"];
        }
        item = checkTriggers(item, fieldObject);
        fields.push(item);
        break;
      case Types.any:
        // handle any type fields
        let anyField = getFieldType(fieldObject);
        let anyItem = {
          label: fieldObject.label || key,
          keyName: key,
          type: anyField,
          default: getDefaultAnyValue(data as any, fieldObject.items) || '',
          send_through: fieldObject.hasOwnProperty('send_through') ? fieldObject.send_through : true,
          hide: fieldObject.hasOwnProperty('hide') ? fieldObject.hide : false,
        } as any;
        if (anyField === FieldTypes.radioText) {
          anyItem["options"] = ["true", "false"];
        }
        anyItem = checkTriggers(anyItem, fieldObject);
        fields.push(anyItem);
        return;
      case Types.hardcoded_value:
        // handle hardcoded value type fields
        let hardField = getFieldType(fieldObject);
        let hardItem = {
          label: fieldObject.label || key,
          keyName: key,
          type: hardField,
          default: fieldObject.value || '',
          send_through: fieldObject.hasOwnProperty('send_through') ? fieldObject.send_through : true,
          hide: fieldObject.hasOwnProperty('hide') ? fieldObject.hide : false,
        } as any
        if (hardField === FieldTypes.radioText) {
          hardItem["options"] = ["true", "false"];
          hardItem.default = hardItem.default === "true" ? "true" : "false";
        }
        hardItem = checkTriggers(hardItem, fieldObject);
        fields.push(hardItem);
        return;
      case Types.merge:
        // handle merge type fields
        let mergeField = getFieldType(fieldObject);
        let mergeItem = {
          label: fieldObject.label || key,
          keyName: key,
          type: mergeField,
          default: getDefaultMergeValue(data, fieldObject.value) || '',
          send_through: fieldObject.hasOwnProperty('send_through') ? fieldObject.send_through : true,
          hide: fieldObject.hasOwnProperty('hide') ? fieldObject.hide : false,
        } as any;
        if (mergeField === FieldTypes.radioText) {
          mergeItem["options"] = ["true", "false"];
        }
        mergeItem = checkTriggers(mergeItem, fieldObject);
        fields.push(mergeItem);
        return;
      case Types.switch:
        // handle switch type fields
        const switchField = getFieldType(fieldObject);

        let switchItem = {
          label:fieldObject.label || key,
          keyName: key,
          type: switchField,
          default: getSwitchValue(data, fieldObject.item, fieldObject.cases, params) || '',
          disabled: true,
          send_through: fieldObject.hasOwnProperty('send_through') ? fieldObject.send_through : true,
          hide: fieldObject.hasOwnProperty('hide') ? fieldObject.hide : false,
        } as any
        // Add a new property to listen to changes on a specific property if it is a switch listener
        const listenerField = switchListenerField(fieldObject.item, params);
        if (listenerField) {
          switchItem.listenerFieldObject = {
            listenerFieldKey: listenerField,
            currentFieldObject: fieldObject,
            params: params,
            data: data,
          }
        }
        switchItem = checkTriggers(switchItem, fieldObject);
        fields.push(switchItem);
        return;

      case Types.dropdown:
        // handle switch type fields
        const dropdownField = getFieldType(fieldObject);

        let dropdownItem = {
          label: fieldObject.label || key,
          keyName: key,
          type: dropdownField,
          options: fieldObject.options,
          default: fieldObject.options[0],
          send_through: fieldObject.hasOwnProperty('send_through') ? fieldObject.send_through : true,
          hide: fieldObject.hasOwnProperty('hide') ? fieldObject.hide : false,
        }
        dropdownItem = checkTriggers(dropdownItem, fieldObject);
        fields.push(dropdownItem);
        return;
      default:
        return;
    }
  });
  return fields as any[];
}

const checkTriggers = (fieldItem: any, fieldObject: any) => {
  let conditionTriggerObject = {} as any;
  if (fieldObject.conditions) {
    fieldObject.conditions.forEach((condition: any) => {
      if (conditionTriggerObject[condition.field]) {
        conditionTriggerObject[condition.field].push(condition.value);
      } else {
        conditionTriggerObject[condition.field] = [condition.value];
      }
      
    });
    fieldItem.triggers = conditionTriggerObject;
  }
  return fieldItem;
}

/*
      *****  Example Parameters *****
      {
        "firstname": { type: "value", item: "hubspot.First Name"},
        "inquiry_type": { type: "hardcoded_value", value: "Buying"},
        "listing_address": { tyoe: "merge", value: "{{hubspot.city}}, {{hubspot.state}}" },
        "buyer_type": {
          "type": "switch",
          "item": "hubspot.Buyer Type",
          "cases": {
                "New Buyer": "NBuyer",
                "Previous Buyer": "PB"
          },
          "field": "string"
        }
        "phone": {"type": "any", "items": ["hubspot.Phone", "hubspot.Day Phone"], "field": "string"}
      }
    */

const dataSenderFields = (data: DataSenderDataModel, requestFields: DataSenderModel) => {
  const queryParams = requestFields.query_parameters;
  const requestBody = requestFields.request_body;

  const mergeParams = { ...queryParams, ...requestBody };
 
  const keys = Object.keys(mergeParams);
  const fields = gatherFields(data, keys, mergeParams);

  return fields;
}

export {
  getFields,
  getKeysFromStatusOptions,
  getDefaultMergeValue,
  dataSenderFields,
  getSwitchValue,
  checkTriggers
}
