import React, { useState } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { FieldTypes } from '../EditFieldsModal/EditFieldsModal';
import { AgreementSignedFields, AwaitingPaymentFields, ClosedFields, CommunicatingFields, LostFields, MeetingScheduledFields, MetHouseHuntedFields, NotConnectedFields, OnMarketMakingOffersFields, UnderContractFields, UnderpaidFields, UpdateStageOptions, UpdateStageTypes } from './Fields';

interface FormBuilderInterface {
  selectedUpdateStage: number | null,
};

const useFormBuilderHook = ({
  selectedUpdateStage,
}: FormBuilderInterface) => {
  // return fields and other data
  const [data, setData] = useState({}) as any;

  const handleOptionChange = (key: string, value: any) => {
    const updatedField = Object.assign({ ...data }, {});
    updatedField[key] = value.value;
    setData(updatedField)
  }

  const updateFieldWithValue = (key: string, value: string | number) => {
    const updatedField = Object.assign({}, { ...data })
    updatedField[key] = value;
    setData(updatedField)
  }

  const updateRadio = (e: any, keyName: string) => {
    const updatedField = Object.assign({}, { ...data });
    updatedField[keyName] = e.target.value;
    setData(updatedField);
  }

  const handleDateChange = (val: Date, keyName: string, dateFormat: string) => {
    const formattedDate = format(val, dateFormat);
    const updatedField = Object.assign({}, { ...data });
    updatedField[keyName] = formattedDate;
    setData(updatedField);
  }

  const getJSXFieldElement = (fieldItem: any) => {
    // if (Object.keys(data).length === 0) {
    //   return;
    // }
    switch (fieldItem.type) {
      case FieldTypes.number:
      case FieldTypes.textfield:
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            <input className="defaultInput" value={data[fieldItem.keyName]} type={fieldItem.type} onChange={(e) => updateFieldWithValue(fieldItem.keyName, e.target.value)}/>
          </div>
        )
      case FieldTypes.textarea:
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            <textarea className="defaultInput" value={data[fieldItem.keyName]} onChange={(e) => updateFieldWithValue(fieldItem.keyName, e.target.value)} />
          </div>
        )
      case FieldTypes.dropdown:
        const options = fieldItem.options.map((opt: string) => {
          return { label: opt, value: opt }
        });

        const currentOption = options.filter((item: any) => item.label === data[fieldItem.keyName])
        let currentValue = null;
        if (currentOption.length > 0) {
          currentValue = currentOption[0];
        }
        return (
          <div className="fieldContainer">
            <label>{fieldItem.label}</label>
            <Select
              className="defaultSelect"
              value={currentValue}
              options={options}
              onChange={(val) => handleOptionChange(fieldItem.keyName, val)}
            />
          </div>
        ) 
      case FieldTypes.radio:
        return (
          <div className="radioContainer fieldContainer" onChange={(e) => updateRadio(e, fieldItem.keyName)}>
            <label><b>{fieldItem.label}</b></label>
            {fieldItem.options.map((option: string) => (
              <div className="radioOption">
                <input type="radio" value={option} name={fieldItem.keyName} /> {option}
              </div>
            ))}
          </div>
        )
      case FieldTypes.date:
        return (
          <div className="fieldContainer dateContainer">
            <label>{fieldItem.label}</label>
            <DatePicker
              className="datepicker"
              minDate={fieldItem.minDate || null}
              // value={data[fieldItem.keyName] ? new Date(data[fieldItem.keyName]) : null as any}
              selected={data[fieldItem.keyName] ? new Date(data[fieldItem.keyName]) : null}
              onChange={(val: any) => handleDateChange(val, fieldItem.keyName, fieldItem.format)}
            />
          </div>
        )

      default:
        return <></>
    }
  }

  const getFieldsFromSelectedUpdateStage = () => {
    if (selectedUpdateStage) {
      // get string type from number
      const selectedStage = UpdateStageOptions.filter((option) => {
        return option.value === selectedUpdateStage;
      })
      if (selectedStage.length > 0) {
        switch (selectedStage[0].label) {
          case UpdateStageTypes['Agreement Signed']:
            return AgreementSignedFields
          case UpdateStageTypes['Awaiting Payment']:
            return AwaitingPaymentFields;
          case UpdateStageTypes.Closed:
            return ClosedFields;
          case UpdateStageTypes.Communicating:
            return CommunicatingFields;
          case UpdateStageTypes.Lost:
            return LostFields;
          case UpdateStageTypes['Meeting Scheduled']:
            return MeetingScheduledFields;
          case UpdateStageTypes['Met / House Hunted']:
            return MetHouseHuntedFields;
          case UpdateStageTypes['Not Connected']:
            return NotConnectedFields;
          case UpdateStageTypes['On Market / Making Offers']:
            return OnMarketMakingOffersFields;
          case UpdateStageTypes['Under Contract']:
            return UnderContractFields;
          default:
            return UnderpaidFields;
        }
      }
      
    } else {
      return [];
    }
  }

  const getFields = () => {
    const fieldsFromUpdateStage = getFieldsFromSelectedUpdateStage() as any;
    const fieldsJSX = fieldsFromUpdateStage.map((field: any) => {
      return getJSXFieldElement(field);
    })
    return fieldsJSX;
  }
  return [getFields(), data]
}

export default useFormBuilderHook;
