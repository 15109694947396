import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import cuid from 'cuid';
import './TextInput.scss';

type TextInputProps = {
  id?: string,
  name: string,
  placeholder?: string,
  handleValue?: any,
  value?: string,
  showLabel?: boolean,
  hideInput?: boolean,
  icon?: any,
  error?: any,
  handleBlur?: any,
}

const TextInput = forwardRef(({
  id,
  name,
  placeholder = "Enter your details",
  handleValue = () => {},
  value = undefined,
  showLabel = false,
  hideInput = false,
  icon,
  error,
  handleBlur = null,
}: TextInputProps, wrapperRef) => {
  const textId = id || useMemo(() => `TextInput-${cuid()}`, []);

  return (
    <label
      htmlFor={textId}
      ref={wrapperRef as any}
      className={classNames("textInput", {
        ["hasIcon"]: icon,
        ["error"]: error,
      })}
    >
      {showLabel && (
        <span className={"label"}>{name}</span>
      )}

      {!hideInput && (
        <input
          id={textId}
          type="text"
          name={name}
          placeholder={placeholder}
          onChange={(event) => handleValue(event.target.value)}
          onBlur={(event) => handleBlur(event)}
          value={value}
          aria-label={name}
        />
      )}

      {icon}
    </label>
  );
});

// TextInput.defaultProps = {
//   placeholder: 'Enter your details',
//   value: undefined,
//   showLabel: false,
//   hideInput: false,
//   icon: null,
//   error: false,
//   handleBlur: () => { },
// };

// TextInput.propTypes = {
//   name: PropTypes.string,
//   handleValue: PropTypes.func,
//   placeholder: PropTypes.string,
//   value: PropTypes.string,
//   showLabel: PropTypes.bool,
//   hideInput: PropTypes.bool,
//   icon: PropTypes.object,
//   error: PropTypes.bool,
//   handleBlur: PropTypes.func,
// };

export default TextInput;
