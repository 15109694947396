import React from 'react';
import BackgroundImage from '../../../images/background.jpg';
import './Background.scss';

const Background = ({ children }: any) => (
  <div className="backgroundContainer">
    <div className="background" style={{ backgroundImage: `url(${BackgroundImage})` }} />
    {children}
    <div className="whiteOverlay" />
  </div>
);

export default Background;